import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { IoArrowBackOutline } from "react-icons/io5";

function ViewDownloadReports({ hideDownloads }) {
    const { Loading, ReportDownloads } = useSelector((state) => state.Astromind);

    return (
        <>
            <Col lg={12} sm={12} className='blance-card mt-5'>
                <Col lg={12} className='view-download'>
                    <div onClick={() => { hideDownloads(false) }}>
                        <a className='text-primary'>
                            Back
                        </a>
                    </div>
                </Col>
                {Loading ?
                    <>
                        <div class="wrapper mt-5">
                            <div class="page-loader">
                                <div class="loading one"></div>
                                <div class="loading two"></div>
                                <div class="loading three"></div>
                                <div class="loading four"></div>
                            </div>
                        </div>
                    </>
                    :
                    <Col lg={12} className={`transaction-table mt-5`}>
                        {ReportDownloads?.data?.data?.reports?.length > 0 ?
                            <>
                                <table>
                                    <tr className='transaction-heading'>
                                        <th>S No</th>
                                        <th>Date</th>
                                        <th>Report Link</th>
                                    </tr>
                                    {ReportDownloads?.data?.data?.reports?.map((data, i) => {
                                        let currentUrl = new URL(data?.file);
                                        let pathname = currentUrl.pathname;
                                        let fileName = pathname.split('/');
                                        console.log(fileName[2]);

                                        return (
                                            <>
                                                <tr className='transaction-data'>
                                                    <td>{i + 1}</td>
                                                    <td>{new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                                                    <td>
                                                        <a href={data?.file} className='text-primary'>{fileName[2]}</a>
                                                    </td>
                                                </tr >
                                            </>
                                        )
                                    })}
                                </table>
                            </>
                            :
                            <>
                                <div className='no-data'>
                                    <p>No sufficient data available!</p>
                                </div>
                            </>
                        }
                    </Col>
                }
            </Col>
        </>
    )
}

export default ViewDownloadReports;