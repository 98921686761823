import React from "react";


export function  NavMenuToggle(){
	setTimeout(()=>{	
		let mainwrapper = document.querySelector("#main-wrapper");
		if(mainwrapper.classList.contains('menu-toggle')){
			mainwrapper.classList.remove("menu-toggle");
		}else{
			mainwrapper.classList.add("menu-toggle");
		}
	},200);
}


const NavHader = ({ navToggle, changeNavToggle }) => {

  return (
    <div className="nav-header">
      

      <div
        className="nav-control"
        onClick={() => {
          changeNavToggle(!navToggle);
          //openMenuToggle();
         NavMenuToggle();
        }}
      >
        <div className={`hamburger ${navToggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>          
        </div>
      </div>
    </div>
  );
};

export default NavHader;
