import { Container, Row, Col } from 'react-bootstrap';
import MainPagetitle from '../../layouts/MainPagetitle';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetEarningsHistory } from '../../../Redux/Slice';

const timePeriod = [
    { value: 'OverAll', label: 'OverAll' },
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Last-7', label: 'Last 7 days' },
    { value: 'Last-15', label: 'Last 15 days' },
    { value: 'Last-30', label: 'Last 30 days' },
    { value: 'Current-month', label: 'Current Month' },
    { value: 'Previous-month', label: 'Previous Month' },
    { value: 'Custom', label: 'Custom' },
    { value: ' ', label: ' ' },
]

const Earnings = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');
    const { EarningsHistory } = useSelector((state) => state.Astromind);

    let page = 1;
    const pageLimit = window.innerWidth < 600 ? 15 : 25;
    const [currentPage, setCurrentPage] = useState(1);
    const [balance, setBalance] = useState(null);

    const [time_period, setTime_period] = useState();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [start_date, setStart_date] = useState(startDate ? moment(startDate).format('YYYY-MM-DD') : null);
    const [end_date, setEnd_date] = useState(endDate ? moment(endDate).format('YYYY-MM-DD') : null);

    useEffect(() => {
        const today = new Date();

        if (time_period?.value === 'OverAll') {
            dispatch(GetEarningsHistory({ token, page, pageLimit, overall: true }));
        }
        else if (time_period?.value === "Today") {
            setStart_date(today);
            let formatStartdate = today ? moment(today).format('YYYY-MM-DD') : null;

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate }));
        }
        else if (time_period?.value === "Yesterday") {
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);

            setStart_date(yesterday);

            let formatStartdate = yesterday ? moment(yesterday).format('YYYY-MM-DD') : null;

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate }));
        }
        else if (time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30") {
            const pastday = new Date(today);
            const pastdayCount = time_period?.value === "Last-7" ?
                7
                : time_period?.value === "Last-15" ?
                    15
                    : time_period?.value === "Last-30" ?
                        30
                        : null;

            pastday.setDate(today.getDate() - pastdayCount);

            setStart_date(pastday);
            setEnd_date(today);

            let formatStartdate = pastday ? moment(pastday).format('YYYY-MM-DD') : null;
            let formatEnddate = today ? moment(today).format('YYYY-MM-DD') : null;

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate, formatEnddate }));
        }
        else if (time_period?.value === "Current-month") {
            let month = today.getMonth() + 1;
            let year = today.getFullYear();
            let firstDay = new Date(year, month - 1, 1);
            let lastDay = new Date(year, month, 0);

            let Month_Start_Date = moment(firstDay).format('YYYY-MM-DD');
            let Month_End_Date = moment(lastDay).format('YYYY-MM-DD');

            setStart_date(today);
            
            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate: Month_Start_Date, formatEnddate: Month_End_Date }));
        }
        else if (time_period?.value === "Previous-month") {
            const pastmonth = new Date(today);
            pastmonth.setMonth(today.getMonth() - 1);

            let month = pastmonth.getMonth() + 1;
            let year = pastmonth.getFullYear();
            let firstDay = new Date(year, month - 1, 1);
            let lastDay = new Date(year, month, 0);

            setStart_date(lastDay);

            let Month_Start_Date = moment(firstDay).format('YYYY-MM-DD');
            let Month_End_Date = moment(lastDay).format('YYYY-MM-DD');

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate: Month_Start_Date, formatEnddate: Month_End_Date }));
        }
        else if (time_period?.value === 'Custom') {
            return;
        }
        else {
            dispatch(GetEarningsHistory({ token, page, pageLimit }));

        }
    }, [time_period])

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(EarningsHistory?.data?.pagination?.currentpage);
    }, [EarningsHistory]);

    useEffect(() => {
        if (time_period?.value === 'Custom' && startDate && endDate) {
            let formatStartdate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
            let formatEnddate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate, formatEnddate }));
        }
    }, [startDate, endDate])

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;

        let formatStartdate = start_date ? moment(start_date).format('YYYY-MM-DD') : null;
        let formatEnddate = end_date ? moment(end_date).format('YYYY-MM-DD') : null;

        if (time_period?.value === 'OverAll') {
            dispatch(GetEarningsHistory({ token, page, pageLimit, overall: true }));
        }
        else if ((time_period?.value === 'Current-month' || time_period?.value === 'Previous-month') && start_date) {
            let month = start_date.getMonth() + 1;
            let year = start_date.getFullYear();
            let firstDay = new Date(year, month - 1, 1);
            let lastDay = new Date(year, month, 0);

            let Month_Start_Date = moment(firstDay).format('YYYY-MM-DD');
            let Month_End_Date = moment(lastDay).format('YYYY-MM-DD');

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate: Month_Start_Date, formatEnddate: Month_End_Date }));
        }
        else if (formatStartdate && formatEnddate) {
            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate, formatEnddate }));
        }
        else if (formatStartdate) {
            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate }));
        }
        else if (startDate && endDate) {
            let formatStartdate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
            let formatEnddate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;

            dispatch(GetEarningsHistory({ token, page, pageLimit, formatStartdate, formatEnddate }));
        }
        else {
            dispatch(GetEarningsHistory({ token, page, pageLimit }));
        }
    }

    useEffect(() => {
        let TotalEarings = Number(EarningsHistory?.data?.data?.totalEarnings);
        let AmcShare = Number(EarningsHistory?.data?.data?.totalAMCEarnings);
        let availableBalance = Number(EarningsHistory?.data?.data?.totalAstrologerEarnings);
        let pgCharge = (availableBalance * 2.5) / 100;
        let subTotal = availableBalance - pgCharge;
        let tdsCharge = (subTotal * 10) / 100;
        let gstCharge = 0;
        let payableAmount = subTotal - tdsCharge + gstCharge;

        setBalance({
            totalEarings: TotalEarings.toFixed(2),
            amcShare: AmcShare.toFixed(2),
            availableBalance: availableBalance.toFixed(2),
            pgCharge: pgCharge.toFixed(2),
            tdsCharge: tdsCharge.toFixed(2),
            gstCharge: gstCharge.toFixed(2),
            payableAmount: payableAmount.toFixed(2),
        });
    }, [EarningsHistory]);

    return (
        <>
            <MainPagetitle pageTitle="Earnings" parentTitle="Home" />
            <Row className='d-flex justify-content-center margin-3' >
                <Col lg={12} className='d-flex justify-content-end gap-3 earnings-filter'>
                    {
                        time_period?.value === "Today" || time_period?.value === "Yesterday" ?
                            <Col lg={3} sm={3} className="d-flex justify-content-end mb-4" >
                                <DatePicker
                                    className="report-date-picker"
                                    selected={start_date}
                                    showIcon
                                    disabled={true}
                                />
                            </Col>
                            : null
                    }
                    {
                        time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30" ?
                            <Col xl={3} sm={3} className="d-flex justify-content-end mb-4" >
                                <DatePicker
                                    className="report-date-picker"
                                    selectsRange={true}
                                    startDate={start_date}
                                    endDate={end_date}
                                    showIcon
                                    disabled={true}
                                />
                            </Col>
                            : null
                    }
                    {
                        time_period?.value === "Current-month" || time_period?.value === "Previous-month" ?
                            <Col lg={3} sm={3} className="d-flex justify-content-end mb-4" >
                                <DatePicker
                                    className="report-date-picker"
                                    selected={start_date}
                                    dateFormat="MM/yyyy"
                                    showIcon
                                    disabled={true}
                                />
                            </Col>
                            : null
                    }
                    {
                        time_period?.value === "Custom" ?
                            <Col lg={3} sm={3} className="d-flex justify-content-end mb-4" >
                                <DatePicker
                                    placeholderText='select date range'
                                    className="report-date-picker"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    showIcon
                                    maxDate={new Date()}
                                />
                            </Col>
                            : null
                    }
                    {
                        timePeriod.some(period => period.value === time_period?.value) ?
                            null
                            :
                            <Col lg={3} sm={6} className="d-flex justify-content-end mb-0" >
                                <DatePicker
                                    placeholderText='selected date'
                                    className="report-date-picker"
                                    selected={start_date}
                                    showIcon
                                    disabled={true}
                                />
                            </Col>
                    }

                    <Col lg={3} sm={6} className="mb-4" >
                        <Select
                            options={timePeriod}
                            className="custom-react-select"
                            value={time_period}
                            onChange={(value) => { setTime_period(value) }}
                        />
                    </Col>

                </Col>
                {EarningsHistory?.data?.data?.orders?.length > 0 ?
                    <Col lg={12} sm={12} className='blance-card'>
                        <Col lg={12} sm={12} className='d-flex flex-wrap mb-3'>
                            <Col lg={6} sm={12}>
                                <p className='avail-balance'><span>Total Earnings :</span> ₹ {balance?.totalEarings ? balance?.totalEarings : '0.00'}</p>
                            </Col>
                            <Col lg={6} sm={12}>
                                <p className='avail-balance'><span>AMC Share : </span> ₹ {balance?.amcShare ? balance?.amcShare : '0.00'}</p>
                            </Col>
                        </Col>
                        <Col lg={12} sm={12} className='d-flex flex-wrap mb-3'>
                            <Col lg={6} sm={12}>
                                <p className='avail-balance'><span>Astrologers Share :</span> ₹ {balance?.availableBalance ? balance?.availableBalance : '0.00'}</p>
                            </Col>
                            <Col lg={6} sm={12}>
                                <p className='avail-balance'><span>Astrologers Payable Amount : </span> ₹ {balance?.payableAmount ? balance?.payableAmount : '0.00'}</p>
                            </Col>
                        </Col>
                        <Col className='d-flex flex-wrap  gap-2 mt-3'>
                            <Col lg={3} sm={5}>
                                <p><span>PG Charge  :</span> ₹ {balance?.pgCharge ? balance?.pgCharge : '0.00'}</p>
                            </Col>
                            <Col lg={3} sm={5}>
                                <p><span>TDS : </span> ₹ {balance?.tdsCharge ? balance?.tdsCharge : '0.00'}</p>
                            </Col>
                            <Col lg={2} sm={5}>
                                <p><span>GST : </span> ₹ {balance?.gstCharge ? balance?.gstCharge : '0.00'}</p>
                            </Col>
                            {/* <Col lg={3} sm={5}>
                            <p >Payable Amount : <span className='fw-bold text-black'> ₹ {balance?.payableAmount ? balance?.payableAmount : '0.00'}</span></p>
                        </Col> */}
                        </Col>
                    </Col>
                    : null
                }
                <Col lg={12} className='transaction-table mt-5'>
                    {EarningsHistory?.data?.data?.orders?.length > 0 ?
                        <>
                            <table className='table-270' >
                                <thead>
                                    <tr className='transaction-heading'>
                                        <th>S No</th>
                                        <th>Date & Time</th>
                                        <th>Order Id</th>
                                        <th>Astrologer AMC ID</th>
                                        <th>User AMC ID</th>
                                        <th>Duration</th>
                                        <th>Type</th>
                                        <th>Total Amount</th>
                                        <th>Your Share</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {EarningsHistory?.data?.data?.orders?.map((data, index) => {
                                        let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                        let Minute = Math.floor(data.duration / 60);
                                        const seconds = data.duration % 60;
                                        return (
                                            <>
                                                <tr className='transaction-data'>
                                                    <td>
                                                        {
                                                            EarningsHistory?.data?.data?.pagination?.currentpage === 1 ?
                                                                <>
                                                                    {index + 1}
                                                                </>
                                                                :
                                                                <>
                                                                    {((EarningsHistory?.data?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                </>
                                                        }
                                                    </td>
                                                    <td>{indianTime}</td>
                                                    <td>{data.id}</td>
                                                    <td>{data.astrologer?.amc_id}</td>
                                                    <td>{data.user?.amc_id}</td>
                                                    <td>{Minute} min {seconds} sec</td>
                                                    <td>{data.type}</td>
                                                    <td className='payment-success' ><span className='text-black'>₹</span> + {data.totalrate}</td>
                                                    <td className='payment-success' ><span className='text-black'>₹</span> + {data.amc_share_cost}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                        :
                        <>
                            <div className='no-data'>
                                <p>No sufficient data available!</p>
                            </div>
                        </>
                    }
                </Col>
                {EarningsHistory?.data?.data?.pagination?.totalPage > 0 ?
                    <>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={EarningsHistory?.data?.data?.pagination?.totalPage}
                            forcePage={EarningsHistory?.data?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                    </ >
                    :
                    null
                }
            </Row>
        </>
    );
};

export default Earnings;