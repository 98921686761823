import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import MainPagetitle from '../../layouts/MainPagetitle';
import NewAstrologerPreview from './NewAstrologerPreview';
import AcceptNewAstrologer from './AcceptNewAstrologer';

import EmptyProfile from '../../../images/dashboard/empty-profile.png';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetNewAstrologers, ActivateOrRejectAstrologers, successMessage, errorMessage } from '../../../Redux/Slice';

const NewAstrologersList = () => {
    const dispatch = useDispatch();
    const { NewAstrologers, Loading, success, errors } = useSelector((state) => state.Astromind)

    const token = localStorage.getItem('admin-auth-token');
    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const [newAstro, setNewAstro] = useState(null);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAcceptClose = () => {
        setIsAccepted(false);
        setIsRejected(false);
    }

    useEffect(() => {
        dispatch(GetNewAstrologers({ token: token, page: 1 }))
    }, []);

    useEffect(() => {
        setCurrentPage(NewAstrologers?.data?.pagination?.currentpage);
    }, [NewAstrologers]);

    const changePage = (value) => {
        dispatch(GetNewAstrologers({ token: token, page: value }))
    }

    const nextPage = () => {
        const next = Number(currentPage) + 1;
        if (next <= NewAstrologers?.data?.pagination?.totalPage) {
            dispatch(GetNewAstrologers({ token: token, page: next }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(currentPage) - 1;
        if (previousPage >= 1) {
            dispatch(GetNewAstrologers({ token: token, page: previousPage }));
        }
    }




    const PreviewCall = (data) => {
        setPreviewData(data)
        handleShow()
    }

    const approveAstrologer = (id) => {
        console.log(id);
        setIsAccepted(true);
        setNewAstro({ id, status: 1 });
    }

    const rejectAstrologer = (id) => {
        console.log(id);
        setIsRejected(true);
        setNewAstro({ id: id, status: 0 });
    }

    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetNewAstrologers({ token: token, page: 1 }));
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK",
                timer: 5000
            }).then((result) => {
                handleAcceptClose();
                handleClose();
                dispatch(successMessage(''));
            })
        }

        else if (errors?.status === 400) {
            dispatch(GetNewAstrologers({ token: token, page: 1 }));
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
            })
        }
    }, [success, errors]);

    return (
        <>
            <Container fluid >
                <MainPagetitle pageTitle="New Applicant" parentTitle="Home" />
                <div className='list-title'>
                    <h3 className='fw-600'>New Applicant</h3>
                </div>
                <Row className={`d-flex ${show ? null : "justify-content-center"}`}>
                    {Loading ?
                        <>
                            <div class="wrapper">
                                <div class="page-loader">
                                    <div class="loading one"></div>
                                    <div class="loading two"></div>
                                    <div class="loading three"></div>
                                    <div class="loading four"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {NewAstrologers?.data?.data.length < 1 ?
                                <>
                                    <div className='no-data'>
                                        <p>No sufficient data available!</p>
                                    </div>
                                </>
                                :
                                <>
                                    <Col lg={12} key="newastrologers" className={`d-flex flex-wrap ${show && "new-astroList-main"} gap-3 mt-4`}>
                                        {
                                            NewAstrologers?.data?.data?.map((item, index) => {
                                                let indianTime = new Date(item?.profileDetail?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                let description = item?.profileDetail?.description?.data?.map(obj => obj.name);
                                                let language = item?.profileDetail?.language?.data?.map(obj => obj.name);

                                                let profile;
                                                if (item?.files != []) {
                                                    item?.files.map((file) => {
                                                        if (file.filefor === "profile") {
                                                            profile = file;
                                                        }
                                                    })
                                                }
                                                else {
                                                    profile = null;
                                                }

                                                return (
                                                    <>

                                                        <Col lg={6} sm={6} key={index} className={`astrologer-Card ${show && "astrologer-fullCard"} p-3`} >
                                                            <div className='change-date'>
                                                                <p>{indianTime}</p>
                                                            </div>
                                                            <Col lg={12} sm={12} className='d-flex gap-2 astrologer-CardCol' onClick={() => PreviewCall(item)}>
                                                                <Col lg={4} sm={5} className='astrologerCard-img'>
                                                                    <div className='d-flex justify-content-center astro-person-img'>
                                                                        <img src={profile ? profile?.file : EmptyProfile} alt="" />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={8} sm={7} className='astrologerCard-content'>
                                                                    <div >
                                                                        <h6 className='font-700'>{item?.profileDetail?.name}</h6>
                                                                        <p className='description'>{description?.join(", ")}</p>
                                                                    </div>
                                                                    <div className='d-flex gap-3 exprience'>
                                                                        {item?.profileDetail?.experience ? <p>Exp:<span className='text-black font-700 px-2'>{item?.profileDetail?.experience} years</span></p> : null}
                                                                        {/* {item.qualification ? <p className='qualification-para'>Qual:<span className='text-black font-700 px-2'>{item.qualification}</span></p> : null} */}
                                                                    </div>
                                                                    <div className='language'>
                                                                        {language ? <p className='lang-para'>Lang:<span className='text-black font-700 px-2'>
                                                                            {language?.join(", ")}
                                                                        </span></p> : null}

                                                                    </div>
                                                                    <div className='more-details' onClick={() => PreviewCall(item)} >
                                                                        More Details
                                                                    </div>

                                                                </Col>
                                                            </Col>
                                                            <Col lg={12} sm={12} className='btn-web'>
                                                                <div>
                                                                    <button className='green-btn' onClick={() => approveAstrologer(item?.profileDetail?.id)}>Approve</button>
                                                                </div>
                                                                <div>
                                                                    <button className='yellow-btn' onClick={() => rejectAstrologer(item?.profileDetail?.id)}>Reject</button>
                                                                </div>

                                                            </Col>
                                                        </Col>
                                                    </>
                                                )
                                            })
                                        }
                                    </Col>


                                    <Col lg={12} className='d-flex justify-content-center mt-5 pagination'>
                                        <Pagination className="px-4">
                                            <Pagination.Prev onClick={() => previousPage()} />

                                            {[...new Array(NewAstrologers?.data?.pagination?.totalPage)].map((arr, index) => {
                                                return (
                                                    <Pagination.Item
                                                        onClick={() => changePage(index + 1)}
                                                        key={index + 1}
                                                        active={index + 1 === Number(currentPage)}
                                                    >
                                                        {index + 1}
                                                    </Pagination.Item>
                                                )
                                            })}
                                            <Pagination.Next onClick={() => nextPage()} />
                                        </Pagination>
                                    </Col>
                                </>
                            }
                        </>
                    }

                </Row>
            </Container>

            <NewAstrologerPreview show={show} handleClose={handleClose} data={previewData} />
            <AcceptNewAstrologer isAccepted={isAccepted} isRejected={isRejected} handleClose={handleAcceptClose} newAstro={newAstro} />

        </>
    )
}

export default NewAstrologersList;