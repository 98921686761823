import { useEffect, useState } from 'react';
// import addNotification from 'react-push-notification';

// import Notification from 'react-web-notification';

import AstroLogo from '../../../images/Astro-logo.svg';

import io from "socket.io-client";

const socket = io('https://dev-astromind-api.astromindcounsel.com', {
    withCredentials: true,
    transports: ['websocket', 'polling'],
    query: {
        who: 'admin'
    }
});

function BrowserNotification() {

    function requestNotificationPermission() {
        if ("Notification" in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    console.log("Notification permission granted.");
                } else {
                    console.log("Notification permission denied.");
                }
            });
        } else {
            console.log("Browser does not support notifications.");
        }
    }

    useEffect(() => {
        requestNotificationPermission();
    }, []);

    useEffect(() => {
        const showNotification = (title, description, isToNew, isToOld, redirectUrl) => {

            if ("serviceWorker" in navigator && Notification.permission === "granted") {
                console.log("granted");
                console.log("serviceWorker" in navigator);

                console.log(navigator.serviceWorker.ready);

                navigator.serviceWorker.ready
                    .then((registration) => {
                        console.log(registration);

                        registration.showNotification(title, {
                            title: title,
                            body: description,
                            theme: 'darkblue',
                            native: true, // when using native, your OS will handle theming.
                            duration: 5000,
                            icon: AstroLogo,
                            onClick: () => window.location = redirectUrl,
                            renotify: false,
                            // Other options
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    })

                // Optional: Handle click event
            } else {
                console.log("Notifications are not allowed or supported.");
            }
        };
        socket.on('send-notify-to-admin', showNotification);

        return () => {
            socket.off('send-notify-to-admin', showNotification);
        };
    });

    useEffect(() => {
        const showNotification = (title, description, ticketId) => {
            console.log(title);
            console.log(description);
            console.log(ticketId);
            
            if ("serviceWorker" in navigator && Notification.permission === "granted") {
                console.log("granted");
                console.log("serviceWorker" in navigator);

                console.log(navigator.serviceWorker.ready);

                navigator.serviceWorker.ready
                    .then((registration) => {
                        console.log(registration);

                        registration.showNotification(title, {
                            title: title,
                            body: description,
                            theme: 'darkblue',
                            native: true, // when using native, your OS will handle theming.
                            duration: 5000,
                            icon: AstroLogo,
                            // onClick: () => window.location = redirectUrl,
                            renotify: false,
                            // Other options
                        });
                    })
                    .catch(err => {
                        console.log(err);
                    })

                // Optional: Handle click event
            } else {
                console.log("Notifications are not allowed or supported.");
            }
        };
        socket.on('receiveSupportNotificationAdmin', showNotification);

        return () => {
            socket.off('receiveSupportNotificationAdmin', showNotification);
        };
    });

    return (
        <>
            <div>
            </div>
        </>
    )
}

export default BrowserNotification;