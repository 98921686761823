import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { FaUserFriends, FaStroopwafel } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { PiPhoneCallFill } from "react-icons/pi";
import { GrUserNew } from "react-icons/gr";
import { MdVerified } from "react-icons/md";


const ActiveCards = ({ data }) => {
    const navigate = useNavigate();
    return (
        <>
            <Col lg={12} className='active-cards'>
                <Col lg={4} className='active-card bg-blue'>
                    <div className='d-flex justify-content-between'>
                        <div className='active-count'>
                            <p>{data?.activeUsers  ? data?.activeUsers : 0 }</p>
                        </div>
                        <div className='active-icon'>
                            <FaUserFriends className='icon' />
                        </div>
                    </div>
                    <div className='active-title'>
                       <p>Active User</p>
                    </div>
                </Col>
                <Col lg={4} className='active-card bg-yellow' onClick={() => navigate('/online-astrologers')}>
                    <div className='d-flex justify-content-between'>
                        <div className='active-count'>
                            <p>{data?.onlineAstrologersCount  ? data?.onlineAstrologersCount : 0 }</p>
                        </div>
                        <div className='active-icon'>
                            <MdVerified className='icon' />
                        </div>
                    </div>
                    <div className='active-title'>
                       <p>Active Astrologer</p>
                    </div>
                </Col>
                <Col lg={4} className='active-card bg-blueGreen' onClick={() => navigate('/users')}>
                    <div className='d-flex justify-content-between'>
                        <div className='active-count'>
                        <p>{data?.totalNewUsers  ? data?.totalNewUsers : 0 }</p>
                        </div>
                        <div className='active-icon'>
                            <GrUserNew className='icon' />
                        </div>
                    </div>
                    <div className='active-title'>
                       <p>New User</p>
                    </div>
                </Col>
                <Col lg={4} className='active-card bg-Green' onClick={() => navigate('/all-astrologers')}>
                    <div className='d-flex justify-content-between'>
                        <div className='active-count'>
                        <p>{data?.totalNewAstrologers ? data?.totalNewAstrologers : 0 }</p>
                        </div>
                        <div className='active-icon'>
                            <FaStroopwafel className='icon' />
                        </div>
                    </div>
                    <div className='active-title'>
                       <p>New Astrologer</p>
                    </div>
                </Col>
                <Col lg={4} className='active-card bg-skyBlue' onClick={() => navigate('/order-history/chat-logs/')}>
                    <div className='d-flex justify-content-between'>
                        <div className='active-count'>
                        <p>{data?.totelChatList ? data?.totelChatList : 0 }</p>
                        </div>
                        <div className='active-icon'>
                            <BsChatDots className='icon' />
                        </div>
                    </div>
                    <div className='active-title'>
                       <p>Total Chat</p>
                    </div>
                </Col>
                <Col lg={4} className='active-card bg-lightGreen' onClick={() => navigate('/order-history/call-logs/')}>
                    <div className='d-flex justify-content-between'>
                        <div className='active-count'>
                        <p>{data?.totelCallList ? data?.totelCallList : 0 }</p>
                        </div>
                        <div className='active-icon'>
                            <PiPhoneCallFill className='icon' />
                        </div>
                    </div>
                    <div className='active-title'>
                       <p>Total Call</p>
                    </div>
                </Col>
            </Col>
        </>
    )
}

export default ActiveCards;