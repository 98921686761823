import { Col } from 'react-bootstrap';
import { AiFillStar } from "react-icons/ai";
import EmptyProfile from '../../../../images/dashboard/empty-profile.png'

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllAstrologers } from '../../../../Redux/Slice';

const AstrologerTopRating = () => {
    const dispatch = useDispatch();
    const { Astrologers } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    useEffect(() => {
        dispatch(GetAllAstrologers({ token: token, page: 1 , type: "all-astrologers"}))
    }, []);

    return (
        <>
            <Col lg={12} className='top-rating-dashboard'>
                <div className='top-rating-title'>
                    <h4 className='text-white'>Top Rating Astrologers</h4>
                </div>

                <div className='top-raters'>
                    {
                        Astrologers?.data?.data?.map((astro, index) => {
                            
                            let profile;
                            if (astro?.files != []) {
                                astro?.files.map((file) => {
                                    if (file.filefor === "profile") {
                                        profile = file;
                                    }
                                })
                            }
                            else {
                                profile = null;
                            }

                            if(astro?.profileDetails?.id === 1){
                                 return;
                            }
                            return (
                                <>
                                    <Col lg={12} className='d-flex justify-content-between top-rater-card'>
                                        <Col lg={2} className='top-raters-profile'>
                                            <img src={profile ? profile?.file : EmptyProfile } alt="" />
                                        </Col>
                                        <Col lg={6} className='top-raters-details' >
                                            <h4>{astro?.profileDetails?.name}</h4>
                                            <p>Astrologer</p>
                                        </Col>
                                        <Col lg={4} className="profileStar mt-2">
                                            <span>
                                                {[...new Array(5)].map((arr, index) => {
                                                    return index < `${astro?.profileDetails?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                })}
                                            </span>
                                        </Col>
                                    </Col>
                                </>
                            )
                        })
                    }
                </div>
            </Col>
        </>
    )
}

export default AstrologerTopRating;