import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col } from 'react-bootstrap';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { AcceptOrRejectAstrologersRequest } from '../../../Redux/Slice';


const ChangesReject = ({ isRejected, handleClose, requestId }) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token')
    const [reason, setReason] = useState(null);

    const handleReject = () => {
        let data = {
            reason: reason
        }
        dispatch(AcceptOrRejectAstrologersRequest({ token, id: requestId, status: 0, data }));
        handleClose();
    }
    return (
        <>
            <Modal
                show={isRejected}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please fill the details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col className='astrologer-details'>
                        <Col lg={12} className='astrologer-form'>
                            <h5 >
                                Reason for Rejection
                                <span className='text-red'>*</span>
                            </h5>
                            <textarea
                                type='text'
                                name='reason'
                                id='reason'
                                value={reason}
                                onChange={(e) => { setReason(e.target.value) }}
                            />
                        </Col>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        disabled={!reason}
                        onClick={() => { handleReject() }}
                    >
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ChangesReject;