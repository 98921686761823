import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';

import { RiDeleteBin6Fill } from "react-icons/ri";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllUnregisterUsers, DeleteUnregisterUser, successMessage, errorMessage } from '../../../Redux/Slice';

const UnregisterUsersList = () => {
    const dispatch = useDispatch();
    const { UnregisterUsers, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [currentPage, setCurrentPage] = useState(1);
    const pageLimit = window.innerWidth < 600 ? 15 : 25;

    useEffect(() => {
        dispatch(GetAllUnregisterUsers({ token, page: currentPage, pageLimit }));
    }, [dispatch, token]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (UnregisterUsers?.data?.data?.pagination?.currentpage) {
            setCurrentPage(UnregisterUsers?.data?.data?.pagination?.currentpage);
        }
    }, [UnregisterUsers]);

    useEffect(() => {
        if (success?.data?.data?.isDeleted) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage(''));
                dispatch(GetAllUnregisterUsers({ token, page: currentPage, pageLimit }));
            })
        }
        else if (errors) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
                dispatch(GetAllUnregisterUsers({ token, page: currentPage, pageLimit }));
            })
        }
    }, [dispatch, token, success, errors, pageLimit]);    

    const handleDelete = (id) => {
        let userId = id;
        Swal.fire({
            title: "Are you sure want to Delete your Data?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(DeleteUnregisterUser({ token, userId }));
            }
        });
    }

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetAllUnregisterUsers({ token, page, pageLimit }));
    }

    return (
        <>
            <MainPagetitle pageTitle="Users" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex user-list'>
                    <Col lg={12} className='list-main'>
                        <Col lg={12} sm={12} className='d-flex py-5'>
                            <h2 className='fw-600'>Unregistered Users</h2>
                        </Col>
                        {Loading ?
                            <>
                                <div className="wrapper">
                                    <div className="page-loader">
                                        <div className="loading one"></div>
                                        <div className="loading two"></div>
                                        <div className="loading three"></div>
                                        <div className="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    UnregisterUsers?.data?.data?.unRegisteredUsers?.length > 0 ?

                                        <Col lg={12} className='list-data mb-3'>
                                            <table className="table-100 table-120" >
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Date</th>
                                                        <th className='responsive-hide'>Phone or Email</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {UnregisterUsers?.data?.data?.unRegisteredUsers?.map((data, index) => {
                                                        let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td>
                                                                        {
                                                                            UnregisterUsers?.data?.data?.pagination?.currentpage === 1 ?
                                                                                <>
                                                                                    {index + 1}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {((UnregisterUsers?.data?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td>{indianTime}</td>
                                                                    <td className='responsive-hide'>{data?.phone ? `+${data?.phone}` : data?.email}</td>
                                                                    <td>
                                                                        <Link className="btn btn-danger btn-sm content-icon ms-1"
                                                                            onClick={() => handleDelete(data?.id)}
                                                                        >
                                                                            <RiDeleteBin6Fill />
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </Col>
                                        :
                                        <>
                                            <div className='no-data pt-5'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                }
                            </>
                        }

                    </Col>

                    {UnregisterUsers?.data?.data?.pagination?.totalPage > 1 ?
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={UnregisterUsers?.data?.data?.pagination?.totalPage}
                            forcePage={UnregisterUsers?.data?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                        : null
                    }
                </Row>
            </Container >
        </>
    )
}

export default UnregisterUsersList;