import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';

import MainPagetitle from '../../layouts/MainPagetitle';
import EmployeePreview from './EmployeePreview';
import FilterAstrologer from './FilterAstrologer';

import { IoClose } from "react-icons/io5";
import { FaFilter, FaSearch } from "react-icons/fa";
import { MdSort } from "react-icons/md";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllEmployee } from '../../../Redux/Slice';

const EmployeeList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Employees, Loading } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    let sortBy = JSON.parse(localStorage.getItem("sortby"));
    let filter = JSON.parse(localStorage.getItem("filter"));

    const [show, setShow] = useState(false);
    const [previewData, setPreviewData] = useState();
    const [currentPage, setCurrentPage] = useState(Employees?.data?.pagination?.currentpage);
    const [searchValue, setSearchValue] = useState(null);
    const [showFilter_or_sort, setShowFilter_or_sort] = useState({
        status: false,
        type: ''
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const closeFilter = (value) => {
        setShowFilter_or_sort({ ...showFilter_or_sort, status: value });
    }

    useEffect(() => {
        dispatch(GetAllEmployee({ token: token, page: 1 }));
        if (sortBy) {
            localStorage.removeItem("sortby");
        }
    }, []);

    useEffect(() => {
        setCurrentPage(Employees?.data?.pagination?.currentpage);
    }, [Employees]);

    useEffect(() => {
        if (searchValue) {
            dispatch(GetAllEmployee({ token, page: currentPage, search: searchValue }));
        }
        else if (searchValue == '') {
            dispatch(GetAllEmployee({ token, page: currentPage }));
        }
    }, [searchValue])

    const changePage = (value) => {
        dispatch(GetAllEmployee({ token: token, page: value }))
    }

    const nextPage = () => {
        const next = Number(currentPage) + 1;
        if (next <= Employees?.data?.pagination?.totalPage) {
            dispatch(GetAllEmployee({ token: token, page: next }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(currentPage) - 1;
        if (previousPage >= 1) {
            dispatch(GetAllEmployee({ token: token, page: previousPage }));
        }
    }

    const PreviewCall = (data) => {
        setPreviewData(data)
        handleShow()
    }

    return (
        <>
            {
                showFilter_or_sort ?
                    <FilterAstrologer
                        show={showFilter_or_sort}
                        close={closeFilter}
                        sortBy={sortBy}
                        filter={filter}
                    />
                    : null
            }

            <Container fluid>
                <Row className='d-flex employee-list'>
                    <Col lg={12} className='list-main'>
                        <MainPagetitle pageTitle="Employees" parentTitle="Home" />

                        <Col lg={12} className='d-flex py-5'>
                            <Col lg={3} sm={3} className=''>
                                <h2 className='fw-600'>Employees</h2>
                            </Col>
                            <Col lg={9} sm={12} className="d-flex justify-content-end gap-3 filter-nav">
                                <div className="d-flex text-white search-option">
                                    <input type="text" value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
                                    {
                                        searchValue ?
                                            <div className="d-flex align-items-center p-2 cancel-btn" onClick={() => { setSearchValue('') }}>
                                                <IoClose />
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center p-2 search-btn">
                                                <FaSearch />
                                            </div>
                                    }
                                </div>
                                <button
                                    className={`filter-btn`}
                                    onClick={() => { setShowFilter_or_sort({ status: true, type: 'filter' }) }}
                                >
                                    <FaFilter className='icon' />
                                    <p>Filter</p>
                                </button>
                                <div className='add-employee'>
                                    <button onClick={() => { navigate('/add-employee') }}>Add Employees</button>
                                </div>
                            </Col>
                        </Col>
                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    Employees?.data?.data?.length > 0 ?

                                        <Col lg={12} className='list-data mb-3'>
                                            <table className={show ? "table-60" : "table-100"} >
                                                <tr>
                                                    {/* <th>AMC ID</th> */}
                                                    <th>Name</th>
                                                    <th className={`${show ? "hide-list" : null} responsive-hide`}>Email</th>
                                                    <th className='responsive-hide'>Phone</th>
                                                    <th></th>
                                                </tr>
                                                {Employees?.data?.data?.map((data, index) => (
                                                    <>
                                                        <tr>
                                                            {/* <td>{data?.profileDetails?.amc_id}</td> */}
                                                            <td>{data?.profileDetail?.name}</td>
                                                            <td className={`${show ? "hide-list" : null} responsive-hide`}>{data?.profileDetail?.email}</td>
                                                            <td className='responsive-hide'>+{data?.profileDetail?.phone}</td>
                                                            <td className='view' onClick={() => PreviewCall(data)}>view details</td>

                                                        </tr>
                                                    </>
                                                ))}
                                            </table>
                                        </Col>
                                        :
                                        <>
                                            <div className='no-data pt-5'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                }
                            </>
                        }
                        <EmployeePreview show={show} handleClose={handleClose} data={previewData} />

                    </Col>

                    {Employees?.data?.pagination?.totalPage > 1 ?
                        <Col lg={12} className='d-flex justify-content-center mt-5 pagination'>

                            <Pagination className="px-4">
                                <Pagination.Prev onClick={() => previousPage()} />

                                {[...new Array(Employees?.data?.pagination?.totalPage)].map((arr, index) => {
                                    return (
                                        <Pagination.Item
                                            onClick={() => changePage(index + 1)}
                                            key={index + 1}
                                            active={index + 1 === Number(currentPage)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    )
                                })}
                                <Pagination.Next onClick={() => nextPage()} />
                            </Pagination>
                        </Col>
                        : null
                    }

                </Row>
            </Container >
        </>
    )
}

export default EmployeeList;