import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useDispatch } from 'react-redux';
import { ChangeWalletpin } from '../../../Redux/Slice';

function ChangeWalletPin({ show, close }) {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');

    const [pinValues, setPinValues] = useState({
        oldpin: '',
        newpin: ''
    });
    const [errorMessages, setErrorMessages] = useState("");

    const handleUpdatePin = () => {
        console.log(pinValues);
        if (pinValues?.oldpin.length < 4 || pinValues?.newpin.length < 4) {
            setErrorMessages("Pin should be 4 digits.");
        }
        else{
            dispatch(ChangeWalletpin({ token, pinValues}));
            setErrorMessages(" "); 
        }
    }
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => close(false)}
                className='edit-wallet-modal'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className='pb-2'>
                            <h3 className='text-white text-center'>Enter your Old PIN </h3>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <input
                                type="text"
                                className='change-pin-input'
                                maxLength={4}
                                name='oldpin'
                                value={pinValues?.oldpin}
                                onChange={(e) => setPinValues({ ...pinValues, oldpin: e.target.value })}
                            />
                        </div>
                        <div className='pb-2 mt-4'>
                            <h3 className='text-white text-center'>Enter your New PIN </h3>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <input
                                type="text"
                                className='change-pin-input'
                                maxLength={4}
                                name='newpin'
                                value={pinValues?.newpin}
                                onChange={(e) => setPinValues({ ...pinValues, newpin: e.target.value })} />
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <Button
                                type={"primary"}
                                className='bg-yellow btn'
                                disabled={!(pinValues?.oldpin && pinValues?.newpin)}
                                onClick={() => { handleUpdatePin() }} >
                                Submit
                            </Button>
                        </div>
                        <div className='pin-response pt-3'>
                            <p>{errorMessages}</p>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeWalletPin;