import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { IoImages } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";

import MainPagetitle from '../../layouts/MainPagetitle';

import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { UpdateOffer, GetOffer, GetAstrologerList, DeleteOffer, UpdateAdminProfileImg, successMessage, errorMessage } from '../../../Redux/Slice';

const discountPercentage = [
    { value: '100', label: 'Free' },
    { value: '10', label: '10% Offer' },
    { value: '25', label: '25% Offer' },
    { value: '50', label: '50% Offer' },
]
const sharePercentage = [
    { value: '40', label: '40%' },
    { value: '45', label: '45%' },
    { value: '50', label: '50%' },
    { value: '55', label: '55%' },
    { value: '60', label: '60%' },
]
const userType = [
    { value: 'New Users', label: 'New Users' },
    { value: 'Loyal Users', label: 'Loyal Users' },
    { value: 'All Users', label: 'All Users' },
]

function VieworEditOffer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Offer, OfferAstrologerList, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("admin-auth-token");
    const { id } = useParams();

    const [astrologerOption, setAstrologerOption] = useState([{}]);
    const [selected, setSelected] = useState([]);
    const [imageUpload, setImageUpload] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const AstrologerLength = OfferAstrologerList?.data?.data?.length;
    const selectedLength = selected?.length;

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const today = getCurrentDate();

    useEffect(() => {
        dispatch(GetAstrologerList({ token }));
        if (id) {
            dispatch(GetOffer({ token, offerId: id }));
        }
    }, [dispatch, token, id])

    useEffect(() => {
        if (Offer) {
            formik.setFieldValue('title', Offer?.data?.data?.offer?.name);
            formik.setFieldValue('discount', { value: Offer?.data?.data?.offer?.discountpercentage, label: `${Offer?.data?.data?.offer?.discountpercentage}% Offer` });
            formik.setFieldValue('validity', Offer?.data?.data?.offer?.validityperoid);
            formik.setFieldValue('user_type', { value: Offer?.data?.data?.offer?.usertype, label: Offer?.data?.data?.offer?.usertype });
            formik.setFieldValue('astro_share', { value: Offer?.data?.data?.offer?.astrologershare, label: `${Offer?.data?.data?.offer?.astrologershare}%` });
            formik.setFieldValue('amc_share', { value: Offer?.data?.data?.offer?.amcshare, label: `${Offer?.data?.data?.offer?.amcshare}%` });
            formik.setFieldValue('terms_condition', Offer?.data?.data?.offer?.termsandconditions);
            formik.setFieldValue('image', Offer?.data?.data?.offer?.image);

            if (Offer?.data?.data?.offer?.isOfferToAll) {
                setSelected(astrologerOption);
            }
            else if (Offer?.data?.data?.offerBelongsTo?.length > 0) {
                let selectedAstro = Offer.data.data.offerBelongsTo?.filter(offerAstro =>
                    astrologerOption?.some(astro => astro?.value === offerAstro?.astrologerId)
                ).map(offerAstro => {
                    const astro = astrologerOption.find(astro => astro?.value === offerAstro?.astrologerId);
                    return astro;
                });
                setSelected(selectedAstro);
            }
        }
    }, [Offer])

    useEffect(() => {
        if (pathname.includes("edit-offer")) {
            setIsEdit(true);
        }
        else if (pathname.includes("view-offer")) {
            setIsEdit(false);
        }
        if (OfferAstrologerList?.data?.data) {
            const options = OfferAstrologerList.data.data.map((value) => ({
                value: value?.id,
                label: `${value?.amc_id} - ${value?.name}`
            }));
            setAstrologerOption(options);
        }
        if (imageUpload) {
            const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            const fileType = imageUpload.type;

            if (supportedFormats.includes(fileType)) {
                let ExistFile = null;
                let formData = new FormData();
                formData.append('filefor', 'offer');
                formData.append('file', imageUpload);
                dispatch(UpdateAdminProfileImg({ token, ExistFile, formData }))
            }
            else {
                formik.setFieldError('image', "Unsupported image format. Please upload a JPG, JPEG, PNG, WEBP or GIF file.")
            }
        }
        if (success) {
            if (success?.data?.isofferCreated) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    navigate('/special-offer')
                })
            }
            else if (success?.data?.isDeleted) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    navigate('/special-offer')
                })
            }
            else if (success?.data?.isOfferUpdated) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    navigate(`/view-offer/${id}`)
                    dispatch(GetOffer({ token, offerId: id }));
                })
            }
            else if (success?.data?.file) {
                formik.setFieldValue('image', success?.data?.file);
                setImageUpload(null);
                dispatch(successMessage(''));
            }
        }
        if (errors) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
            })
        }

    }, [dispatch, token, navigate, pathname, OfferAstrologerList, imageUpload, success, errors])

    const handleDeleteOffer = (offerId) => {
        Swal.fire({
            title: "Are you sure want to Delete your Offer?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(DeleteOffer({ token, offerId }))
            }
        });
    }

    const handleEditOffer = (offerId) => {
        Swal.fire({
            title: "Are you sure want to Edit your Offer?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Edit"
        }).then((result) => {
            if (result?.isConfirmed) {
                navigate(`/edit-offer/${offerId}`)
            }
        });
    }

    const handlePublishOrUnpublish = () => {
        Swal.fire({
            title: `Are you sure want to ${Offer?.data?.data?.offer?.ispublished ? "Unpublish" : "Publish"} your Offer?`,
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: Offer?.data?.data?.offer?.ispublished ? "Unpublish" : "Publish"
        }).then((result) => {
            if (result?.isConfirmed) {
                let updateOffer = {
                    offerId: Offer?.data?.data?.offer?.id,
                    name: Offer?.data?.data?.offer?.name,
                    discountpercentage: Number(Offer?.data?.data?.offer?.discountpercentage),
                    validityperoid: Offer?.data?.data?.offer?.validityperoid,
                    usertype: Offer?.data?.data?.offer?.usertype,
                    astrologershare: Number(Offer?.data?.data?.offer?.astrologershare),
                    amcshare: Number(Offer?.data?.data?.offer?.amcshare),
                    termsandconditions: Offer?.data?.data?.offer?.termsandconditions,
                    isoffertoall: Offer?.data?.data?.offer?.isOfferToAll ? 1 : 0,
                    image: Offer?.data?.data?.offer?.image,
                    status: Offer?.data?.data?.offer?.ispublished ? "save" : "publish"
                }
                dispatch(UpdateOffer({ token, updateOffer }));
            }
        });
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            discount: '',
            validity: '',
            user_type: '',
            astro_share: '',
            amc_share: '',
            terms_condition: '',
            image: '',
            type: ''
        },
        onSubmit: async (values, type) => {
            let isAll = AstrologerLength == selectedLength ? true : false;
            let ids = [];
            if (!isAll) {
                selected?.map((item) => {
                    ids.push(item?.value);
                })
            }

            let updateOffer = {
                offerId: id,
                name: values?.title,
                discountpercentage: Number(values?.discount?.value),
                validityperoid: values?.validity,
                usertype: values?.user_type?.value,
                astrologershare: Number(values?.astro_share?.value),
                amcshare: Number(values?.amc_share?.value),
                termsandconditions: values?.terms_condition,
                isoffertoall: isAll ? 1 : 0,
                image: values?.image,
                ids: ids,
                status: Offer?.data?.data?.offer?.ispublished ? "publish" : "save"
            }
            dispatch(UpdateOffer({ token, updateOffer }))
        },
        validate: (values) => {
            const errors = {};

            if (!values.title && isEdit) {
                errors.title = "Requried*";
            }
            if (!values.discount && isEdit) {
                errors.discount = "Required*";
            }
            if (!values.validity && isEdit) {
                errors.validity = "Required*";
            }
            if (!values.user_type && isEdit) {
                errors.user_type = "Required*";
            }
            if (!values.astro_share && isEdit) {
                errors.astro_share = "Required*";
            }
            if (!values.amc_share && isEdit) {
                errors.amc_share = "Required*";
            }
            else if ((Number(values.astro_share?.value) + Number(values.amc_share?.value)) != 100) {
                errors.amc_share = "please provide efficient share percentage*";
            }
            if (!values.image && isEdit) {
                errors.image = "Required*";
            }
            if (!values.terms_condition && isEdit) {
                errors.terms_condition = "Required*";
            }
            return errors;
        }

    })

    return (
        <>
            <MainPagetitle pageTitle={isEdit ? 'Edit Offer' : 'View Offer'} parentTitle="Special Offer" />

            <Container fluid>
                <Row className='d-flex flex-wrap gap-5 justify-content-center'>
                    <Col lg={11} className="d-flex gap-3 justify-content-end offer-btns">
                        {
                            Offer?.data?.data?.offer?.ispublished ?
                                <button
                                    className="unpublish"
                                    onClick={() => handlePublishOrUnpublish()}
                                >
                                    Unpublish
                                </button>
                                : <button
                                    className="publish"
                                    onClick={() => handlePublishOrUnpublish()}
                                >
                                    Publish
                                </button>
                        }
                        {
                            !isEdit ?
                                <button
                                    className="offer-edit"
                                    onClick={() => handleEditOffer(id)}
                                >
                                    <MdEdit />
                                </button>
                                : null
                        }
                        <button
                            className="offer-delete"
                            onClick={() => handleDeleteOffer(id)}
                        >
                            <RiDeleteBin6Fill />
                        </button>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Offer Title
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <Row >
                                        <Col xl={10} sm={10} >
                                            <form>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={2} sm={2}>
                                            <Button
                                                type="submit"
                                                variant='primary'
                                                className="mt-3 mt-sm-0"
                                                onClick={() => { formik.setFieldValue('title', ''); }}
                                            >Clear</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Offer Details
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <Row >
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Discount Percentage <span className="text-red">*</span></label>
                                            <Select
                                                options={discountPercentage}
                                                className="custom-react-select"
                                                value={formik.values.discount}
                                                onChange={(value) => { formik.setFieldValue('discount', value) }}
                                                isDisabled={!isEdit}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.discount}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Validity Periods <span className="text-red">*</span></label>
                                            <input
                                                type="date"
                                                name='validity'
                                                className="form-control"
                                                value={formik.values.validity}
                                                onChange={formik.handleChange}
                                                disabled={!isEdit}
                                                min={today}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.validity}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">User Type <span className="text-red">*</span></label>
                                            <Select
                                                options={userType}
                                                className="custom-react-select"
                                                value={formik.values.user_type}
                                                onChange={(value) => { formik.setFieldValue('user_type', value) }}
                                                isDisabled={!isEdit}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.user_type}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Astrologer Type <span className="text-red">*</span></label>
                                            <MultiSelect
                                                options={astrologerOption}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Options"
                                                disabled={!isEdit}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.user_type}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Astro Share <span className="text-red">*</span></label>
                                            <Select
                                                options={sharePercentage}
                                                className="custom-react-select"
                                                value={formik.values.astro_share}
                                                onChange={(value) => { formik.setFieldValue('astro_share', value) }}
                                                isDisabled={!isEdit}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.astro_share}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">AMC share <span className="text-red">*</span></label>
                                            <Select
                                                options={sharePercentage}
                                                className="custom-react-select"
                                                value={formik.values.amc_share}
                                                onChange={(value) => { formik.setFieldValue('amc_share', value) }}
                                                displayValue="value"
                                                isDisabled={!isEdit}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.amc_share}</p>
                                        </Col>

                                        <Col xl={12} sm={12} className="mb-4">
                                            <label className="form-label">Terms Condition <span className="text-red">*</span></label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                name='terms_condition'
                                                value={formik.values.terms_condition}
                                                onChange={formik.handleChange}
                                                disabled={!isEdit}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.terms_condition}</p>

                                        </Col>
                                        <Col xl={12} sm={12} className="mb-4" >
                                            <label className="from-label">Upload Image <span className="text-red">*</span></label>
                                            <div className="dz-default dlab-message upload-img bg-lightOrange">
                                                <form action="#" className="dropzone astro-file-upload">
                                                    <IoImages className="file-icon" />

                                                    <div className="fallback">
                                                        <input
                                                            type="file"
                                                            name="image"
                                                            onChange={(e) => {
                                                                setImageUpload(e.target.files[0])
                                                            }}
                                                            disabled={!isEdit}
                                                            multiple
                                                        />
                                                    </div>
                                                    {
                                                        formik?.values?.image ?
                                                            <div className="upload-img-text">
                                                                <p className="file-name">{formik?.values?.image}</p>
                                                            </div>
                                                            :
                                                            <div className="upload-img-text">
                                                                <p>Upload Your Profile Image</p>
                                                                <p className="sub-note"><span className="text-red">Note:</span> Passport size photo with Plain background) (jpg, png, jpeg only)</p>
                                                            </div>
                                                    }
                                                </form>
                                            </div>
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.image}</p>
                                        </Col>
                                        {
                                            isEdit ?
                                                <Col xl={12} sm={12} className='d-flex justify-content-end gap-3'>
                                                    <Button
                                                        type="submit"
                                                        variant="secondary"
                                                        className="mt-3 mt-sm-0"
                                                        onClick={() => {
                                                            formik.handleSubmit()
                                                            formik.setFieldValue('type', "save")
                                                        }}>Save</Button>
                                                    {/* <Button
                                                type="submit"
                                                variant="primary"
                                                className="mt-3 mt-sm-0"
                                                onClick={() => {
                                                    formik.handleSubmit()
                                                    formik.setFieldValue('type', "publish")
                                                }}>Publish</Button> */}
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default VieworEditOffer;