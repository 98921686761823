import axios from 'axios';

class MyUploadAdapter {

  constructor(loader) {
      this.loader = loader;
  }

  upload() {
      return this.loader.file
          .then(file => new Promise((resolve, reject) => {
              const token = localStorage.getItem('admin-auth-token');

              console.log(file);
              const data = new FormData();
              data.append('file', file);
              data.append('filefor', 'blog')
              console.log(data);

              // fetch('http://13.201.217.89:8000/upload-file', { // Replace with your upload URL
              //     method: 'POST',
              //     headers: {
              //         'Authorization': token, // Add your custom headers here
              //     },
              //     body: data
              // })
              const headers = {
                  'authorization': token,
                  'Content-Type': 'multipart/form-data',
              }
              const uploadImg =  axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-file`, data,
              { headers: headers })
                  .then(response =>{
                      console.log("response", response);
                      resolve({
                          default: response.data.file // The URL of the uploaded image
                      });
                      // response.json()
                  } )
                  // .then(data => {
                  //     console.log("data", data);
                  //     resolve({
                  //         default: data.file // The URL of the uploaded image
                  //     });
                  // })
                  .catch(error => {
                      reject(error);
                  });
          }));
  }

  abort() {
      // Handle abort
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
  };
}

export default MyCustomUploadAdapterPlugin;