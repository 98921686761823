import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { IoImages } from "react-icons/io5";

import MainPagetitle from '../../layouts/MainPagetitle';

import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CreateNewOffer, GetAstrologerList, UpdateAdminProfileImg, successMessage, errorMessage } from '../../../Redux/Slice';

const discountPercentage = [
    { value: '5', label: '5% Offer' },
    { value: '10', label: '10% Offer' },
    { value: '15', label: '15% Offer' },
    { value: '20', label: '20% Offer' },
    { value: '25', label: '25% Offer' },
    { value: '40', label: '40% Offer' },
    { value: '50', label: '50% Offer' },
    { value: '60', label: '60% Offer' },
    { value: '75', label: '75% Offer' },
]
const sharePercentage = [
    { value: '40', label: '40%' },
    { value: '45', label: '45%' },
    { value: '50', label: '50%' },
    { value: '55', label: '55%' },
    { value: '60', label: '60%' },
]
const userType = [
    { value: 'New Users', label: 'New Users' },
    { value: 'Loyal Users', label: 'Loyal Users' },
    { value: 'All Users', label: 'All Users' },
]

function AddOffer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { OfferAstrologerList, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("admin-auth-token");

    const [astrologerOption, setAstrologerOption] = useState([{}]);
    const [selected, setSelected] = useState([]);
    const [imageUpload, setImageUpload] = useState(null);

    const AstrologerLength = OfferAstrologerList?.data?.data?.length;
    const selectedLength = selected?.length;

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const today = getCurrentDate();

    useEffect(() => {
        dispatch(GetAstrologerList({ token }));
    }, [dispatch, token])

    useEffect(() => {
        if (OfferAstrologerList?.data?.data) {
            const options = OfferAstrologerList.data.data.map((value) => ({
                value: value?.id,
                label: `${value?.amc_id} - ${value?.name}`
            }));
            setAstrologerOption(options);
        }
        if (imageUpload) {
            const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            const fileType = imageUpload.type;

            if(supportedFormats.includes(fileType)){
                let ExistFile = null;
                let formData = new FormData();
                formData.append('filefor', 'offer');
                formData.append('file', imageUpload);
                dispatch(UpdateAdminProfileImg({ token, ExistFile, formData }))
            }
            else{
                formik.setFieldError('image', "Unsupported image format. Please upload a JPG, JPEG, PNG, WEBP or GIF file.")
            }
        }
        if (success) {
            if (success?.data?.isofferCreated) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    navigate('/special-offer')
                })
            }
            else {
                formik.setFieldValue('image', success?.data?.file)
                setImageUpload(null);
            }
        }
        else if (errors) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, token, navigate, OfferAstrologerList, imageUpload, success, errors])

    const formik = useFormik({
        initialValues: {
            title: '',
            discount: '',
            validity: '',
            user_type: '',
            astro_share: '',
            amc_share: '',
            terms_condition: '',
            image: '',
            type: ''
        },
        onSubmit: async (values, type) => {
            let isAll = AstrologerLength === selectedLength ? true : false;
            let ids = [];
            if (!isAll) {
                selected?.map((item) => {
                    ids.push(item?.value);
                })
            }

            let newOffer = {
                name: values?.title,
                discountpercentage: Number(values?.discount?.value),
                validityperoid: values?.validity,
                usertype: values?.user_type?.value,
                astrologershare: Number(values?.astro_share?.value),
                amcshare: Number(values?.amc_share?.value),
                termsandconditions: values?.terms_condition,
                isoffertoall: isAll ? 1 : 0,
                image: values?.image,
                ids: ids,
                status: values?.type
            }
            dispatch(CreateNewOffer({ token, newOffer }));
        },
        validate: (values) => {
            const errors = {};

            if (!values.title) {
                errors.title = "Requried*";
            }
            if (!values.discount) {
                errors.discount = "Required*";
            }
            if (!values.validity) {
                errors.validity = "Required*";
            }
            if (!values.user_type) {
                errors.user_type = "Required*";
            }
            if (!values.astro_share) {
                errors.astro_share = "Required*";
            }
            if (!values.amc_share) {
                errors.amc_share = "Required*";
            }
            else if ((Number(values.astro_share?.value) + Number(values.amc_share?.value)) != 100) {
                errors.amc_share = "please provide efficient share percentage*";
            }
            if (!values.image) {
                errors.image = "Required*";
            }
            if (!values.terms_condition) {
                errors.terms_condition = "Required*";
            }

            return errors;
        }
    })

    return (
        <>
            <MainPagetitle pageTitle="Add Offer" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex flex-wrap gap-5 justify-content-center'>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Offer Title
                                    <span className="text-red">*</span>
                                </div>

                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <Row >
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.title}</p>

                                                </div>
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Offer Details
                                </div>

                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <Row >
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Discount Percentage <span className="text-red">*</span></label>
                                            <Select
                                                options={discountPercentage}
                                                className="custom-react-select"
                                                value={formik.values.discount}
                                                onChange={(value) => { formik.setFieldValue('discount', value) }}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.discount}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Validity Periods <span className="text-red">*</span></label>
                                            <input
                                                type="date"
                                                name='validity'
                                                className="form-control"
                                                value={formik.values.validity}
                                                onChange={formik.handleChange}
                                                min={today}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.validity}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">User Type <span className="text-red">*</span></label>
                                            <Select
                                                options={userType}
                                                className="custom-react-select"
                                                value={formik.values.user_type}
                                                onChange={(value) => { formik.setFieldValue('user_type', value) }}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.user_type}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Astrologer Type <span className="text-red">*</span></label>
                                            <MultiSelect
                                                options={astrologerOption}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Select Options"
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.user_type}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">Astro Share <span className="text-red">*</span></label>
                                            <Select
                                                options={sharePercentage}
                                                className="custom-react-select"
                                                value={formik.values.astro_share}
                                                onChange={(value) => { formik.setFieldValue('astro_share', value) }}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.astro_share}</p>
                                        </Col>
                                        <Col xl={6} sm={6} className="mb-4" >
                                            <label className="from-label">AMC share <span className="text-red">*</span></label>
                                            <Select
                                                options={sharePercentage}
                                                className="custom-react-select"
                                                value={formik.values.amc_share}
                                                onChange={(value) => { formik.setFieldValue('amc_share', value) }}
                                                displayValue="value"
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.amc_share}</p>
                                        </Col>
                                        <Col xl={12} sm={12} className="mb-4">
                                            <label className="form-label">Terms Condition <span className="text-red">*</span></label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                name='terms_condition'
                                                value={formik.values.terms_condition}
                                                onChange={formik.handleChange}
                                            />
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.terms_condition}</p>

                                        </Col>
                                        <Col xl={12} sm={12} className="mb-4" >
                                            <label className="from-label">Upload Image <span className="text-red">*</span></label>
                                            <div className="dz-default dlab-message upload-img bg-lightOrange">
                                                <form action="#" className="dropzone astro-file-upload">
                                                    <IoImages className="file-icon" />

                                                    <div className="fallback">
                                                        <input type="file" name="image" onChange={(e) => {
                                                            setImageUpload(e.target.files[0])
                                                        }} multiple />
                                                    </div>
                                                    {
                                                        formik?.values?.image ?
                                                            <div className="upload-img-text">
                                                                <p className="file-name">{formik?.values?.image}</p>
                                                            </div>
                                                            :
                                                            <div className="upload-img-text">
                                                                <p>Upload Your Profile Image</p>
                                                                <p className="sub-note"><span className="text-red">Note:</span> Passport size photo with Plain background) (jpg, png, jpeg only)</p>
                                                            </div>
                                                    }
                                                </form>
                                            </div>
                                            <p className={"error__feedback pt-2 mb-0"}>{formik.errors.image}</p>

                                        </Col>
                                        <Col xl={12} sm={12} className='d-flex justify-content-end gap-3'>
                                            <Button
                                                type="submit"
                                                variant="secondary"
                                                className="mt-3 mt-sm-0"
                                                onClick={() => {
                                                    formik.handleSubmit()
                                                    formik.setFieldValue('type', "save")
                                                }}>Save</Button>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="mt-3 mt-sm-0"
                                                onClick={() => {
                                                    formik.handleSubmit()
                                                    formik.setFieldValue('type', "publish")
                                                }}>Publish</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddOffer;