import { useState } from 'react';
import { Col, Row, Nav, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { GetAllUsers } from '../../../Redux/Slice';

function FilterAstrologer({ show, close, pageLimit, sortBy, filter }) {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');

    const [filterData, setFilterData] = useState({
        usertype: [],
        gender: [],
    });
    // const [sortbyData, setSortbyData] = useState({
    //     experience_high_to_low: false,
    //     experience_low_to_high: false,
    //     price_high_to_low: false,
    //     price_low_to_high: false,
    //     rating_high_to_low: false,
    //     rating_low_to_high: false,
    // })

    // useEffect(() => {
    //     if (sortBy) {
    //         if ((sortBy?.sort == "experience") && (sortBy?.sortby == "hightolow")) {
    //             setSortbyData({ experience_high_to_low: true })
    //         }
    //         else if ((sortBy?.sort == "experience") && (sortBy?.sortby == "lowtohigh")) {
    //             setSortbyData({ experience_low_to_high: true })
    //         }
    //         else if ((sortBy?.sort == "myrate") && (sortBy?.sortby == "hightolow")) {
    //             setSortbyData({ price_high_to_low: true })
    //         }
    //         else if ((sortBy?.sort == "myrate") && (sortBy?.sortby == "lowtohigh")) {
    //             setSortbyData({ price_low_to_high: true })
    //         }
    //         else if ((sortBy?.sort == "rating") && (sortBy?.sortby == "hightolow")) {
    //             setSortbyData({ rating_high_to_low: true })
    //         }
    //         else if ((sortBy?.sort == "rating") && (sortBy?.sortby == "lowtohigh")) {
    //             setSortbyData({ rating_low_to_high: true })
    //         }
    //     }
    //     else if (filter) {
    //         setFilterData(filter);
    //     }

    // }, [sortBy, filter])

    // const handleSortby = (value, option) => {
    //     let sort = value;
    //     let sortby = option;
    //     dispatch(GetAllAstrologers({ token, page: 1, pagelimit: pageLimit, sort, sortby }));
    //     localStorage.setItem("sortby", JSON.stringify({ sort: sort, sortby: sortby }));
    //     close(false);
    // }

    const updateArray = (array, value) => {
        if (array.includes(value)) {
            return array.filter(item => item !== value);
        } else {
            return [...array, value];
        }
    };

    const handleUpdateData = (value, type) => {
        switch (type) {
            case "usertype":
                setFilterData(prevState => ({
                    ...prevState,
                    usertype: updateArray(prevState.usertype, value)
                }));
                break;
            case "gender":
                setFilterData(prevState => ({
                    ...prevState,
                    gender: updateArray(prevState.gender, value)
                }));
                break;

            default:
                console.log(`Unknown type: ${type}`);
        }
    }

    const handleFilter = () => {
        // let filter = JSON.stringify({ "filterdata": filterData });

        let filter = {
            filterdata: filterData
        }

        dispatch(GetAllUsers({ token, page: 1, pageLimit, filter }));
        localStorage.setItem("filter", JSON.stringify(filterData));
        close(false);
    }

    const handleReset = () => {
        setFilterData({
            usertype: [],
            gender: [],
        });
        localStorage.removeItem("filter");
        dispatch(GetAllUsers({ token, page: 1, pageLimit }));
        close(false);
        window.location.reload(false);
    }

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show?.status}
                onHide={() => close(false)}
                className={`${show?.type === 'filter' ? 'filter-modal' : 'sortby-modal'}`}
            >
                <Modal.Header closeButton>
                    <h4>{show?.type === 'filter' ? "Filter" : show?.type === 'sort' ? "Sort By" : null}</h4>
                </Modal.Header>
                <Modal.Body>
                    {/* {
                        show?.type == 'sort' ?
                            <div className='d-flex flex-column gap-4'>
                                <div className='d-flex gap-2 sortby'>
                                    <input type='radio' defaultChecked={sortbyData?.experience_high_to_low} onChange={() => { handleSortby("experience", "hightolow") }} />
                                    <p>Experience : High to Low</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input type='radio' defaultChecked={sortbyData?.experience_low_to_high} onChange={() => { handleSortby("experience", "lowtohigh") }} />
                                    <p>Experience : Low to High</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input type='radio' defaultChecked={sortbyData?.price_high_to_low} onChange={() => { handleSortby("myrate", "hightolow") }} />
                                    <p>Price : High to Low</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input type='radio' defaultChecked={sortbyData?.price_low_to_high} onChange={() => { handleSortby("myrate", "lowtohigh") }} />
                                    <p>Price : Low to High</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input type='radio' defaultChecked={sortbyData?.rating_high_to_low} onChange={() => { handleSortby("rating", "hightolow") }} />
                                    <p>Rating : High to Low</p>
                                </div>
                                <div className='d-flex gap-2 sortby'>
                                    <input type='radio' defaultChecked={sortbyData?.rating_low_to_high} onChange={() => { handleSortby("rating", "lowtohigh") }} />
                                    <p>Rating : Low to High</p>
                                </div>
                            </div>
                            : null
                    } */}
                    {
                        show?.type === 'filter' ?
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={4} className='bg-lightOrange filter-left'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">User Type</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Gender</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={8} className='filter-right'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first" >
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.usertype.includes("New Users")}
                                                        onChange={() => { handleUpdateData("New Users", "usertype") }}
                                                    />
                                                    <p >New Users</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.usertype.includes("Loyal Users")}
                                                        onChange={() => { handleUpdateData("Loyal Users", "usertype") }}
                                                    />
                                                    <p>Loyal Users</p>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className='d-flex flex-column gap-3'>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.gender.includes("Male")}
                                                        onChange={() => { handleUpdateData("Male", "gender") }}
                                                    />
                                                    <p >Male</p>
                                                </div>
                                                <div className='d-flex gap-3 sortby'>
                                                    <input
                                                        type='checkbox'
                                                        defaultChecked={filterData?.gender.includes("Female")}
                                                        onChange={() => { handleUpdateData("Female", "gender") }}
                                                    />
                                                    <p>Female</p>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                            : null
                    }
                </Modal.Body>
                {
                    show?.type === 'filter' ?
                        <Modal.Footer className='d-flex justify-content-between'>
                            <Button
                                variant="warning"
                                className='text-white'
                                onClick={() => { handleReset() }}
                                disabled={!filterData}
                            >
                                Reset All
                            </Button>
                            <Button
                                onClick={() => { handleFilter() }}
                                disabled={!filterData}
                            >
                                Submit
                            </Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>
        </>
    )
}

export default FilterAstrologer;