import { Container, Row, Col, Button } from 'react-bootstrap';
import MainPagetitle from '../../layouts/MainPagetitle';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import DatePicker from "react-datepicker";
import moment from 'moment';

import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";

import { FaDownload } from "react-icons/fa6";

import ViewDownloadReports from './ViewDownloadReports';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologerList, GetAstrologersPayoutReport, GetAllDownloadReports } from '../../../Redux/Slice';

const field = [
    { value: 'name', label: 'Astrologer Name', key: 'astrologer' },
    { value: 'amc_id', label: 'Astrologer AMC ID', key: 'astrologer' },
    { value: 'email', label: 'Email', key: 'astrologer' },
    { value: 'phone', label: 'Phone No', key: 'astrologer' },
    { value: 'available_amount', label: 'Available Amount', key: 'astrologer' },
    { value: 'tds', label: 'TDS', key: 'astrologer' },
    { value: 'gst', label: 'GST', key: 'astrologer' },
    { value: 'payable_amount', label: 'Payable Amount', key: 'astrologer' },
    { value: 'bank_name', label: 'Bank Name', key: 'bank' },
    { value: 'accountholder_name', label: 'Accountholder Name', key: 'bank' },
    { value: 'accountnumber', label: 'Account Number', key: 'bank' },
    { value: 'IFSCcode', label: 'IFSC Code', key: 'bank' },
    { value: 'branch', label: 'Branch', key: 'bank' },
    { value: 'pancard', label: 'Pancard', key: 'bank' },
]

const timePeriod = [
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Last-7', label: 'Last 7 days' },
    { value: 'Last-15', label: 'Last 15 days' },
    { value: 'Last-30', label: 'Last 30 days' },
    { value: 'Current-month', label: 'Current Month' },
    { value: 'Previous-month', label: 'Previous Month' },
    { value: 'Custom', label: 'Custom' },
    { value: ' ', label: ' ' },
]

function AstrologerPayoutReport() {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');
    const { Loading, OfferAstrologerList, AstrologersPayoutReportData, ReportDownloads } = useSelector((state) => state.Astromind);

    const payoutData = AstrologersPayoutReportData?.data?.data?.astrologers[0];
    const tableKeys = payoutData ? Object.keys(payoutData) : null;

    const [astrologerOption, setAstrologerOption] = useState([{}]);
    const [astrologerSelected, setAstrologerSelected] = useState([]);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [selected, setSelected] = useState([
        { value: 'name', label: 'Astrologer Name', key: 'astrologer' },
        { value: 'available_amount', label: 'Available Amount', key: 'astrologer' },
        { value: 'tds', label: 'TDS', key: 'astrologer' },
        { value: 'gst', label: 'GST', key: 'astrologer' },
        { value: 'payable_amount', label: 'Payable Amount', key: 'astrologer' },
        { value: 'bank_name', label: 'Bank Name', key: 'bank' },
        { value: 'accountholder_name', label: 'Accountholder Name', key: 'bank' },
        { value: 'accountnumber', label: 'Account Number', key: 'bank' },
        { value: 'IFSCcode', label: 'IFSC Code', key: 'bank' },
        { value: 'branch', label: 'Branch', key: 'bank' },
        { value: 'pancard', label: 'Pancard', key: 'bank' },
    ]);
    const [time_period, setTime_period] = useState();
    const [viewDownloads, setViewDownloads] = useState(false);
    const [fileName, setFileName] = useState();

    const hideDownloads = (value) => {
        setViewDownloads(value)
    }

    useEffect(() => {
        dispatch(GetAstrologerList({ token }));
    }, [dispatch, token])

    useEffect(() => {
        if (OfferAstrologerList?.data?.data) {
            const options = OfferAstrologerList?.data?.data?.map((value) => ({
                value: value?.id,
                label: `${value?.amc_id} - ${value?.name}`
            }));
            setAstrologerOption(options);
        }
    }, [OfferAstrologerList])

    useEffect(() => {
        if (startDate) {
            formik.setFieldValue('start_date', moment(startDate).format('YYYY-MM-DD'));
        }
        if (endDate) {
            formik.setFieldValue('end_date', moment(endDate).format('YYYY-MM-DD'));
        }
        if (selected) {
            formik.setFieldValue('field', selected);
        }
        if (astrologerSelected?.length > 0) {
            formik.setFieldValue('astrologers_id', astrologerSelected);
        }
    }, [startDate, endDate, selected, astrologerSelected]);

    const formik = useFormik({
        initialValues: {
            astrologers_id: [],
            start_date: '',
            end_date: '',
            field: []
        },
        onSubmit: async (values) => {
            let isAll = astrologerOption?.length === astrologerSelected?.length ? true : false;
            let ids = [];
            if (!isAll) {
                astrologerSelected?.map((item) => {
                    ids.push(item?.value);
                })
            }

            let Start_Date = moment(values.start_date).format('YYYY-MM-DD');
            let End_Date = moment(values.end_date).format('YYYY-MM-DD');

            if (time_period?.value === 'Current-month' || time_period?.value === 'Previous-month') {
                let month = values.start_date.getMonth() + 1;
                let year = values.start_date.getFullYear();
                let firstDay = new Date(year, month - 1, 1);
                let lastDay = new Date(year, month, 0);

                Start_Date = moment(firstDay).format('YYYY-MM-DD');
                End_Date = moment(lastDay).format('YYYY-MM-DD');
            }

            let astrologer_field = [];
            let bank_field = [];

            selected?.map((data) => {
                if (data?.key === 'astrologer') {
                    if (data?.value !== '' && data?.value !== 'SNo' ) {
                        astrologer_field.push(data?.value)
                    }
                }
                else if (data?.key === 'bank') {
                    bank_field.push(data?.value)
                }
            })
            const payoutReportDetails = {
                startDate: Start_Date,
                endDate: End_Date !== 'Invalid date' ? End_Date : Start_Date,
                astrologerIds: ids,
                isAllAstrologers: isAll,
                astrologerFields: astrologer_field,
                bankFields: bank_field
            }
            dispatch(GetAstrologersPayoutReport({ token, payoutReportDetails }));
        },
        validate: (values) => {
            const errors = {};

            if (astrologerSelected?.length <= 0) {
                errors.astrologers_id = "Requried*";
            }
            if (!time_period?.value) {
                errors.time_period = "Requried*";
            }
            if ((time_period?.value === "Custom") && !values.start_date) {
                errors.start_date = "Requried*";
            }
            if ((time_period?.value === "Custom") && !values.end_date) {
                errors.end_date = "Requried*";
            }
            if (selected?.length <= 0) {
                errors.field = "Requried*";
            }

            return errors;
        }
    })

    useEffect(() => {
        const today = new Date();
        if (time_period?.value === "Today") {
            formik.setFieldValue('start_date', today);
        }
        else if (time_period?.value === "Yesterday") {
            const yesterday = new Date(today);

            yesterday.setDate(today.getDate() - 1);
            formik.setFieldValue('start_date', yesterday);
        }
        else if (time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30") {
            const pastday = new Date(today);
            const pastdayCount = time_period?.value === "Last-7" ?
                7
                : time_period?.value === "Last-15" ?
                    15
                    : time_period?.value === "Last-30" ?
                        30
                        : null;
            pastday.setDate(today.getDate() - pastdayCount);
            formik.setFieldValue('start_date', pastday);
            formik.setFieldValue('end_date', today);
        }
        else if (time_period?.value === "Current-month") {
            console.log(today.getMonth());
            console.log(today);

            formik.setFieldValue('start_date', today);
        }
        else if (time_period?.value === "Previous-month") {
            const pastmonth = new Date(today);
            pastmonth.setMonth(today.getMonth() - 1);
            console.log(pastmonth);

            formik.setFieldValue('start_date', pastmonth);
        }
    }, [time_period])

    useEffect(() => {
        if (AstrologersPayoutReportData?.data?.data?.reportLink) {
            const reportLink = AstrologersPayoutReportData?.data?.data?.reportLink;
            const a = document.createElement('a');
            a.href = reportLink;
            a.download = reportLink.split('/').pop();
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }, [AstrologersPayoutReportData])

    const handleExport = () => {
        let isAll = astrologerOption?.length === astrologerSelected?.length ? true : false;
        let ids = [];
        if (!isAll) {
            astrologerSelected?.map((item) => {
                ids.push(item?.value);
            })
        }

        let Start_Date = moment(formik?.values?.start_date).format('YYYY-MM-DD');
        let End_Date = moment(formik?.values?.end_date).format('YYYY-MM-DD');

        if (time_period?.value === 'Current-month' || time_period?.value === 'Previous-month') {
            let month = formik?.values?.start_date.getMonth() + 1;
            let year = formik?.values?.start_date.getFullYear();
            let firstDay = new Date(year, month - 1, 1);
            let lastDay = new Date(year, month, 0);

            Start_Date = moment(firstDay).format('YYYY-MM-DD');
            End_Date = moment(lastDay).format('YYYY-MM-DD');
        }

        let astrologer_field = [];
        let bank_field = [];

        selected?.map((data) => {
            if (data?.key === 'astrologer') {
                if (data?.value !== '' && data?.value !== 'SNo') {
                    astrologer_field.push(data?.value)
                }
            }
            else if (data?.key === 'bank') {
                bank_field.push(data?.value)
            }
        })
        const payoutReportDetails = {
            startDate: Start_Date,
            endDate: End_Date !== 'Invalid date' ? End_Date : Start_Date,
            astrologerIds: ids,
            isAllAstrologers: isAll,
            astrologerFields: astrologer_field,
            bankFields: bank_field,
            isExportNeeded: 1,
            fileName: fileName
        }
        dispatch(GetAstrologersPayoutReport({ token, payoutReportDetails }));
    }

    const handleViewDownloads = () => {
        setViewDownloads(true);
        dispatch(GetAllDownloadReports({ token, isPayoutReport: true }));
    }

    console.log(ReportDownloads);

    return (
        <>
            <MainPagetitle pageTitle="Astrologer Payout Report" parentTitle="Home" />
            <Row className='d-flex justify-content-center margin-3' >
                <Col lg={12} className='add-offer-card'>
                    <div className="filter cm-content-box box-primary">
                        <div className="content-title">
                            <div className="cpa">
                                Details
                            </div>

                        </div>
                        <div className="cm-content-body form excerpt">
                            <div className="card-body">
                                <Row className='report-filter'>
                                    <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">Astrologer ID <span className="text-red">*</span></label>
                                        <MultiSelect
                                            options={astrologerOption}
                                            value={astrologerSelected}
                                            onChange={setAstrologerSelected}
                                            labelledBy="Select Options"
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.astrologers_id}</p>
                                    </Col>
                                    <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">Time Period<span className="text-red">*</span></label>
                                        <Select
                                            options={timePeriod}
                                            className="custom-react-select"
                                            value={time_period}
                                            onChange={(value) => { setTime_period(value) }}
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.time_period}</p>
                                    </Col>
                                    {
                                        time_period?.value === "Today" || time_period?.value === "Yesterday" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date</label>
                                                <DatePicker
                                                    className="report-date-picker"
                                                    selected={formik?.values?.start_date}
                                                    showIcon
                                                    disabled={true}
                                                />
                                            </Col>
                                            : null
                                    }
                                    {
                                        time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date Range</label>
                                                <DatePicker
                                                    className="report-date-picker"
                                                    selectsRange={true}
                                                    startDate={formik?.values?.start_date}
                                                    endDate={formik?.values?.end_date}
                                                    showIcon
                                                    disabled={true}
                                                />
                                            </Col>
                                            : null
                                    }
                                    {
                                        time_period?.value === "Current-month" || time_period?.value === "Previous-month" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Month</label>
                                                <DatePicker
                                                    className="report-date-picker"
                                                    selected={formik?.values?.start_date}
                                                    dateFormat="MM/yyyy"
                                                    showIcon
                                                    disabled={true}
                                                />
                                            </Col>
                                            : null
                                    }
                                    {
                                        time_period?.value === "Custom" ?
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date Range </label>
                                                <DatePicker
                                                    placeholderText='select date range'
                                                    className="report-date-picker"
                                                    selectsRange={true}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onChange={(update) => {
                                                        setDateRange(update);
                                                    }}
                                                    isClearable={true}
                                                    monthsShown={2}
                                                    showIcon
                                                    maxDate={new Date()}
                                                />
                                                <p className={"error__feedback pt-2 mb-0"}>{formik.errors.end_date || formik.errors.start_date}</p>
                                            </Col>
                                            : null
                                    }
                                    {
                                        timePeriod.some(period => period.value === time_period?.value) ?
                                            null
                                            :
                                            <Col xl={4} sm={6} className="mb-4" >
                                                <label className="from-label">Date</label>
                                                <DatePicker
                                                    placeholderText='selected date'
                                                    className="report-date-picker"
                                                    selected={formik?.values?.start_date}
                                                    showIcon
                                                    disabled={true}
                                                />
                                                <p className={"error__feedback pt-2 mb-0"}>{formik.errors.start_date}</p>
                                            </Col>
                                    }

                                    <Col xl={4} sm={6} className="mb-4" >
                                        <label className="from-label">Field <span className="text-red">*</span></label>
                                        <MultiSelect
                                            options={field}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy="Select Options"
                                        />
                                        <p className={"error__feedback pt-2 mb-0"}>{formik.errors.field}</p>
                                    </Col>

                                    <Col xl={8} sm={12} className='d-flex align-items-center justify-content-end gap-3'>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="mt-3 mt-sm-0"
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}>
                                            {
                                                Loading ?
                                                    <div className='loader'></div>
                                                    : "Submit"
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>

                {
                    viewDownloads ?
                        <>
                            <ViewDownloadReports hideDownloads={hideDownloads} />
                        </>
                        :
                        <>
                            <Col lg={12} sm={12} className='blance-card mt-5 py-3'>
                                <Col lg={12} className='mb-3 view-download'>
                                    <div onClick={handleViewDownloads}>
                                        <a className='text-primary'>
                                            View Downloads
                                        </a>
                                    </div>
                                </Col>
                                {Loading ?
                                    null
                                    :
                                    <>
                                        {
                                            AstrologersPayoutReportData?.data?.data?.astrologers?.length > 0 ?
                                                <>
                                                    <Col lg={12} className='d-flex'>
                                                        <Col lg={9}>
                                                            <Col lg={12} className='d-flex justify-content-center'>
                                                                <Col lg={8}>
                                                                    <p className='avail-balance'><span>Astrologers Available Amount :</span> ₹ {AstrologersPayoutReportData?.data?.data?.overAllData?.totalAstrologerShareCost ? AstrologersPayoutReportData?.data?.data?.overAllData?.totalAstrologerShareCost : '0.00'}</p>
                                                                </Col>
                                                            </Col>
                                                            <Col lg={12} className='d-flex flex-wrap justify-content-center gap-3 mt-3'>
                                                                <Col lg={3} sm={5}>
                                                                    <p><span>TDS : </span> ₹ {AstrologersPayoutReportData?.data?.data?.overAllData?.tdsCharge ? AstrologersPayoutReportData?.data?.data?.overAllData?.tdsCharge : '0.00'}</p></Col>
                                                                <Col lg={3} sm={5}>
                                                                    <p><span>GST : </span> ₹ {'0.00'}</p></Col>
                                                                <Col lg={5} sm={5}>
                                                                    <p >Payable Amount : <span className='fw-bold text-black'> ₹ {AstrologersPayoutReportData?.data?.data?.overAllData?.totalAstrologerPayableAmount ? AstrologersPayoutReportData?.data?.data?.overAllData?.totalAstrologerPayableAmount : '0.00'}</span></p>
                                                                </Col>
                                                            </Col>
                                                        </Col>
                                                        <Col lg={3} className='d-flex flex-column justify-content-center align-items-end gap-2'>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    placeholder='Enter your file name(opt)'
                                                                    className='file-name'
                                                                    value={fileName}
                                                                    onChange={(e) => setFileName(e.target.value)}
                                                                />
                                                            </div>
                                                            <Button className='export-button' onClick={handleExport}>
                                                                {
                                                                    Loading ?
                                                                        <div className='loader'></div> :
                                                                        <>
                                                                            <FaDownload className='export-icon' /> Export
                                                                        </>
                                                                }
                                                            </Button>
                                                        </Col>
                                                    </Col>


                                                </>
                                                : null
                                        }
                                    </>
                                }
                            </Col>
                            {Loading ?
                                <>
                                    <div class="wrapper mt-5">
                                        <div class="page-loader">
                                            <div class="loading one"></div>
                                            <div class="loading two"></div>
                                            <div class="loading three"></div>
                                            <div class="loading four"></div>
                                        </div>
                                    </div>
                                </>
                                :
                                <Col lg={12} className={`transaction-table report-table ${tableKeys?.length > 10 ? "extent-table-max" : tableKeys?.length > 7 ? "extent-table-center" : tableKeys?.length > 3 ? "extent-table-min" : null} `}>
                                    {AstrologersPayoutReportData?.data?.data?.astrologers?.length > 0 ?
                                        <>
                                            <table>
                                                <tr className='transaction-heading'>
                                                    <th>S No</th>
                                                    {
                                                        tableKeys.includes("name") ?
                                                            <th>Name</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("amc_id") ?
                                                            <th>AMC ID</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("phone") ?
                                                            <th>Phone No</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("email") ?
                                                            <th>Email</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("total_amount") ?
                                                            <th>Total Amount</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("tds_charge") ?
                                                            <th>TDS</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("gst") ?
                                                            <th>GST</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("total_astrologer_payable") ?
                                                            <th>Payable Amount</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("bank_name") ?
                                                            <th>Bank Name</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("accountholder_name") ?
                                                            <th>Account Holder Name</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("accountnumber") ?
                                                            <th>Account Number</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("IFSCcode") ?
                                                            <th>IFSC Code</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("branch") ?
                                                            <th>Branch</th>
                                                            : null
                                                    }
                                                    {
                                                        tableKeys.includes("pancard") ?
                                                            <th>Pancard</th>
                                                            : null
                                                    }
                                                </tr>
                                                {AstrologersPayoutReportData?.data?.data?.astrologers?.map((data, i) => {
                                                    return (
                                                        <>
                                                            <tr className='transaction-data'>
                                                                <td>{i + 1}</td>
                                                                {
                                                                    tableKeys.includes("name") ?
                                                                        <td>
                                                                            {data.name ?
                                                                                data.name
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("amc_id") ?
                                                                        <td>
                                                                            {data.amc_id ?
                                                                                data.amc_id
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("phone") ?
                                                                        <td>
                                                                            {data.phone ?
                                                                                data.phone
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("email") ?
                                                                        <td>
                                                                            {data.email ?
                                                                                data.email
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("total_amount") ?
                                                                        <td>₹
                                                                            {data.total_amount ?
                                                                                data.total_amount
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("tds_charge") ?
                                                                        <td>₹
                                                                            {data.tds_charge ?
                                                                                data.tds_charge
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("gst") ?
                                                                        <td>₹
                                                                            {data.gst ?
                                                                                data.gst
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("total_astrologer_payable") ?
                                                                        <td>₹
                                                                            {data.total_astrologer_payable ?
                                                                                data.total_astrologer_payable
                                                                                : "0"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("bank_name") ?
                                                                        <td>
                                                                            {data.bank_name ?
                                                                                data.bank_name
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("accountholder_name") ?
                                                                        <td>
                                                                            {data.accountholder_name ?
                                                                                data.accountholder_name
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("accountnumber") ?
                                                                        <td>
                                                                            <td>
                                                                                {data.accountnumber ?
                                                                                    data.accountnumber
                                                                                    : "none"
                                                                                }
                                                                            </td>
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("IFSCcode") ?
                                                                        <td>
                                                                            {data.IFSCcode ?
                                                                                data.IFSCcode
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("branch") ?
                                                                        <td>
                                                                            {data.branch ?
                                                                                data.branch
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                                {
                                                                    tableKeys.includes("pancard") ?
                                                                        <td>
                                                                            {data.pancard ?
                                                                                data.pancard
                                                                                : "none"
                                                                            }
                                                                        </td>
                                                                        : null
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </table>
                                        </>
                                        :
                                        <>
                                            <div className='no-data'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                    }
                                </Col>
                            }
                        </>

                }

            </Row >
        </>
    );
}

export default AstrologerPayoutReport;