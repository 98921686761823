import React, { useContext, useEffect } from "react";

/// React router dom
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";


//Apps

import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import AddEmployee from "./components/Employees/AddEmployee";
import EmployeeList from "./components/Employees/EmployeeList";

import AstrologersList from "./components/Astrologers/AstrologersList";
import AstrologerProfile from "./components/Astrologers/AstrologerProfile";
import AstrologerOrderHistoryList from "./components/Astrologers/OrderHistory";

import NewAstrologersList from "./components/NewAstrologers/NewAstrologersList";
import UsersList from "./components/Users/UsersList";
import UnregisterUsersList from "./components/Users/UnregisterUsersList";

import ChangesApprovalList from "./components/ChangesApproval/ChangesApprovalList";
import ChangesAcceptedList from './components/ChangesApproval/ChangesAcceptedList';
import ChangesRejectedList from './components/ChangesApproval/ChangesRejectedList';

import UserWallet from "./components/Wallet/UserWallet";
import AstrologerWallet from './components/Wallet/AstrologerWallet'
import WalletHistory from "./components/Wallet/WalletHistory";
import PaymentHistory from "./components/Wallet/PaymentHistory";

import OfferList from "./components/SpecialOffer/OfferList";
import AddOffer from "./components/SpecialOffer/AddOffer";
import VieworEditOffer from "./components/SpecialOffer/VieworEditOffer";

import RatingsReviewsList from "./components/RatingsReviews/RatingsReviewsList";

import BlogList from "./components/Cms/BlogList";
import AddBlog from "./components/Cms/AddBlog";
import ViewOrEditBlog from "./components/Cms/ViewOrEditBlog";

import SupportChat from "./components/SupportChat/SupportChat";

import OrderHistoryList from './components/OrderHistory/OrderHistoryList';

import Earnings from "./components/Earnings/Earnings";

import Notifications from "./components/Notifications/Notifications";
import SendedNotifications from "./components/Notifications/SendedNotifications";
import CreateNotification from "./components/Notifications/CreateNotification";
import BrowserNotification from "./components/Notifications/BrowserNotification";

import AstrologerPayoutReport from "./components/Reports/AstrologersPayoutReport";
import AmcIncomeReport from "./components/Reports/AmcIncomeReport";
import AmcSalesRefundReport from "./components/Reports/AmcSalesRefundReport";

import AddCourse from "./components/Course/AddCourse";
import CourseList from "./components/Course/CourseList";
import ViewOrEditCourse from "./components/Course/ViewOrEditCourse";
import CourseRegisterUsers from "./components/Course/CourseRegisterUsers";

import { ThemeContext } from "../context/ThemeContext";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAdminProfile } from '../Redux/Slice';

export const ProtectedRoute = ({ access, role, ...rest }) => {
  const userHasPermission = access.includes(role);
  return userHasPermission ? <Outlet /> : <Navigate to='/access-denied' />;
};

const Markup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { AdminProfile } = useSelector((state) => state.Astromind)

  const token = localStorage.getItem('admin-auth-token')
  useEffect(() => {
    dispatch(GetAdminProfile(token))
  }, [dispatch, token]);

  const allroutes = [
    // Dashboard
    { url: "", component: Home },
    { url: "/dashboard/", component: Home },
    { url: "/profile/", component: AppProfile },

    // Employees
    { url: "/employees/", access: ["Admin"], component: EmployeeList },
    { url: "/add-employee/", access: ["Admin"], component: AddEmployee },

    // Astrologers
    { url: "/all-astrologers/", access: ["Admin", "Support Team", "Management Team"], component: AstrologersList },
    { url: "/online-astrologers/", access: ["Admin", "Support Team", "Management Team"], component: AstrologersList },
    { url: "/paused-astrologers/", access: ["Admin", "Support Team", "Management Team"], component: AstrologersList },
    { url: "/astrologer/:slug/:id", access: ["Admin", "Support Team", "Management Team"], component: AstrologerProfile },
    { url: "/astrologer/order-history/:slug/:id", access: ["Admin", "Support Team", "Management Team"], component: AstrologerOrderHistoryList },
    { url: "/new-astrologers/", access: ["Admin", "Management Team"], component: NewAstrologersList },

    // Users
    { url: "/users/", access: ["Admin", "Finance Team", "Support Team", "Management Team",  "Marketing Team"], component: UsersList },
    { url: "/unregister-users/", access: ["Admin", "Finance Team", "Support Team", "Management Team",  "Marketing Team"], component: UnregisterUsersList },


    // Order History
    { url: "/order-history/call-logs/", component: OrderHistoryList },
    { url: "/order-history/chat-logs/", component: OrderHistoryList },

     // Earnings History
     { url: "/earnings/", component: Earnings },

    // Changes Approval

    { url: "/changes-approval/", access: ["Admin", "Management Team"], component: ChangesApprovalList },
    { url: "/changes-accepted/", access: ["Admin", "Management Team"], component: ChangesAcceptedList },
    { url: "/changes-rejected/", access: ["Admin", "Management Team"], component: ChangesRejectedList },

    // Wallet
    { url: "/user-wallet/", access: ["Admin", "Finance Team", "Support Team", "Management Team"], component: UserWallet },
    { url: "/astrologer-wallet/", access: ["Admin", "Finance Team", "Support Team", "Management Team"], component: AstrologerWallet },
    { url: "/user-wallet-transactions/:id", access: ["Admin", "Finance Team", "Support Team", "Management Team"], component: WalletHistory },
    { url: "/user-payment-transactions/:id", access: ["Admin", "Finance Team", "Support Team", "Management Team"], component: PaymentHistory },
    { url: "/astrologer-wallet-transactions/:id", access: ["Admin", "Finance Team", "Support Team", "Management Team"], component: WalletHistory },

    // Special Offer
    { url: "/special-offer/", access: ["Admin", "Marketing Team", "Management Team"], component: OfferList },
    { url: "/add-offer/", access: ["Admin", "Management Team"], component: AddOffer },
    { url: "/view-offer/:id", access: ["Admin", "Marketing Team", "Management Team"], component: VieworEditOffer },
    { url: "/edit-offer/:id", access: ["Admin", "Management Team"], component: VieworEditOffer },

    // Ratings & Review
    { url: "/ratings-reviews/", access: ["Admin", "Support Team", "Management Team"], component: RatingsReviewsList },

    // Cms
    { url: "/blog-list/", access: ["Admin", "Marketing Team"], component: BlogList },
    { url: "/add-blog/", access: ["Admin", "Marketing Team"], component: AddBlog },
    { url: "/view-blog/:id", access: ["Admin", "Marketing Team"], component: ViewOrEditBlog },
    { url: "/edit-blog/:id", access: ["Admin", "Marketing Team"], component: ViewOrEditBlog  },

    // Customer Support
    { url: "/customer-support/", component: SupportChat },

    // Notification
    { url: "/notifications/", component: Notifications },
    { url: "/sent-notifications/", access: ["Admin", "Management Team"], component: SendedNotifications },
    { url: "/create-notification/", access: ["Admin", "Management Team"], component: CreateNotification },
    // { url: "/chrome-notification", component: ChromeNotification },

    // Reports
    { url: "/astrologer-payout-report/", access: ["Admin", "Finance Team", "Management Team"], component: AstrologerPayoutReport },
    { url: "/amc-income-report/", access: ["Admin", "Finance Team", "Management Team"], component: AmcIncomeReport },
    { url: "/amc-sales-refund-report/", access: ["Admin", "Finance Team", "Management Team"], component: AmcSalesRefundReport },

    // Courses
    // { url: "/add-course/", access: ["Admin", "Management Team"], component: AddCourse },
    // { url: "/course-list/", access: ["Admin", "Management Team"], component: CourseList },
    // { url: "/view-course/:id", access: ["Admin", "Management Team"], component: ViewOrEditCourse },
    // { url: "/edit-course/:id", access: ["Admin", "Management Team"], component: ViewOrEditCourse  },
    // { url: "/course-register-users/:id", access: ["Admin", "Management Team"], component: CourseRegisterUsers },

  ];


  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]

    // if (url.indexOf(path) <= 0) {
    //   return <Error404 />
    // }
    if (url.indexOf(path) <= 0) {
        if(path === 'login' && token){
           return navigate('/dashboard');
        }
        else{
          return <Error404 />
        }
    }
  }


  return (
    <>
      <Routes>
        <Route path='/page-lock-screen' element={<LockScreen />} />
        <Route path='/page-error-400' element={<Error400 />} />
        <Route path='/access-denied' element={<Error403 />} />
        <Route path='/page-error-404' element={<Error404 />} />
        <Route path='/page-error-500' element={<Error500 />} />
        <Route path='/page-error-503' element={<Error503 />} />


        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            data.access && AdminProfile ? (
              <Route
                key={i}
                path={data.url}
                element={<ProtectedRoute access={data.access} role={AdminProfile?.data?.data?.role?.rolename}/>}
              >
                <Route path={data.url} element={<data.component />} />
              </Route>
            ) : (
              <Route
                key={i}
                path={data.url}
                element={<data.component />}
              />
            )
            // <Route
            //   key={i}
            //   exact
            //   path={`${data.url}`}
            //   element={data.component}
            // />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>

      <ScrollToTop />
      <BrowserNotification />
    </>
  );
};


function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  const { AdminProfile } = useSelector((state) => state.Astromind)

  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav profile={AdminProfile} />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};

export default Markup;
