import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import StatusSuccess from '../../../images/dashboard/status-success.png';
import StatusCancel from '../../../images/dashboard/status-cancel.png';

import { BsChatDots } from "react-icons/bs";
import { PiPhoneCallFill } from "react-icons/pi";
import { MdVerified } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";

import MainPagetitle from '../../layouts/MainPagetitle';
import OrderHistoryPreview from './OrderHistoryPreview';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetOrderHistory } from '../../../Redux/Slice';

import ReactPaginate from 'react-paginate';


const OrderHistoryList = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("admin-auth-token");
    const { Loading, OrderHistory } = useSelector((state) => state.Astromind);
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;
    const pageLimit = window.innerWidth < 600 ? 15 : 25;

    const [show, setShow] = useState(false);
    const [type, setType] = useState();
    const [previewData, setPreviewData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState(null);
    const [status, setStatus] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        let page = currentPage ? currentPage : 1;;
        if (pathname == "/order-history/call-logs/") {
            setType("call");
            dispatch(GetOrderHistory({ token, page, pageLimit, type: "call" }))
        }

        else if (pathname == "/order-history/chat-logs/") {
            setType("chat");
            dispatch(GetOrderHistory({ token, page, pageLimit, type: "chat" }))

        }
    }, [pathname, type]);

    useEffect(() => {
        if (startDate && endDate) {
            let start_date = moment(startDate).format('YYYY-MM-DD');
            let end_date = moment(endDate).format('YYYY-MM-DD');
            dispatch(GetOrderHistory({ token, page: 1, pageLimit, type, start_date, end_date, status }))
        }
        else if (status) {
            dispatch(GetOrderHistory({ token, page: 1, pageLimit, type, status }));
        }
        else {
            dispatch(GetOrderHistory({ token, page: 1, pageLimit, type }))
        }
    }, [startDate, endDate, status]);

    useEffect(() => {
        if (searchValue) {
            dispatch(GetOrderHistory({ token, page: 1, type, pageLimit, search: searchValue }));
        }
        else if (searchValue == '') {
            dispatch(GetOrderHistory({ token, page: 1, pageLimit, type }));
        }
    }, [searchValue])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (OrderHistory?.data?.data?.pagination?.currentpage) {
            setCurrentPage(OrderHistory?.data?.data?.pagination?.currentpage);
        }
    }, [OrderHistory]);


    // useEffect(() => {
    //     let page = currentPage ? currentPage : 1 ;
    //     dispatch(GetOrderHistory({ token, page, type }));
    // }, [dispatch, token, currentPage, type]);

    const PreviewCall = (data) => {
        setPreviewData(data);
        handleShow();
    }

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        if (startDate || endDate) {
            let start_date = moment(startDate).format('YYYY-MM-DD');
            let end_date = moment(endDate).format('YYYY-MM-DD');
            dispatch(GetOrderHistory({ token, page, pageLimit, type, start_date, end_date, status }))
        }
        else if (searchValue) {
            dispatch(GetOrderHistory({ token, page, pageLimit, type, search: searchValue }));
        }
        else if (status) {
            dispatch(GetOrderHistory({ token, page, pageLimit, type, status }));
        }
        else {
            dispatch(GetOrderHistory({ token, page, pageLimit, type }));
        }
    }

    return (
        <>
            <MainPagetitle pageTitle={type == "call" ? "Call Logs" : "Chat Logs"} parentTitle="Order History" />
            <Container fluid>
                <Row className='d-flex'>
                    <Col lg={12} className='call-list-main'>
                        <Col lg={12} sm={12} className='d-flex flex-wrap py-5'>
                            <Col lg={4} sm={5} className=''>
                                <h2 className='fw-600'>{status == "completed" ? "Successed " : status == "failed" ? "Canceled " : "All "}{type == "call" ? "Call Logs" : "Chat Logs"}</h2>
                            </Col>
                            <Col lg={8} sm={8} className="d-flex flex-nowrap justify-content-end gap-3 filter-nav">

                                <div className='date-filter'>
                                    <DatePicker
                                        placeholderText='select date range'
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}

                                        showIcon
                                        maxDate={new Date()}
                                    />
                                </div>
                                <div className="d-flex text-white search-option">
                                    <input
                                        type="text"
                                        value={searchValue}
                                        onChange={(e) => { setSearchValue(e.target.value) }}
                                        placeholder='Search AMC ID, Order ID, Name'
                                    />
                                    {
                                        searchValue ?
                                            <div
                                                className="d-flex align-items-center p-2 cancel-btn"
                                                onClick={() => { setSearchValue('') }}
                                            >
                                                <IoClose />
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center p-2 search-btn">
                                                <FaSearch />
                                            </div>
                                    }
                                </div>


                                {/* <button
                                    className={`${(filter || showFilter_or_sort?.type == "filter") && "active"} filter-btn`}
                                    onClick={() => { setShowFilter_or_sort({ status: true, type: 'filter' }) }}
                                >
                                    <FaFilter className='icon' />
                                    <p>Filter</p>
                                </button>
                                <button
                                    className={`${(sortBy || showFilter_or_sort?.type == "sort") && "active"} filter-btn`}
                                    onClick={() => { setShowFilter_or_sort({ status: true, type: 'sort' }) }}
                                >
                                    <MdSort className='icon' />
                                    <p>Sort by</p>
                                </button> */}
                            </Col>
                        </Col>

                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {OrderHistory?.data?.data?.orders?.length > 0 ?
                                    <>
                                        <Col lg={12} className='active-cards order-history-cards mb-4'>
                                            <Col lg={4} className='active-card bg-Green' onClick={() => { setStatus(null) }}>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='active-icon'>
                                                        {
                                                            type === "call" ?
                                                                <PiPhoneCallFill className='icon' />
                                                                :
                                                                <BsChatDots className='icon' />
                                                        }
                                                    </div>
                                                    <div className='active-count'>
                                                        <p>{OrderHistory?.data?.data?.counts?.ordersCount ? OrderHistory?.data?.data?.counts?.ordersCount : 0}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} className='active-card bg-yellow' onClick={() => { setStatus("completed") }}>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='active-icon'>
                                                        <MdVerified className='icon' />
                                                    </div>
                                                    <div className='active-count'>
                                                        <p>{OrderHistory?.data?.data?.counts?.completedCount ? OrderHistory?.data?.data?.counts?.completedCount : 0}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} className='active-card bg-darkBlue' onClick={() => { setStatus("failed") }}>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='active-icon'>
                                                        <ImCancelCircle className='icon' />
                                                    </div>
                                                    <div className='active-count'>
                                                        <p>{OrderHistory?.data?.data?.counts?.failedCount ? OrderHistory?.data?.data?.counts?.failedCount : 0}</p>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Col>
                                        <Col lg={12} className='call-list mb-3'>
                                            <table className={show ? "table-60" : "table-100"} >
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Date & Time</th>
                                                        <th>Order ID</th>
                                                        <th>Astrologer AMC Id</th>
                                                        <th>User AMC Id</th>
                                                        <th>Type</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {OrderHistory?.data?.data?.orders?.map((data, index) => {
                                                        console.log(data);
                                                        
                                                        let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            OrderHistory?.data?.data?.pagination?.currentpage === 1 ?
                                                                                <>
                                                                                    {index + 1}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {((OrderHistory?.data?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td>{indianTime}</td>
                                                                    <td>{data?.id}</td>
                                                                    <td>{data?.astrologer?.amc_id}</td>
                                                                    <td>{data?.user?.amc_id}</td>
                                                                    <td className={ data?.payment_type == 'paid' ?  "text-red" : "text-blue"}>{data?.payment_type}</td>
                                                                    {data?.type === "call" ?
                                                                        <td>{data?.calls[0]?.Status == 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td>
                                                                        : data?.type === "chat" ?
                                                                            <td>{data?.chatsession?.status == 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td>
                                                                            : null
                                                                    }
                                                                    <td className='view' onClick={() => PreviewCall(data)}>view details</td>

                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <div className='no-data'>
                                            <p>No sufficient data available!</p>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        <OrderHistoryPreview show={show} handleClose={handleClose} data={previewData} />
                    </Col>
                    {OrderHistory?.data?.data?.pagination?.totalPage > 1 ?
                        // <Col lg={12} className='d-flex justify-content-center mt-3 pagination'>
                        //     <Pagination className="px-4">
                        //         <Pagination.Prev onClick={() => previousPage()} />

                        //         {[...new Array(OrderHistory?.data?.data?.pagination?.totalPage)].map((arr, index) => {
                        //             return (
                        //                 <Pagination.Item
                        //                     onClick={() => changePage(index + 1)}
                        //                     key={index + 1}
                        //                     active={index + 1 === Number(currentPage)}
                        //                 >
                        //                     {index + 1}
                        //                 </Pagination.Item>
                        //             )
                        //         })}
                        //         <Pagination.Next onClick={() => nextPage()} />
                        //     </Pagination>
                        // </Col>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={OrderHistory?.data?.data?.pagination?.totalPage}
                            forcePage={OrderHistory?.data?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                        : null
                    }
                </Row>
            </Container>
        </>
    )
}

export default OrderHistoryList;