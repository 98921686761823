import React from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ orderData }) => {
    let totalOrder = Number(orderData?.totelCallList) + Number(orderData?.totelChatList) + Number(orderData?.totelCancelList);
    let callPercentage = Math.ceil((Number(orderData?.totelCallList) / totalOrder) * 100);
    let chatPercentage = Math.ceil((Number(orderData?.totelChatList) / totalOrder) * 100);
    let cancelPercentage = Math.ceil((Number(orderData?.totelCancelList) / totalOrder) * 100);

    const data = {
        datasets: [
            {
                data: [callPercentage, chatPercentage, cancelPercentage],
                borderWidth: 0,
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                ],
                hoverBackgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                ],
            },
        ],
        labels: ["Call","Chat","Cancelled" ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
              },
            responsive: true,
        },

        maintainAspectRatio: false,
    };

    return (
        <div className='statistic-chart'>
            <h3>Astrologer Statistic</h3>
            <Pie data={data} options={options} />
        </div>
    );
};

export default PieChart;