import React, { useEffect } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SendNotification, successMessage, errorMessage } from '../../../Redux/Slice';

const userType = [
    { value: 'New Users', label: 'New Users' },
    { value: 'Loyal Users', label: 'Loyal Users' },
    { value: 'All Users', label: 'All Users' },
]
const userName = [
    { value: 'Customer', label: 'Customer' },
    { value: 'Astrologer', label: 'Astrologer' },
    { value: 'All', label: 'All' },
]
function CreateNotification() {
    const dispatch = useDispatch();
    const { Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            user_name: '',
            user_type: '',
            redirect_url: ''
        },
        onSubmit: async (values) => {
            let notificationContent = {
                title: values?.title,
                description: values?.description,
                redirectUrl: values?.redirect_url,
                isToUser: values?.user_name?.value === 'Customer' ? 1 : values?.user_name?.value === 'All' ? 1 : 0,
                isToAstrologer: values?.user_name?.value === 'Astrologer' ? 1 : values?.user_name?.value === 'All' ? 1 : 0,
                isToNew: values?.user_type?.value === 'New Users' ? 1 : 0,
                isToOld: values?.user_type?.value === 'Loyal Users' ? 1 : 0,
            }
            console.log(notificationContent);
            dispatch(SendNotification({ token, notificationContent }));
        },
        validate: (values) => {
            const errors = {};

            if (!values.title) {
                errors.title = "Requried*";
            }
            if (!values.description) {
                errors.description = "Required*";
            }
            if (!values.user_name) {
                errors.user_name = "Required*";
            }
            if (!values.user_type) {
                errors.user_type = "Required*";
            }
            return errors;
        }

    })

    useEffect(() => {
        if (success) {
            if (success?.data?.data?.isSent) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    formik.resetForm();
                })
            }
        }
        else if (errors) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, success, errors])

    return (
        <>
            <MainPagetitle pageTitle="Create Notofication" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex flex-wrap gap-4 justify-content-center create-notification'>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Create Notification
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Title <span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='title'
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Description <span className="text-red">*</span></label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="description"
                                                        name='description'
                                                        value={formik.values.description}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.description}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">User Name <span className="text-red">*</span></label>
                                                    <Select
                                                        options={userName}
                                                        className="custom-react-select"
                                                        value={formik.values.user_name}
                                                        onChange={(value) => { formik.setFieldValue('user_name', value) }}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.user_name}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">User Type <span className="text-red">*</span></label>
                                                    <Select
                                                        options={userType}
                                                        className="custom-react-select"
                                                        value={formik.values.user_type}
                                                        onChange={(value) => { formik.setFieldValue('user_type', value) }}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.user_type}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Redirect Url </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Url"
                                                        name='redirect_url'
                                                        value={formik.values.redirect_url}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </form>
                                        </Col>
                                        <div className="card-footer border-0 text-center py-3 ">
                                            <Button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    formik.handleSubmit()
                                                }}>
                                                Send
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default CreateNotification;