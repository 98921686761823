import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CheckWalletPin, UpdateAstrologerOrUserWallet, successMessage, errorMessage } from '../../../Redux/Slice';

function EditWallet({ show, close, data, role }) {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');

    const { success, errors } = useSelector((state) => state.Astromind);

    const [pinValues, setPinValues] = useState("");
    const [errorMessages, setErrorMessages] = useState("");
    const [isEditShow, setIsEditShow] = useState(false);
    const [newWalletAmount, setNewWalletAmount] = useState();
    const inputRefs = useRef([]);

    const focusNextInput = (index) => {
        if (index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };
    const focusBackInput = (index) => {
        if (index < inputRefs.current.length && index !== 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleInputChange = (index, e) => {
        const newOtpValues = [...pinValues];
        newOtpValues[index] = e.target.value;
        setPinValues(newOtpValues);
        if (e.target.value.trim() !== '') {
            focusNextInput(index);
        }
        else if (e.target.value.trim() === '') {
            focusBackInput(index);
        }
    };

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    useEffect(() => {
        if (pinValues?.length === 4) {
            const PinArray = pinValues.map(str => +str);
            const Pin = PinArray.join('');
            dispatch(CheckWalletPin({ token, Pin }))
        }
        else {
            setErrorMessages("");
        }
    }, [dispatch, token, pinValues]);

    useEffect(() => {
        if (success) {
            console.log(success);
            if (success?.data?.data?.isValidated) {
                setIsEditShow(true);
                setErrorMessages("");
                dispatch(successMessage(' '))
            }
        }

        else if (errors) {
            if (!(errors?.data?.isValidated)) {
                setErrorMessages("Your pin is wrong. please enter a valid pin");
                dispatch(errorMessage(' '));
            }
        }
    }, [dispatch, success, errors]);

    const handleUpdateWallet = () => {

        if (newWalletAmount < 0) {
            Swal.fire({
                title: "Please Enter a Valid Number",
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then(() => {
            })
        }
        else {
            let updatedAmount = {
                id: data?.id,
                pin: 1111,
                amount: newWalletAmount
            }
            dispatch(UpdateAstrologerOrUserWallet({ token, role, updatedAmount }));
        }
    }

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => close(false)}
                className='edit-wallet-modal'
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body >
                    {
                        isEditShow ?
                            <>
                                <div className='edit-wallet-screen'>
                                    <div>
                                        <h3>Edit Amount Here</h3>
                                        <p><span>AMC ID : </span> {data?.amc_id}</p>
                                        <p><span>Name : </span> {data?.name} </p>
                                        <p><span>Current Balance : </span>   ₹ {data?.accountbalance ? data?.accountbalance : data?.mywallet ? data?.mywallet : "none"}</p>
                                        <p><span>Add New Balance to Wallet </span></p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center gap-3'>
                                        <input
                                            type='number'
                                            placeholder='₹'
                                            min='0'
                                            value={newWalletAmount}
                                            onChange={(e) => { setNewWalletAmount(e.target.value) }} />
                                        <button onClick={() => { handleUpdateWallet() }} disabled={!newWalletAmount}>Proceed</button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='pb-3'>
                                    <h3 className='text-white text-center'>Enter your PIN for Confirmation</h3>
                                </div>
                                <div className='pin-input-fields'>
                                    {[1, 2, 3, 4].map((field, index) => (
                                        <input
                                            key={index}
                                            onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                            maxLength="1"
                                            size="1"
                                            className={`otp__digit otp__field__${field}`}
                                            ref={(el) => (inputRefs.current[index] = el)}
                                            onInput={(e) => handleInputChange(index, e)}
                                            value={pinValues[index]}
                                        />
                                    ))}
                                </div>
                                <div className='pin-response pt-3'>
                                    <p>{errorMessages}</p>
                                </div>
                            </>
                    }

                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditWallet;