import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';

import AcceptNewAstrologer from './AcceptNewAstrologer';

import EmptyProfile from '../../../images/dashboard/empty-profile.png'

import { useDispatch } from 'react-redux'
import { ActivateOrRejectAstrologers } from '../../../Redux/Slice'

const NewAstrologerPreview = ({ data, show, handleClose }) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');

    const [newAstro, setNewAstro] = useState(null);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    const handleAcceptClose = () =>{
        setIsAccepted(false);
        setIsRejected(false);
    }

    let description = data?.profileDetail?.description?.data.map(obj => obj.name);
    let language = data?.profileDetail?.language?.data.map(obj => obj.name);

    let profile;
    let idProof;
    let passbook;
    if (data?.files != []) {
        data?.files.map((file) => {
            if (file.filefor === "profile") {
                profile = file;
            }
            if (file.filefor === "idproof") {
                idProof = file;
            }
            if (file.filefor === "passbook") {
                passbook = file;
            }
        })
    }
    else {
        profile = null;
        idProof = null;
        passbook = null;
    }

    const approveAstrologer = (id) => {
        setIsAccepted(true);
        setNewAstro({ id, status: 1 });
    }

    const rejectAstrologer = (id) => {
        console.log(id);
        setIsRejected(true);
        setNewAstro({ id: id, status: 0 });
    }


    return (
        <>
            <AcceptNewAstrologer isAccepted={isAccepted} isRejected={isRejected} handleClose={handleAcceptClose} newAstro={newAstro} />

            <Offcanvas className="list-canvas offcanvas-end" show={show} onHide={handleClose} name='end' backdrop={false} scroll={true} >
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className='preview-card bg-yellow-light'>
                        <div className='d-flex justify-content-between'>
                            <Col lg={8}>
                                <div>
                                    <h4>Astrologer Profile Details </h4>
                                </div>

                                <div className='mb-4'>
                                    <h5>Personal Details</h5>

                                    {data?.profileDetail?.name ?
                                        <>
                                            <p className='preview-data'><span>Name :</span>{data?.profileDetail?.name}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.email ?
                                        <>
                                            <p className='preview-data'><span>Email :</span>{data?.profileDetail?.email}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.phone ?
                                        <>
                                            <p className='preview-data'><span>Phone Number :</span>{data?.profileDetail?.phone}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.dateofbirth ?
                                        <>
                                            <p className='preview-data'><span>D.O.B :</span>{data?.profileDetail?.dateofbirth}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.age ?
                                        <>
                                            <p className='preview-data'><span>Age :</span>{data?.profileDetail?.age}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.gender ?
                                        <>
                                            <p className='preview-data'><span>Gender :</span>{data?.profileDetail?.gender}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.maritalstatus ?
                                        <>
                                            <p className='preview-data'><span>Marital Status :</span>{data?.profileDetail?.maritalstatus}</p>
                                        </>
                                        : null
                                    }
                                </div>






                            </Col>
                            <Col lg={4} >
                                <div className='call-profile-img'>
                                    <img src={profile ? profile?.file : EmptyProfile} alt="" />
                                </div>
                            </Col>
                        </div>
                        <div>
                            <Col lg={12}>
                                <div className='mb-4'>
                                    <h5>Professional Details</h5>

                                    {description ?
                                        <>
                                            <p className='preview-data'><span>Expertise :</span>{description.join(", ")}</p>
                                        </>
                                        : null
                                    }
                                    {language ?
                                        <>
                                            <p className='preview-data'><span>Languages :</span>{language.join(", ")}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.experience ?
                                        <>
                                            <p className='preview-data'><span>Experience in years :</span> {data?.profileDetail?.experience} years</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.profession ?
                                        <>
                                            <p className='preview-data'><span>Profession :</span> {data?.profileDetail?.profession}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.qualification ?
                                        <>
                                            <p className='preview-data'><span>Qualification :</span> {data?.profileDetail?.qualification}</p>
                                        </>
                                        : null
                                    }
                                </div>

                                <div className='mb-4'>
                                    <h5>Bank Details</h5>

                                    {data?.bank?.accountholder_name ?
                                        <>
                                            <p className='preview-data'><span>Holder name :</span>{data?.bank?.accountholder_name}</p>
                                        </>
                                        : null
                                    }
                                    {data?.bank?.accountnumber ?
                                        <>
                                            <p className='preview-data'><span>Account number :</span>{data?.bank?.accountnumber}</p>
                                        </>
                                        : null
                                    }
                                    {data?.bank?.bank_name ?
                                        <>
                                            <p className='preview-data'><span>Bank name :</span> {data?.bank?.bank_name}</p>
                                        </>
                                        : null
                                    }
                                    {data?.bank?.branch ?
                                        <>
                                            <p className='preview-data'><span>Branch Name :</span> {data?.bank?.branch}</p>
                                        </>
                                        : null
                                    }
                                    {data?.bank?.IFSCcode ?
                                        <>
                                            <p className='preview-data'><span>IFSC Code :</span> {data?.bank?.IFSCcode}</p>
                                        </>
                                        : null
                                    }
                                    {data?.bank?.pancard ?
                                        <>
                                            <p className='preview-data'><span>Pan number :</span> {data?.bank?.pancard
                                            }</p>
                                        </>
                                        : null
                                    }
                                </div>

                                <div className='mb-4'>
                                    <h5>Proof</h5>
                                    <div className="d-flex gap-4 mb-4">
                                        <p className='preview-data'>ID Proof </p>
                                        <div >
                                            {idProof ?
                                                <Button type={"primary"} className="preview-download-btn" href={idProof?.file}>
                                                    Download
                                                </Button>
                                                : <p>file not found</p>
                                            }

                                        </div>
                                    </div>
                                    <div className="d-flex gap-4 mb-4">
                                        <p className='preview-data'>Bank passbook</p>
                                        <div>
                                            {passbook ?
                                                <Button type={"primary"} className="preview-download-btn" href={passbook?.file}>
                                                    Download
                                                </Button>
                                                : <p>file not found</p>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>

                        <div className='d-flex justify-content-center gap-4 mt-3' >
                            <button className='green-btn preview-btn' onClick={() => approveAstrologer(data?.profileDetail?.id)}>
                                Approve
                            </button>
                            <button className='yellow-btn preview-btn' onClick={() => rejectAstrologer(data?.profileDetail?.id)}>
                                Reject
                            </button>
                        </div>
                    </Col>

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default NewAstrologerPreview;