// import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";

import { SVGICON } from '../constant/theme';


const MainPagetitle = ({ pageTitle, pageTitleSub, parentTitle, mainTitle }) => {

	const navigate = useNavigate();

	// const [isLastPage, setIsLastPage] = useState(false);

	// useEffect(() => {
	// 	const handlePopState = () => {
	// 		if (window.history.state && window.history.state.idx === 0) {
	// 			setIsLastPage(true);
	// 		  } else {
	// 			setIsLastPage(false);
	// 		  }
	// 	};

	// 	window.addEventListener('popstate', handlePopState);

	// 	return () => {
	// 		window.removeEventListener('popstate', handlePopState);
	// 	};
	// }, [history]);

	// console.log("isLastPage", isLastPage);
	return (
		<>
			<div className="page-titles">
				<ol className="breadcrumb">
					<li><h5 className="bc-title">{mainTitle}</h5></li>
					<li className='breadcrumb-back' onClick={() => navigate(-1)} ><GoArrowLeft className='icon' /></li>
					<li className="breadcrumb-item">
						<Link to={"#"}>
							{SVGICON.HomeSvg}
							{" "}{parentTitle}
						</Link>
					</li>
					<li className="breadcrumb-item active"><Link to={"#"}>{pageTitle}</Link></li>
					<li className="breadcrumb-item active"><Link to={"#"}>{pageTitleSub}</Link></li>
				</ol>

			</div>

		</>
	);
};

export default MainPagetitle;