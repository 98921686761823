import React, { Fragment, useState, useEffect } from "react";
import MainPagetitle from '../../../layouts/MainPagetitle';
import { Container, Row, Col } from 'react-bootstrap';
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAdminProfile, DeleteAdminProfileImg, successMessage, errorMessage } from '../../../../Redux/Slice';

import EditProfile from "./EditProfile";
import ImageUpdate from "./ImageUpdate";
import ChangePassword from "./ChangePassword";
import EmptyProfile from '../../../../images/dashboard/empty-profile.png';

const AppProfile = () => {
	const dispatch = useDispatch();
	const { AdminProfile, success, errors } = useSelector((state) => state.Astromind);
	const token = localStorage.getItem("admin-auth-token");

	const [editShow, setEditShow] = useState(false);
	const [changeProfileShow, setChangeProfileShow] = useState(false);
	const [changePasswordShow, setChangePasswordShow] = useState(false);
	const [fileData, setFileData] = useState(null);

	const editClose = (value) => {
		setEditShow(value);
		setChangePasswordShow(value);
	}
	const ChangeProfileClose = (value) => {
		setChangeProfileShow(value);
	}

	const deleteAccount = () => {
		Swal.fire({
			title: "Are you sure want to Delete your account?",
			text: "After 14 days, the Account can be Deleted Permanently.",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Delete Account"
		});
	}

	const updateProfile = () => {
		Swal.fire({
			title: "Are you sure want to Update your profile?",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Update"
		}).then((result) => {
			if (result?.isConfirmed) {
				setEditShow(true);
			}
		})
	}

	const changePassword = () => {
		Swal.fire({
			title: "Are you sure want to Update your password?",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Update"
		}).then((result) => {
			if (result?.isConfirmed) {
				setChangePasswordShow(true);
			}
		})
	}

	const updateProfileImg = () => {
		setChangeProfileShow(true);
		setFileData({
			fileId: AdminProfile?.data?.data?.profile?.id,
			fileFor: "profile",
			existFile: AdminProfile?.data?.data?.profile
		})
	}

	const deleteProfileImg = () => {
		const fileId = AdminProfile?.data?.data?.profile?.id;

		Swal.fire({
			title: "Are you sure want to Delete your Profile Image?",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Delete"
		})
			.then((result) => {
				if (result.isConfirmed) {
					dispatch(DeleteAdminProfileImg({ token, fileId }))
				}
			})
	}


	useEffect(() => {
		if (success?.status === 200) {
			dispatch(GetAdminProfile(token));
			setChangeProfileShow(false);
			setEditShow(false);
			Swal.fire({
				title: success?.data?.message,
				icon: "success",
				iconColor: "#36AA00",
				confirmButtonColor: "#36AA00",
				confirmButtonText: "Okay",
				timer: 5000
			}).then((result) => {
				dispatch(successMessage(''));
			})
		}

		else if (errors?.status === 400) {
			dispatch(GetAdminProfile(token));
			Swal.fire({
				title: errors?.data?.message,
				icon: "error",
				iconColor: "#CA0505",
				confirmButtonColor: "#CA0505",
				confirmButtonText: "Okay",
				timer: 5000
			}).then((result) => {
				dispatch(errorMessage({ errors: '' }));
			})
		}
	}, [dispatch, token, success, errors]);

	return (
		<Fragment>
			<MainPagetitle pageTitle="Profile" parentTitle="Home" />

			<EditProfile
				editShow={editShow}
				editClose={editClose}
				profile={AdminProfile}
			/>
			<ChangePassword
				editShow={changePasswordShow}
				editClose={editClose}
				profile={AdminProfile}
			/>
			<ImageUpdate
				profileShow={changeProfileShow}
				fileData={fileData}
				profileClose={ChangeProfileClose}
			/>

			<Container fluid >
				<Row >
					<Col lg={12} >
						<div className="profile card card-body px-3 pt-3 pb-0">
							<div className="profile-head">
								<div className="photo-content ">
									<div className="cover-photo rounded">
										<div className="profile-update d-flex gap-4 justify-content-end mx-3 pt-2">
											{/* <div className="profile-icon">
												<MdEdit className="icon" />
											</div>
											<div className="profile-icon">
												<RiDeleteBin6Fill className="icon" />
											</div> */}
										</div>
									</div>
								</div>
								<div className="profile-info">
									<div className="profile-photo">
										<img
											src={AdminProfile?.data?.data?.profile ? AdminProfile?.data?.data?.profile?.file : EmptyProfile} className="img-fluid rounded-circle" alt="profile" />
										<div className="profile-photo-update" >
											<div className="profile-icon" onClick={updateProfileImg}>
												<MdEdit className="icon" />
											</div>
											<div className="profile-icon" onClick={deleteProfileImg}>
												<RiDeleteBin6Fill className="icon" />
											</div>
										</div>
									</div>
									<div className="profile-details">
										<div className="profile-name px-3 pt-2">
											<h4 className="text-primary mb-0">{AdminProfile?.data?.data?.user?.name}</h4>
											<p>Admin</p>
										</div>
									</div>
								</div>
								<Col lg={2} className="followers-card" >
									<div className="changePassword" onClick={() => changePassword()}>
										Change Password
									</div>
								</Col>
							</div>
						</div>
					</Col>
				</Row>
				<Row >

					<Col xl={12}>
						<div className="card">
							<div className="card-body">
								<div className="profile-update d-flex gap-4 justify-content-end">
									<div onClick={updateProfile} >
										<MdEdit className="icon" />
									</div>
									<div onClick={deleteAccount} >
										<RiDeleteBin6Fill className="icon" />
									</div>
								</div>
								<div className="profile-tab">
									<div className="custom-tab-1">
										<div id="about-me" eventKey='About'>
											{/* <div className="profile-about-me">
												<div className="pt-4 border-bottom-1 pb-3">
													<h4 >About</h4>
													<p className="mb-2">
														{AstrologerProfile?.data?.data?.astrologer?.about}
													</p>

												</div>
											</div> */}
											<Col xl={12} className="profile-personal-info">
												<h4 className="mb-3">
													Admin  Information
												</h4>
												<div className="d-flex mb-2">
													<Col lg={3} >
														<h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
													</Col>
													<Col lg={8} >
														<span>{AdminProfile?.data?.data?.user?.name ? AdminProfile?.data?.data?.user?.name : "none"}</span>
													</Col>
												</div>
												<div className="d-flex mb-2">
													<Col lg={3}>
														<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
													</Col>
													<Col lg={8}>
														<span>{AdminProfile?.data?.data?.user?.email ? AdminProfile?.data?.data?.user?.email : "none"}</span>
													</Col>
												</div>
												<div className="d-flex mb-2">
													<Col lg={3} >
														<h5 className="f-w-500"> Role<span className="pull-right">:</span></h5>
													</Col>
													<Col lg={8} >
														<span>{AdminProfile?.data?.data?.user?.role ? AdminProfile?.data?.data?.user?.role : "Admin"}</span>
													</Col>
												</div>
												<div className="d-flex mb-2">
													<Col lg={3} >
														<h5 className="f-w-500"> Phone Number<span className="pull-right">:</span></h5>
													</Col>
													<Col lg={8} >
														<span>{AdminProfile?.data?.data?.user?.phone ? "+" + AdminProfile?.data?.data?.user?.phone : "none"}</span>
													</Col>
												</div>
											</Col>
										</div>

									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AppProfile;
