import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ActivateOrRejectAstrologers, errorMessage} from '../../../Redux/Slice';


const AcceptNewAstrologer = ({ isAccepted, isRejected, handleClose, newAstro }) => {
    const dispatch = useDispatch();
    const { NewAstrologers, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token')
    const [reason, setReason] = useState(null);

    const handleReject = () => {
        let data = {
            astrologerId : newAstro?.id,
            reason : reason
        }
        dispatch(ActivateOrRejectAstrologers({ token,  status : newAstro?.status, data }));

    }
    const formik = useFormik({
        initialValues: {
            astrologerId: '',
            astrologerRate: '',
            astrologerShare: '',
            amcShare: '',
        },
        onSubmit: async (values) => {
            console.log(values);
            console.log(newAstro);
            let data = {
                amc_id : values?.astrologerId,
                rate : values?.astrologerRate,
                astrologer_share_percentage : values?.astrologerShare,
                amc_share_percentage : values?.amcShare,
                astrologerId : newAstro?.id
            }
            dispatch(ActivateOrRejectAstrologers({ token,  status : newAstro?.status, data }));
        },
        validate: (values) => {
            const errors = {};
            const formatRegex = /^AMCA[A-Za-z0-9]{4}$/;

            if (!values.astrologerId) {
                errors.astrologerId = "Requried*";
            }
            else if (!formatRegex.test(values.astrologerId)) {
                errors.astrologerId = 'Invalid format. It should be in the format AMCAXXXX.';
            }
            if (!values.astrologerRate) {
                errors.astrologerRate = "Required*";
            }
            if (!values.astrologerShare) {
                errors.astrologerShare = "Required*";
            }
            if (!values.amcShare) {
                errors.amcShare = "Required*";
            } else if (values.astrologerShare + values.amcShare != 100) {
                errors.amcShare = "please provide efficient share percentage*";
            }
            console.log(errors);
            return errors;
        }

    })


    useEffect(() => {
        if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay"
            }).then((result) => {
                if (result?.isConfirmed) {
                    dispatch(errorMessage({ errors: '' }));
                }
            })
        }
    }, [errors]);

    return (
        <>
            <Modal show={isAccepted || isRejected} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Please fill the details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isAccepted ?
                        <Col className='astrologer-details'>
                            <Col lg={12} className='astrologer-form'>
                                <h5 >Astrologer Id<span className='text-red'>*</span> <span className='note'>(ex : AMCAXXXX)</span></h5>
                                <input type='text' name='astrologerId' id='astrologerId' value={formik.values.astrologerId} onChange={formik.handleChange} />
                                <p className={"error__feedback"}>{formik.errors.astrologerId}</p>
                            </Col>
                            <Col lg={12} className='astrologer-form'>
                                <h5 >Astrologer Rate<span className='text-red'>*</span> <span className='note'>(per minute)</span> </h5>
                                <input type='number' name='astrologerRate' id='astrologerRate' value={formik.values.astrologerRate} onChange={formik.handleChange} />
                                <p className={"error__feedback"}>{formik.errors.astrologerRate}</p>
                            </Col>
                            <Col lg={12} className='d-flex justify-content-between astrologer-form'>
                                <Col lg={5}>
                                    <h5 >Astrologer Share<span className='text-red'>*</span> <span className='note'>(%)</span></h5>
                                    <input type='number' name='astrologerShare' id='astrologerShare' value={formik.values.astrologerShare} onChange={formik.handleChange} />
                                    <p className={"error__feedback"}>{formik.errors.astrologerShare}</p>
                                </Col>
                                <Col lg={5}>
                                    <h5 >AMC Share<span className='text-red'>*</span> <span className='note'>(%)</span></h5>
                                    <input type='number' name='amcShare' id='amcShare' value={formik.values.amcShare} onChange={formik.handleChange} />
                                    <p className={"error__feedback"}>{formik.errors.amcShare}</p>
                                </Col>
                            </Col>
                        </Col>
                        : null
                    }

                    {isRejected ?
                        <Col className='astrologer-details'>
                            <Col lg={12} className='astrologer-form'>
                                <h5 >Reason for Rejection<span className='text-red'>*</span></h5>
                                <textarea type='text' name='reason' id='reason' value={reason} onChange={(e) => { setReason(e.target.value) }} />
                            </Col>

                        </Col>
                        : null
                    }


                </Modal.Body>
                <Modal.Footer>
                    {isAccepted ?
                        <Button variant="primary" onClick={formik.handleSubmit}>
                            Approve
                        </Button>
                        : null}
                    {isRejected ?
                        <Button variant="primary" disabled={!reason} onClick={() => { handleReject() }}>
                            Reject
                        </Button>
                        : null}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AcceptNewAstrologer;