import { Container, Row, Col, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';
import ChangesReject from './ChangesReject';
import EmptyProfile from '../../../images/dashboard/empty-profile.png'
import { GoArrowLeft } from "react-icons/go";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologerEditRequest, AcceptOrRejectAstrologersRequest, UpdateAstrologersEditRequest, successMessage, errorMessage } from '../../../Redux/Slice'

function ChangesApprovalPreview({ back, requestData }) {
    const dispatch = useDispatch();
    const { Loading, AstrologerEditRequest, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [isRejected, setIsRejected] = useState(false);
    const [isAcceptedData, setIsAcceptedData] = useState(false);

    const handleClose = () => setIsRejected(false);

    let description = AstrologerEditRequest?.data?.data?.astrologer?.description?.data?.map(obj => obj.name);
    let language = AstrologerEditRequest?.data?.data?.astrologer?.language?.data?.map(obj => obj.name);

    let changedDescription = AstrologerEditRequest?.data?.data?.request?.description?.data?.map(obj => obj.name);
    let changedLanguage = AstrologerEditRequest?.data?.data?.request?.language?.data?.map(obj => obj.name);

    const [editData, setEditData] = useState();
    const [editShow, setEditShow] = useState(false);

    useEffect(() => {
        if (pathname == "/changes-accepted") {
            setIsAcceptedData(true);
        }
        else {
            setIsAcceptedData(false);

        }
    }, [pathname, isAcceptedData]);

    useEffect(() => {
        dispatch(GetAstrologerEditRequest({ token, id: requestData?.request?.id }))
        setEditData({
            name: requestData?.request?.name,
            email: requestData?.request?.email,
            phone: requestData?.request?.phone,
            dateofbirth: requestData?.request?.dateofbirth,
            age: requestData?.request?.age,
            gender: requestData?.request?.gender,
            maritalstatus: requestData?.request?.maritalstatus,
            experience: requestData?.request?.experience,
            profession: requestData?.request?.profession,
            qualification: requestData?.request?.qualification,
            about: requestData?.request?.about,
        })
    }, [requestData]);

    const acceptRequest = () => {
        let id = AstrologerEditRequest?.data?.data?.request?.id;
        let status = 1;
        let data = {
            reason: null
        };
        dispatch(AcceptOrRejectAstrologersRequest({ token, id, status, data }));
        back();
    }

    const rejectRequest = () => {
        let id = AstrologerEditRequest?.data?.data?.request?.id;
        setIsRejected(true);
        // dispatch(AcceptOrRejectAstrologersRequest({ token, id, status }));
        // back();
    }

    const handleUpdate = () => {
        console.log(editData);
        let id = AstrologerEditRequest?.data?.data?.request?.id;
        // let updateData = {
        //     name : editData
        // }
        dispatch(UpdateAstrologersEditRequest({ token, id, editData }));
        setEditShow(false);
    }

    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetAstrologerEditRequest({ token, id: requestData?.request?.id }))
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK",
                timer: 5000,
            }).then((result) => {
                dispatch(successMessage(''));
            })
        }

        else if (errors?.status === 400) {
            dispatch(GetAstrologerEditRequest({ token, id: requestData?.request?.id }))
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
            })
        }
    }, [dispatch, token, requestData, success, errors]);

    return (
        <>
            {isRejected ?
                <ChangesReject
                    isRejected={isRejected}
                    requestId={AstrologerEditRequest?.data?.data?.request?.id}
                    handleClose={handleClose}
                />
                : null
            }

            <Container fluid>
                <Row className='d-flex'>
                    <Col lg={12} className='list-main'>
                        <MainPagetitle pageTitle="Changes Approval" parentTitle="Home" />
                    </Col>
                    <Col lg={12} className='d-flex justify-content-center mt-5'>
                        <Col lg={4}>
                            <button
                                className='yellow-btn back-btn'
                                onClick={back}
                            >
                                <GoArrowLeft className='icon' />
                            </button>
                        </Col>
                        <Col lg={6}>
                            <h3>Changes Preview</h3>
                        </Col>
                    </Col>

                    <Col lg={12} className='d-flex flex-wrap justify-content-center gap-3 changes-approval mt-5'>
                        <Col lg={5} className='changes-profile'>
                            <div className='bg-yellow-light text-center p-3'>
                                <h3>{isAcceptedData ? "Old Details" : "Current Details"}</h3>
                            </div>
                            <Col lg={12} className='px-4'>
                                <div className='d-flex justify-content-center'>
                                    {AstrologerEditRequest?.data?.data?.request?.filefor === "profile" ?
                                        <>
                                            <div className='d-flex align-items-center preview-profile-img'>
                                                <img src={AstrologerEditRequest?.data?.data?.request?.old_file ? AstrologerEditRequest?.data?.data?.request?.old_file : EmptyProfile} alt="" className='img-fluid' />
                                            </div>
                                        </>

                                        : null
                                    }
                                </div>

                                <div className='mb-4'>
                                    {
                                        AstrologerEditRequest?.data?.data?.request?.name || AstrologerEditRequest?.data?.data?.request?.email || AstrologerEditRequest?.data?.data?.request?.phone || AstrologerEditRequest?.data?.data?.request?.dateofbirth || AstrologerEditRequest?.data?.data?.request?.age || AstrologerEditRequest?.data?.data?.request?.gender || AstrologerEditRequest?.data?.data?.request?.maritalstatus || changedDescription || changedLanguage || AstrologerEditRequest?.data?.data?.request?.experience || AstrologerEditRequest?.data?.data?.request?.profession || AstrologerEditRequest?.data?.data?.request?.qualification ?
                                            <h3>Personal Details</h3> : null
                                    }

                                    {AstrologerEditRequest?.data?.data?.request?.name ?
                                        <>
                                            <p className='preview-data'><span>Name :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.name  : AstrologerEditRequest?.data?.data?.astrologer?.name}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.email ?
                                        <>
                                            <p className='preview-data'><span>Email :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.email : AstrologerEditRequest?.data?.data?.astrologer?.email }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.phone ?
                                        <>
                                            <p className='preview-data'><span>Phone Number :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.phone : AstrologerEditRequest?.data?.data?.astrologer?.phone }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.dateofbirth ?
                                        <>
                                            <p className='preview-data'><span>D.O.B :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.dateofbirth : AstrologerEditRequest?.data?.data?.astrologer?.dateofbirth }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.age ?
                                        <>
                                            <p className='preview-data'><span>Age :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.age : AstrologerEditRequest?.data?.data?.astrologer?.age }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.gender ?
                                        <>
                                            <p className='preview-data'><span>Gender :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.gender : AstrologerEditRequest?.data?.data?.astrologer?.gender }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.maritalstatus ?
                                        <>
                                            <p className='preview-data'><span>Marital Status :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.maritalstatus : AstrologerEditRequest?.data?.data?.astrologer?.maritalstatus }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.experience ?
                                        <>
                                            <p className='preview-data'><span>Experience in years :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.experience : AstrologerEditRequest?.data?.data?.astrologer?.experience } years</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.profession ?
                                        <>
                                            <p className='preview-data'><span>Profession :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.profession : AstrologerEditRequest?.data?.data?.astrologer?.profession }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.qualification ?
                                        <>
                                            <p className='preview-data'><span>Qualification :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.qualification : AstrologerEditRequest?.data?.data?.astrologer?.qualification }</p>
                                        </>
                                        : null
                                    }
                                    {changedDescription ?
                                        <>
                                            <p className='preview-data'><span>Expertise :</span>{description.join(", ")}</p>
                                        </>
                                        : null
                                    }
                                    {changedLanguage ?
                                        <>
                                            <p className='preview-data'><span>Languages :</span>{language.join(", ")}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.about ?
                                        <>
                                            <p className='preview-data'><span>About :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.about : AstrologerEditRequest?.data?.data?.astrologer?.about }</p>
                                        </>
                                        : null
                                    }
                                </div>

                                <div className='mb-4'>
                                    {
                                        AstrologerEditRequest?.data?.data?.request?.accountholder_name || AstrologerEditRequest?.data?.data?.request?.accountnumber || AstrologerEditRequest?.data?.data?.request?.bank_name || AstrologerEditRequest?.data?.data?.request?.branch || AstrologerEditRequest?.data?.data?.request?.IFSCcode || AstrologerEditRequest?.data?.data?.request?.pancard ?
                                            <h3>Bank Details</h3> : null
                                    }


                                    {AstrologerEditRequest?.data?.data?.request?.accountholder_name ?
                                        <>
                                            <p className='preview-data'><span>Holder name :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.accountholder_name   : AstrologerEditRequest?.data?.data?.bank?.accountholder_name }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.accountnumber ?
                                        <>
                                            <p className='preview-data'><span>Account number :</span>{ isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.accountnumber  : AstrologerEditRequest?.data?.data?.bank?.accountnumber }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.bank_name ?
                                        <>
                                            <p className='preview-data'><span>Bank name :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.bank_name  : AstrologerEditRequest?.data?.data?.bank?.bank_name }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.branch ?
                                        <>
                                            <p className='preview-data'><span>Branch Name :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.branch : AstrologerEditRequest?.data?.data?.bank?.branch }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.IFSCcode ?
                                        <>
                                            <p className='preview-data'><span>IFSC Code :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.IFSCcode : AstrologerEditRequest?.data?.data?.bank?.IFSCcode }</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.pancard ?
                                        <>
                                            <p className='preview-data'><span>Pan number :</span> { isAcceptedData ? AstrologerEditRequest?.data?.data?.olddata?.pancard : AstrologerEditRequest?.data?.data?.bank?.pancard }</p>
                                        </>
                                        : null
                                    }

                                    {AstrologerEditRequest?.data?.data?.request?.filefor === "idproof" ?
                                        <>
                                            <p className='preview-data'><span>Id Proof :</span>
                                                {AstrologerEditRequest?.data?.data?.request?.old_file ?
                                                    <Button type={"primary"} className="preview-download-btn" href={AstrologerEditRequest?.data?.data?.request?.old_file}>
                                                        Download
                                                    </Button>
                                                    : <p>file not found</p>
                                                }
                                            </p>
                                        </>

                                        : null
                                    }

                                    {AstrologerEditRequest?.data?.data?.request?.filefor === "passbook" ?
                                        <>
                                            <p className='preview-data'><span>Passbook :</span>
                                                {AstrologerEditRequest?.data?.data?.request?.old_file ?
                                                    <Button type={"primary"} className="preview-download-btn" href={AstrologerEditRequest?.data?.data?.request?.old_file}>
                                                        Download
                                                    </Button>
                                                    : <p>file not found</p>
                                                }
                                            </p>
                                        </>
                                        : null
                                    }
                                </div>
                            </Col>
                        </Col>
                        <Col lg={5} className='changes-profile'>
                            <div className='bg-yellow-light text-center p-3'>
                                <h3>Changed Details</h3>
                            </div>
                            <Col lg={12} className='px-4'>
                                <div className='d-flex justify-content-center'>
                                    {AstrologerEditRequest?.data?.data?.request?.filefor === "profile" ?
                                        <div className='d-flex align-items-center preview-profile-img'>
                                            <img src={AstrologerEditRequest?.data?.data?.request?.new_file ? AstrologerEditRequest?.data?.data?.request?.new_file : EmptyProfile} alt="" className='img-fluid' />
                                        </div>
                                        : null
                                    }
                                </div>

                                <div className='mb-4'>
                                    {
                                        AstrologerEditRequest?.data?.data?.request?.name || AstrologerEditRequest?.data?.data?.request?.email || AstrologerEditRequest?.data?.data?.request?.phone || AstrologerEditRequest?.data?.data?.request?.dateofbirth || AstrologerEditRequest?.data?.data?.request?.age || AstrologerEditRequest?.data?.data?.request?.gender || AstrologerEditRequest?.data?.data?.request?.maritalstatus || changedDescription || changedLanguage || AstrologerEditRequest?.data?.data?.request?.experience || AstrologerEditRequest?.data?.data?.request?.profession || AstrologerEditRequest?.data?.data?.request?.qualification ?
                                            <h3>Personal Details</h3> : null
                                    }

                                    {AstrologerEditRequest?.data?.data?.request?.name ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Name :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.name}
                                                            onChange={(e) => { setEditData({ ...editData, name: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Name :</span>{AstrologerEditRequest?.data?.data?.request?.name}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.email ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Email :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.email}
                                                            onChange={(e) => { setEditData({ ...editData, email: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Email :</span>{AstrologerEditRequest?.data?.data?.request?.email}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.phone ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Phone Number :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.phone}
                                                            onChange={(e) => { setEditData({ ...editData, phone: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Phone Number :</span>{AstrologerEditRequest?.data?.data?.request?.phone}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.dateofbirth ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>D.O.B :</span></p>
                                                        <input
                                                            type="Date"
                                                            className='preview-input'
                                                            value={editData?.dateofbirth}
                                                            onChange={(e) => { setEditData({ ...editData, dateofbirth: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>D.O.B :</span>{AstrologerEditRequest?.data?.data?.request?.dateofbirth}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.age ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Age :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.age}
                                                            onChange={(e) => { setEditData({ ...editData, age: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Age :</span>{AstrologerEditRequest?.data?.data?.request?.age}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.gender ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Gender :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.gender}
                                                            onChange={(e) => { setEditData({ ...editData, gender: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Gender :</span>{AstrologerEditRequest?.data?.data?.request?.gender}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.maritalstatus ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Marital Status :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.maritalstatus}
                                                            onChange={(e) => { setEditData({ ...editData, maritalstatus: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Marital Status :</span>{AstrologerEditRequest?.data?.data?.request?.maritalstatus}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }

                                    {changedDescription ?
                                        <>
                                            <p className='preview-data'><span>Expertise :</span>{changedDescription.join(", ")}</p>
                                        </>
                                        : null
                                    }
                                    {changedLanguage ?
                                        <>

                                            <p className='preview-data'><span>Languages :</span>{changedLanguage.join(", ")}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.experience ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Experience in years :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.experience}
                                                            onChange={(e) => { setEditData({ ...editData, experience: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Experience in years :</span> {AstrologerEditRequest?.data?.data?.request?.experience} years</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.profession ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Profession :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.profession}
                                                            onChange={(e) => { setEditData({ ...editData, profession: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Profession :</span> {AstrologerEditRequest?.data?.data?.request?.profession}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.qualification ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>Qualification :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.qualification}
                                                            onChange={(e) => { setEditData({ ...editData, qualification: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>Qualification :</span> {AstrologerEditRequest?.data?.data?.request?.qualification}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.about ?
                                        <>
                                            {
                                                editShow ?
                                                    <div className='d-flex'>
                                                        <p className='preview-data'><span>About :</span></p>
                                                        <input
                                                            type="text"
                                                            className='preview-input'
                                                            value={editData?.about}
                                                            onChange={(e) => { setEditData({ ...editData, about: e.target.value }) }}
                                                        />
                                                    </div>
                                                    :
                                                    <div>
                                                        <p className='preview-data'><span>About :</span>{AstrologerEditRequest?.data?.data?.request?.about}</p>
                                                    </div>
                                            }
                                        </>
                                        : null
                                    }
                                </div>

                                <div className='mb-4'>
                                    {
                                        AstrologerEditRequest?.data?.data?.request?.accountholder_name || AstrologerEditRequest?.data?.data?.request?.accountnumber || AstrologerEditRequest?.data?.data?.request?.bank_name || AstrologerEditRequest?.data?.data?.request?.branch || AstrologerEditRequest?.data?.data?.request?.IFSCcode || AstrologerEditRequest?.data?.data?.request?.pancard ?
                                            <h3>Bank Details</h3> : null
                                    }


                                    {AstrologerEditRequest?.data?.data?.request?.accountholder_name ?
                                        <>
                                            <p className='preview-data'><span>Holder name :</span>{AstrologerEditRequest?.data?.data?.request?.accountholder_name}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.accountnumber ?
                                        <>
                                            <p className='preview-data'><span>Account number :</span>{AstrologerEditRequest?.data?.data?.request?.accountnumber}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.bank_name ?
                                        <>
                                            <p className='preview-data'><span>Bank name :</span> {AstrologerEditRequest?.data?.data?.request?.bank_name}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.branch ?
                                        <>
                                            <p className='preview-data'><span>Branch Name :</span> {AstrologerEditRequest?.data?.data?.request?.branch}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.IFSCcode ?
                                        <>
                                            <p className='preview-data'><span>IFSC Code :</span> {AstrologerEditRequest?.data?.data?.request?.IFSCcode}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.pancard ?
                                        <>
                                            <p className='preview-data'><span>Pan number :</span> {AstrologerEditRequest?.data?.data?.request?.pancard}</p>
                                        </>
                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.filefor === "idproof" ?
                                        <>
                                            <p className='preview-data'><span>Id Proof :</span>
                                                {AstrologerEditRequest?.data?.data?.request?.new_file ?
                                                    <Button type={"primary"} className="preview-download-btn" href={AstrologerEditRequest?.data?.data?.request?.new_file}>
                                                        Download
                                                    </Button>
                                                    : <p>file not found</p>
                                                }
                                            </p>
                                        </>

                                        : null
                                    }
                                    {AstrologerEditRequest?.data?.data?.request?.filefor === "passbook" ?
                                        <>
                                            <p className='preview-data'><span>Passbook :</span>
                                                {AstrologerEditRequest?.data?.data?.request?.new_file ?
                                                    <Button type={"primary"} className="preview-download-btn" href={AstrologerEditRequest?.data?.data?.request?.new_file}>
                                                        Download
                                                    </Button>
                                                    : <p>file not found</p>
                                                }
                                            </p>
                                        </>

                                        : null
                                    }
                                </div>
                            </Col>
                            {!AstrologerEditRequest?.data?.data?.request?.isChanged && !AstrologerEditRequest?.data?.data?.request?.isRejected ?
                                <>
                                    <div className='d-flex justify-content-center gap-3 my-4'>
                                        <button
                                            className='blue-btn'
                                            onClick={() => setEditShow(!editShow)}
                                        >
                                            {editShow ? "Back" : "Edit"}
                                        </button>
                                        {
                                            editShow ?
                                                <button
                                                    className='green-btn'
                                                    onClick={() => handleUpdate()}
                                                >
                                                    Submit
                                                </button>
                                                :
                                                <button
                                                    className='green-btn'
                                                    onClick={() => acceptRequest()}
                                                >
                                                    Approve
                                                </button>
                                        }
                                        <button
                                            className='yellow-btn'
                                            onClick={() => rejectRequest()}
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </>
                                : null
                            }
                            {AstrologerEditRequest?.data?.data?.request?.isChanged ?
                                <>
                                    <div className='d-flex justify-content-center gap-3 my-4'>
                                        <button className='grey-btn disabled'>Approved</button>
                                    </div>
                                    <div className='text-center'>
                                        <p>Approved by {AstrologerEditRequest?.data?.data?.request?.approvedby}</p>
                                    </div>
                                </>
                                : null}
                            {AstrologerEditRequest?.data?.data?.request?.isRejected ?
                                <>
                                    <div className='d-flex justify-content-center gap-3 my-4'>
                                        <button className='grey-btn disabled'>Rejected</button>
                                    </div>
                                    <div className='text-center'>
                                        <p className='text-danger'>{AstrologerEditRequest?.data?.data?.request?.reason}</p>
                                        <p>Rejected by {AstrologerEditRequest?.data?.data?.request?.approvedby}</p>
                                    </div>
                                </>
                                : null}
                        </Col>
                    </Col>
                </Row>
            </Container >
        </>
    )

};

export default ChangesApprovalPreview;