import React, { useEffect } from "react";
import { Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "react-phone-input-2/lib/style.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AdminChangePassword, successMessage, errorMessage } from '../../../../Redux/Slice'

const ChangePassword = ({ editShow, editClose }) => {

    const dispatch = useDispatch()
    const { Loading, success, errors } = useSelector((state) => state.Astromind)
    const token = localStorage.getItem("admin-auth-token");

    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required('Old Password is required'),
        new_password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords must match')
            .required('Confirm Password is required'),
    });


    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            let passwordData = {
                oldpassword: values.old_password,
                newpassword: values.new_password,
            }
            dispatch(AdminChangePassword({ passwordData, token }));
        },
    })

    const EditClose = (e) => {
        e.preventDefault();
        formik.resetForm();
        editClose(false);
    }

    useEffect(() => {
        if (success?.message) {
            Swal.fire({
                title: success?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage(''));
                editClose(false);
            })
        }
        else if (errors?.status === 401) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, success, errors, editClose]);

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={editShow}
                onHide={EditClose}
                className=''
            >
                <Modal.Body>
                    <form action="" onSubmit={formik.handleSubmit} >
                        <div>
                            <h2 className="text-center">Change Password</h2>
                        </div>
                        <div className={"details__wrapper register-form"}>

                            <Col lg={12} className="p-3">
                                <div className="form__item  mb-4">
                                    <label>
                                        Enter your Old Password
                                        <span className="text-red">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        name="old_password"
                                        className="px-3"
                                        value={formik.values.old_password}
                                        onChange={formik.handleChange}
                                    />
                                    <p className={"error__feedback"}>{formik.errors.old_password}</p>
                                </div>
                                <div className="form__item  mb-4">
                                    <label>
                                        Enter your New Password
                                        <span className="text-red">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        name="new_password"
                                        className="px-3"
                                        value={formik.values.new_password}
                                        onChange={formik.handleChange}
                                    />
                                    <p className={"error__feedback"}>{formik.errors.new_password}</p>
                                </div>
                                <div className="form__item mb-4">
                                    <label>
                                        Confirm your New Password
                                        <span className="text-red">*</span>
                                    </label>
                                    <input
                                        type="password"
                                        name="confirm_password"
                                        className="px-3"
                                        value={formik.values.confirm_password}
                                        onChange={formik.handleChange}
                                    />
                                    <p className={"error__feedback"}>{formik.errors.confirm_password}</p>
                                </div>
                            </Col>


                            <div
                                className={"form__item2 button__items d-flex justify-content-center mx-4"}
                            >
                                <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                    <button
                                        type='submit'
                                        className={Loading ? 'otp-button bg-yellow text-white disabled' : 'otp-button bg-yellow text-white'}
                                    >
                                        {Loading ? <div className='loader'></div> : "Update"}
                                    </button>
                                    <button
                                        onClick={(e) => EditClose(e)}
                                        className='otp-button btn-primary text-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ChangePassword;
