import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import EmptyProfile from '../../../images/dashboard/empty-profile.png'

const EmployeePreview = ({ data, show, handleClose }) => {
    let dateOfJoining = new Date(data?.profileDetail?.dateofjoining).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    return (
        <>
            <Offcanvas className="list-canvas offcanvas-end" show={show} onHide={handleClose} name='end' backdrop={false} scroll={true} >
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className='preview-card bg-yellow-light'>
                        <div className='d-flex justify-content-between'>
                            <Col lg={8} sm={8}>
                                <div>
                                    <h4>Employee Profile Details </h4>
                                </div>
                                <div className='mb-4'>
                                    {data?.profileDetail?.name ?
                                        <>
                                            <p className='preview-data'><span>Name :</span></p>
                                            <p className='preview-data'>{data?.profileDetail?.name}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetail?.email ?
                                        <>
                                            <p className='preview-data'><span>Email :</span></p>
                                            <p className='preview-data'>{data?.profileDetail?.email}</p>
                                        </>
                                        : null
                                    }
                                </div>
                            </Col>
                            <Col lg={4} sm={4} className='d-flex justify-content-center' >
                                <div className='call-profile-img'>
                                    <img src={data?.profile ? data?.profile?.file : EmptyProfile} alt="" />
                                </div>
                            </Col>
                        </div>
                        <div>
                            <Col lg={12} className='d-flex'>
                                <Col lg={6}>
                                    <div>
                                        {data?.profileDetail?.amc_id ?
                                            <>
                                                <p className='preview-data'><span>Amc Id :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.amc_id}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetail?.role ?
                                            <>
                                                <p className='preview-data'><span>Position :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.role?.rolename}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetail?.qualification ?
                                            <>
                                                <p className='preview-data'><span>Qualification :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.qualification}</p>
                                            </>
                                            : null
                                        }
                                    </div>

                                    <div>
                                        {data?.profileDetail?.age ?
                                            <>
                                                <p className='preview-data'><span>Age :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.age} yrs</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetail?.address ?
                                            <>
                                                <p className='preview-data'><span>Address :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.address} </p>
                                            </>
                                            : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        {data?.profileDetail?.phone ?
                                            <>
                                                <p className='preview-data'><span>Phone Number :</span></p>
                                                <p className='preview-data'>+{data?.profileDetail?.phone}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetail?.dateofjoining ?
                                            <>
                                                <p className='preview-data'><span>Date of Joining :</span></p>
                                                <p className='preview-data'>{dateOfJoining}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetail?.experience ?
                                            <>
                                                <p className='preview-data'><span>Experience :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.experience} yrs</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetail?.gender ?
                                            <>
                                                <p className='preview-data'><span>Gender :</span></p>
                                                <p className='preview-data'>{data?.profileDetail?.gender}</p>
                                            </>
                                            : null
                                        }
                                    </div>


                                </Col>
                            </Col>
                        </div>
                        <div className='d-flex justify-content-center gap-4 mt-3' >
                            {/* <Button type={"primary"} className='bg-darkBlue btn'>
                                Disable
                            </Button>
                            <Button type={"primary"} className='bg-yellow btn'>
                                Remove
                            </Button> */}
                        </div>
                    </Col>

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default EmployeePreview;