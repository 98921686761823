import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllOffers, DeleteOffer, successMessage, errorMessage } from '../../../Redux/Slice';

function OfferList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Offers, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("admin-auth-token");

    useEffect(() => {
        dispatch(GetAllOffers({ token }));
    }, [dispatch, token])

    useEffect(() => {
        if (success) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage(''));
                dispatch(GetAllOffers({ token }));
            })
        }
        else if (errors) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
                dispatch(GetAllOffers({ token }));
            })
        }
    }, [dispatch, token, success, errors])


console.log(success);

    const handleDeleteOffer = (offerId) => {
        Swal.fire({
            title: "Are you sure want to Delete your Offer?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(DeleteOffer({ token, offerId }))
            }
        });
    }

    return (
        <>
            <MainPagetitle pageTitle="Special Offer" parentTitle="Home" />

            <Container fluid>
                <Row>
                    <Col lg={12} className='d-flex justify-content-between mb-4'>
                        <div>
                            <h2>Offers</h2>
                        </div>
                        <div className='offer-add-btn'>
                            <button onClick={() => { navigate('/add-offer/') }}>Add</button>
                        </div>
                    </Col>
                    {Loading ?
                        <>
                            <div class="wrapper">
                                <div class="page-loader">
                                    <div class="loading one"></div>
                                    <div class="loading two"></div>
                                    <div class="loading three"></div>
                                    <div class="loading four"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                Offers?.data?.data?.length > 0 ?
                                    <Col lg={12} className='d-flex flex-wrap justify-content-between gap-4'>
                                        {
                                            Offers?.data?.data?.map((offer, i) => {
                                                return (
                                                    <Col lg={6} className='offer-list-card'>
                                                        <img src={offer?.offer?.image} alt="" className='img-fluid' />
                                                        <Col lg={12} className='d-flex offer-overlay'>
                                                            <Col lg={6} className='offer-detail-left'>
                                                                <div className='offer-badge'>
                                                                    <p>{offer?.offer?.discountpercentage}% offer</p>
                                                                </div>
                                                                <div className='offer-detail'>
                                                                    <h5>{offer?.offer?.name}</h5>
                                                                    <button onClick={() => navigate(`/view-offer/${offer?.offer?.id}`)}>View Details</button>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} className='offer-detail-right d-flex gap-2'>
                                                                <div className='offer-btn' onClick={() => navigate(`/edit-offer/${offer?.offer?.id}`)}>
                                                                    < MdEdit className='icon' />
                                                                </div>
                                                                <div className='offer-btn' onClick={() => { handleDeleteOffer(offer?.offer?.id) }}>
                                                                    < RiDeleteBin6Fill className='icon' />
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Col>
                                    :
                                    <div className='no-data pt-5'>
                                        <p>No sufficient data available!</p>
                                    </div>
                            }
                        </>
                    }
                </Row>
            </Container>
        </>
    )
}

export default OfferList;