import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-bootstrap/Pagination';

import MainPagetitle from '../../layouts/MainPagetitle';

import EmptyProfile from '../../../images/dashboard/empty-profile.png';
import { AiFillStar } from "react-icons/ai";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ApproveOrRejectReviews, GetUnApprovedReviews, successMessage, errorMessage } from '../../../Redux/Slice';


function RatingsReviewsList() {
    const dispatch = useDispatch();
    const { UnApprovedReviews, Loading, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(UnApprovedReviews?.data?.pagination?.currentpage);
    }, [UnApprovedReviews]);

    useEffect(() => {
        dispatch(GetUnApprovedReviews({ token: token, page: currentPage }))
    }, []);


    const changePage = (value) => {
        dispatch(GetUnApprovedReviews({ token: token, page: value }))
    }

    const nextPage = () => {
        const next = Number(currentPage) + 1;
        if (next <= UnApprovedReviews?.data?.pagination?.totalPage) {
            dispatch(GetUnApprovedReviews({ token: token, page: next }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(currentPage) - 1;
        if (previousPage >= 1) {
            dispatch(GetUnApprovedReviews({ token: token, page: previousPage }));
        }
    }

    const handleAcceptReviews = (id) => {
        Swal.fire({
            title: "Are you sure want to accept review",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#36AA00",
            confirmButtonText: "Accept"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(ApproveOrRejectReviews({ token, reviewId: id, status: 1 }))
            }
        })
    }
    const handleRejectReviews = (id) => {
        Swal.fire({
            title: "Are you sure want to reject review",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#36AA00",
            confirmButtonText: "Reject"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(ApproveOrRejectReviews({ token, reviewId: id, status: 0 }))
            }
        })
    }

    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetUnApprovedReviews({ token: token, page: currentPage }))
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage(''));
            })
        }

        else if (errors?.status === 400) {
            dispatch(GetUnApprovedReviews({ token: token, page: currentPage }))
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
            })
        }
    }, [success, errors]);

    return (
        <>
            <MainPagetitle pageTitle="Ratings & Reviews" parentTitle="Home" />
            <Container fluid>
                <Row className='mb-4'>
                    <Col lg={12}>
                        <h3>Ratings & Reviews</h3>
                    </Col>
                </Row>
                {Loading ?
                    <>
                        <div class="wrapper">
                            <div class="page-loader">
                                <div class="loading one"></div>
                                <div class="loading two"></div>
                                <div class="loading three"></div>
                                <div class="loading four"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {
                            UnApprovedReviews?.data?.data?.length > 0 ?
                                <Row className='d-flex gap-4 justify-content-center reviewRow'>
                                    {UnApprovedReviews?.data?.data?.map((list, index) => {
                                        let indianTime = new Date(list?.review?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                        return (
                                            <>
                                                <Col lg={10} className='d-flex review-card'>
                                                    <Col lg={2} sm={2} className='review-details'>
                                                        <div className='review-user-profile'>
                                                            <img src={list?.UserProfile ? list?.UserProfile?.file : EmptyProfile} alt="" className='img-fluid' />
                                                        </div>
                                                        <div className='review-res-userDetail'>
                                                            <div className='d-flex justify-content-between'>
                                                                <h4>{list?.review?.user?.name}</h4>
                                                                <p className='review-date'>{indianTime}</p>
                                                            </div>
                                                            <p>{list?.review?.user?.amc_id}</p>
                                                        </div>
                                                    </Col>
                                                    <Col lg={10} sm={10} >
                                                        <div className='review-user-detail'>
                                                            <div className='d-flex justify-content-between'>
                                                                <h4>{list?.review?.user?.name}</h4>
                                                                <p className='review-date'>{indianTime}</p>
                                                            </div>
                                                            <p>{list?.review?.user?.amc_id}</p>
                                                        </div>
                                                        <div>
                                                            <p>{list?.review?.comment}</p>
                                                        </div>
                                                        <div >
                                                            <h5>Ratings : <span>
                                                                {[...new Array(5)].map((arr, index) => {
                                                                    return index < `${list?.review?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                                })}
                                                            </span>
                                                            </h5>
                                                        </div>
                                                        <Col lg={12} sm={12} className='d-flex review-bottom-card'>
                                                            <Col lg={6} sm={6} className='d-flex gap-3 review-astro-card'>
                                                                <div className='review-astro-img'>
                                                                    <img src={list?.astrologerProfile ? list?.astrologerProfile?.file : EmptyProfile} alt="" className='img-fluid' />
                                                                </div>
                                                                <div>
                                                                    <h5>{list?.review?.astrologer?.name}</h5>
                                                                    <p>{list?.review?.astrologer?.amc_id}</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} sm={6} className='review-btn'>
                                                                <button className='accept' onClick={() => { handleAcceptReviews(list?.review?.id) }} >Accept</button>
                                                                <button className='reject' onClick={() => { handleRejectReviews(list?.review?.id) }} >Reject</button>
                                                            </Col>
                                                        </Col>

                                                    </Col>
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Row>
                                :
                                <div className='no-data pt-5'>
                                    <p>No sufficient data available!</p>
                                </div>
                        }
                        <Row>
                            {UnApprovedReviews?.data?.pagination?.totalPage > 1 ?
                                <>
                                    <Col lg={12} className='d-flex justify-content-center mt-5 pagination'>
                                        <Pagination className="px-4">
                                            <Pagination.Prev onClick={() => previousPage()} />

                                            {[...new Array(UnApprovedReviews?.data?.pagination?.totalPage)].map((arr, index) => {
                                                return (
                                                    <Pagination.Item
                                                        onClick={() => changePage(index + 1)}
                                                        key={index + 1}
                                                        active={index + 1 === Number(currentPage)}
                                                    >
                                                        {index + 1}
                                                    </Pagination.Item>
                                                )
                                            })}
                                            <Pagination.Next onClick={() => nextPage()} />
                                        </Pagination>
                                    </Col>
                                </>
                                : null
                            }
                        </Row>
                    </>
                }
            </Container>
        </>
    )
}

export default RatingsReviewsList;