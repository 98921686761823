import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import MyCustomUploadAdapterPlugin from './CustomUpload';
import axios from 'axios';

// class MyUploadAdapter {

//     constructor(loader) {
//         this.loader = loader;
//     }

//     upload() {
//         return this.loader.file
//             .then(file => new Promise((resolve, reject) => {
//                 const token = localStorage.getItem('admin-auth-token');

//                 console.log(file);
//                 const data = new FormData();
//                 data.append('file', file);
//                 data.append('filefor', 'blog')
//                 console.log(data);

//                 // fetch('http://13.201.217.89:8000/upload-file', { // Replace with your upload URL
//                 //     method: 'POST',
//                 //     headers: {
//                 //         'Authorization': token, // Add your custom headers here
//                 //     },
//                 //     body: data
//                 // })
//                 const headers = {
//                     'authorization': token,
//                     'Content-Type': 'multipart/form-data',
//                 }
//                 const uploadImg =  axios.post(`http://13.201.217.89:8000/upload-file`, data,
//                 { headers: headers })
//                     .then(response =>{
//                         console.log("response", response);
//                         resolve({
//                             default: response.data.file // The URL of the uploaded image
//                         });
//                         // response.json()
//                     } )
//                     // .then(data => {
//                     //     console.log("data", data);
//                     //     resolve({
//                     //         default: data.file // The URL of the uploaded image
//                     //     });
//                     // })
//                     .catch(error => {
//                         reject(error);
//                     });
//             }));
//     }

//     abort() {
//         // Handle abort
//     }
// }

// function MyCustomUploadAdapterPlugin(editor) {
//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//         return new MyUploadAdapter(loader);
//     };
// }

// function MyCustomUploadAdapter(loader) {
//     this.loader = loader;
//   }

//   MyCustomUploadAdapter.prototype.upload = function() {
//     const token = localStorage.getItem('admin-auth-token');

//     return this.loader.file
//       .then(file => new Promise((resolve, reject) => {
//         const data = new FormData();
//         data.append('file', file);
//         data.append('filefor', 'blog')

//         fetch('http://13.201.217.89:8000/upload-file', { // Your image upload endpoint
//           method: 'POST',
//           body: data,
//           headers: {
//             'authorization': token, 
//           },
//         })
//         .then(response => response.json())
//         .then(data => {
//           resolve({
//             default: data.url
//           });
//         })
//         .catch(error => {
//           reject(error);
//         });
//       }));
//   };

//   ClassicEditor
//     .create(document.querySelector('#editor'), {
//       extraPlugins: [ MyCustomUploadAdapter ],
//     })
//     .catch(error => {
//       console.error(error);
//     });
const CkEditorBlog = ({ setData, data, isDisabled }) => {


    // const customConfig = {
    //     extraPlugins: [MyCustomUploadAdapterPlugin],
    // };

    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={data}
                disabled={isDisabled}
                config={{
                    extraPlugins: [MyCustomUploadAdapterPlugin],
                    toolbar: [
                        'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo', 'imageUpload'
                    ],
                    image: {
                        toolbar: [
                            'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
                        ]
                    }
                }} onReady={editor => {
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setData(data);
                    // console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                }}
            />
        </>
    );
};

export default CkEditorBlog;