import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MainPagetitle from '../../layouts/MainPagetitle';
import FilterAstrologer from './FilterAstrologer';

import { IoClose } from "react-icons/io5";
import { FaFilter, FaSearch } from "react-icons/fa";
import { MdSort } from "react-icons/md";
import { MdVerified } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAllAstrologers } from '../../../Redux/Slice';

import ReactPaginate from 'react-paginate';

const AstrologersList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Astrologers, Loading } = useSelector((state) => state.Astromind);

    const token = localStorage.getItem('admin-auth-token');
    let sortBy = JSON.parse(localStorage.getItem("sortby"));
    let filter = JSON.parse(localStorage.getItem("filter"));

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [currentPage, setCurrentPage] = useState(Astrologers?.data?.pagination?.currentpage);
    const pageLimit = window.innerWidth < 600 ? 15 : 25;
    const [type, setType] = useState();
    const [searchValue, setSearchValue] = useState(null);
    const [showFilter_or_sort, setShowFilter_or_sort] = useState({
        status: false,
        type: ''
    });

    const closeFilter = (value) => {
        setShowFilter_or_sort({ ...showFilter_or_sort, status: value });
    }

    useEffect(() => {
        if (pathname) {
            if (pathname.includes("all-astrologers")) {
                setType("all-astrologers");
                localStorage.removeItem("filter");
                dispatch(GetAllAstrologers({ token, page: currentPage ? currentPage : 1, pageLimit, type: "all-astrologers" }));
            }
            else if (pathname.includes("paused-astrologers")) {
                setType("paused-astrologers");
                localStorage.removeItem("filter");
                dispatch(GetAllAstrologers({ token, page: currentPage ? currentPage : 1, pageLimit, type: "paused-astrologers" }));
            }
            else if (pathname.includes("online-astrologers")) {
                setType("online-astrologers");
                localStorage.removeItem("filter");
                dispatch(GetAllAstrologers({ token, page: currentPage ? currentPage : 1, pageLimit, type: "online-astrologers" }));
            }

            if (sortBy) {
                localStorage.removeItem("sortby");
            }
        }
    }, [pathname])

    useEffect(() => {
        if (searchValue) {
            dispatch(GetAllAstrologers({ token, page: 1, pageLimit, type, search: searchValue }));
        }
        else if (searchValue == '') {
            dispatch(GetAllAstrologers({ token, page: 1, pageLimit, type }));
        }
    }, [searchValue])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (Astrologers?.data) {
            setCurrentPage(Astrologers?.data?.pagination?.currentpage);
        }
    }, [Astrologers, currentPage]);

    const handlePageClick = (event) => {
        const newOffset = Number(event.selected) + 1;
        if (searchValue) {
            dispatch(GetAllAstrologers({ token, page: newOffset, pageLimit, type, search: searchValue }));
        }
        if (sortBy) {
            dispatch(GetAllAstrologers({ token, page: newOffset, pageLimit, type, sort: sortBy?.sort, sortby: sortBy?.sortby }));
        }
        else {
            dispatch(GetAllAstrologers({ token, page: newOffset, pageLimit, type }));
        }
    }

    const PreviewCall = (data) => {
        navigate(`/astrologer/${data?.profileDetails?.slug}/${data?.profileDetails?.id}`)
    }

    return (
        <>
            {
                showFilter_or_sort ?
                    <FilterAstrologer
                        show={showFilter_or_sort}
                        close={closeFilter}
                        sortBy={sortBy}
                        filter={filter}
                        type={type}
                        pageLimit={pageLimit}
                    />
                    : null
            }

            <MainPagetitle pageTitle="Astrologers" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex flex-wrap astrologer-list'>
                    <Col lg={12} className='d-flex justify-content-center gap-3'>
                        <button
                            className={`approval-btn ${type == "all-astrologers" && "bg-blue text-white"}`}
                            onClick={() => {
                                setCurrentPage(1)
                                navigate('/all-astrologers/')
                            }}
                        >
                            Astrologer List
                        </button>
                        <button
                            className={`approval-btn ${type == "online-astrologers" && "bg-blue text-white"}`}
                            onClick={() => {
                                setCurrentPage(1)
                                navigate('/online-astrologers/')
                            }}
                        >
                            Online Astrologers
                        </button>
                        <button
                            className={`approval-btn ${type == "paused-astrologers" && "bg-blue text-white"}`}
                            onClick={() => {
                                setCurrentPage(1)
                                navigate('/paused-astrologers/')
                            }}
                        >
                            Paused Astrologers
                        </button>
                    </Col>
                    <Col lg={12} className='list-main'>
                        <Col lg={12} className='d-flex flex-wrap py-5'>
                            <Col lg={3} sm={3} className=''>
                                <h2 className='fw-600'>Astrologers</h2>
                            </Col>
                            <Col lg={9} sm={9} className="d-flex justify-content-end gap-3 filter-nav">
                                <div className="d-flex text-white search-option">
                                    <input
                                        type="text"
                                        value={searchValue}
                                        onChange={(e) => { setSearchValue(e.target.value) }}
                                        placeholder='Search AMC ID, Phone Number, Name'
                                    />
                                    {
                                        searchValue ?
                                            <div
                                                className="d-flex align-items-center p-2 cancel-btn"
                                                onClick={() => { setSearchValue('') }}
                                            >
                                                <IoClose />
                                            </div>
                                            :
                                            <div className="d-flex justify-content-center p-2 search-btn">
                                                <FaSearch />
                                            </div>
                                    }
                                </div>
                                <button
                                    className={`${(filter || showFilter_or_sort?.type == "filter") && "active"} filter-btn`}
                                    onClick={() => { setShowFilter_or_sort({ status: true, type: 'filter' }) }}
                                >
                                    <FaFilter className='icon' />
                                    <p>Filter</p>
                                </button>
                                <button
                                    className={`${(sortBy || showFilter_or_sort?.type == "sort") && "active"} filter-btn`}
                                    onClick={() => { setShowFilter_or_sort({ status: true, type: 'sort' }) }}
                                >
                                    <MdSort className='icon' />
                                    <p>Sort by</p>
                                </button>
                            </Col>
                        </Col>

                        {Loading ?
                            <>
                                <div className="wrapper">
                                    <div className="page-loader">
                                        <div className="loading one"></div>
                                        <div className="loading two"></div>
                                        <div className="loading three"></div>
                                        <div className="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    Astrologers?.data?.data?.length > 0 ?
                                        <Col lg={12} className='list-data mb-3'>
                                            <table key='list' className="table-100 table-200" >
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>AMC ID</th>
                                                        <th>Name</th>
                                                        <th className='responsive-hide'>Phone</th>
                                                        <th>Call </th>
                                                        <th>Chat </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Astrologers?.data?.data?.map((data, index) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        Astrologers?.data?.pagination?.currentpage === 1 ?
                                                                            <>
                                                                                {index + 1}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {((Astrologers?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td>{data?.profileDetails?.amc_id}</td>
                                                                <td>{data?.profileDetails?.name}</td>
                                                                <td className='responsive-hide'>+{data?.profileDetails?.phone}</td>
                                                                <td className='orderCount' >
                                                                    <span className='success'><MdVerified className='icon' />{data?.profileDetails?.totelCompletedCallOrders}</span>
                                                                    <span className='cancel'><ImCancelCircle className='icon' />{data?.profileDetails?.totelCancelledCallOrders}</span>
                                                                </td>
                                                                <td className='orderCount' >
                                                                    <span className='success'><MdVerified className='icon' />{data?.profileDetails?.totelCompletedChatOrders}</span>
                                                                    <span className='cancel'><ImCancelCircle className='icon' />{data?.profileDetails?.totelCancelledChatOrders}</span>
                                                                </td>
                                                                <td className='view' onClick={() => PreviewCall(data)}>view details</td>

                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Col>
                                        :
                                        <>
                                            <div className='no-data pt-5'>
                                                <p>No sufficient data available!</p>
                                            </div>
                                        </>
                                }
                            </>
                        }
                    </Col>
                </Row>
            </Container >
            {
                Astrologers?.data?.pagination?.totalPage > 1 ?
                    <>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={Astrologers?.data?.pagination?.totalPage}
                            forcePage={Astrologers?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                    </>
                    : null
            }
        </>
    )
}

export default AstrologersList;