import React, { useState, useEffect } from "react";
import { Col } from 'react-bootstrap';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Modal from 'react-bootstrap/Modal';

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { UpdateAdminProfile } from '../../../../Redux/Slice'

const EditProfile = ({ editShow, editClose, profile }) => {

    const dispatch = useDispatch();
    const { Loading } = useSelector((state) => state.Astromind)
    const token = localStorage.getItem("admin-auth-token");

    const [adminData, setAdminData] = useState();

    useEffect(() => {
        setAdminData({
            name: profile?.data?.data?.user?.name,
            email: profile?.data?.data?.user?.email,
            phone: profile?.data?.data?.user?.phone,
        })
    }, [profile, editShow]);


    const EditClose = (e) => {
        e.preventDefault();
        editClose(false);
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        if (token) {
            dispatch(UpdateAdminProfile({ adminData, token }));
        }
    }

    return (
        <>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={editShow}
                onHide={EditClose}
                className=''
            >
                <Modal.Body>
                    <form action="" >
                        <div>
                            <h2 className="text-center">Edit Your Basic Details</h2>
                        </div>
                        <div className={"details__wrapper register-form"}>

                            <Col lg={12} className="p-3">
                                <div className="form__item  mb-4">
                                    <label>
                                        Enter your Name
                                        <span className="text-red">*</span>
                                    </label>
                                    <input
                                        name="name"
                                        className="px-3"
                                        value={adminData?.name}
                                        onChange={(e) => { setAdminData({ ...adminData, name: e.target.value }) }}
                                    />
                                </div>
                                <div className="form__item  mb-4">
                                    <label>
                                        Enter your Email
                                        <span className="text-red">*</span>
                                    </label>
                                    <input
                                        name="email"
                                        className="px-3"
                                        value={adminData?.email}
                                        onChange={(e) => { setAdminData({ ...adminData, email: e.target.value }) }}
                                    />
                                </div>
                                <div className="form__item mb-4">
                                    <label>
                                        Phone Number
                                        <span className="text-red">*</span>
                                    </label>
                                    <PhoneInput
                                        country={'in'}
                                        id='phone'
                                        name='phone'
                                        value={adminData?.phone}
                                        onChange={(value) => { setAdminData({ ...adminData, phone: value }) }}
                                    />
                                </div>
                            </Col>

                            <div
                                className={"form__item2 button__items d-flex justify-content-center mx-4"}
                            >
                                <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                    <button
                                        className={Loading ? 'otp-button bg-yellow text-white disabled' : 'otp-button bg-yellow text-white'}
                                        onClick={(e) => { handleUpdateProfile(e) }}
                                    >
                                        {Loading ? <div className='loader'></div> : "Submit"}
                                    </button>
                                    <button
                                        onClick={(e) => { EditClose(e) }}
                                        className='otp-button btn-primary text-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default EditProfile;
