import { Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import EmptyProfile from '../../../images/dashboard/empty-profile.png';

const UserPreview = ({ data, show, handleClose }) => {
    return (
        <>
            <Offcanvas
                className="list-canvas offcanvas-end"
                show={show}
                onHide={handleClose}
                name='end'
                backdrop={false}
                scroll={true}
            >
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Col className='preview-card bg-yellow-light'>
                        <div className='d-flex justify-content-between'>
                            <Col lg={8}>
                                <div>
                                    <h4>User Profile Details </h4>
                                </div>
                                <div className='mb-4'>
                                    {data?.profileDetails?.name ?
                                        <>
                                            <p className='preview-data'><span>Name :</span></p>
                                            <p className='preview-data'>{data?.profileDetails?.name}</p>
                                        </>
                                        : null
                                    }
                                    {data?.profileDetails?.email ?
                                        <>
                                            <p className='preview-data'><span>Email :</span></p>
                                            <p className='preview-data'>{data?.profileDetails?.email}</p>
                                        </>
                                        : null
                                    }
                                </div>
                            </Col>
                            <Col lg={4} className='d-flex justify-content-center' >
                                <div className='call-profile-img'>
                                    <img src={data?.profile ? data?.profile?.file : EmptyProfile} alt="" />
                                </div>
                            </Col>
                        </div>
                        <div>
                            <Col lg={12} className='d-flex'>
                                <Col lg={6}>
                                    <div>
                                        {data?.profileDetails?.phone ?
                                            <>
                                                <p className='preview-data'><span>Phone Number :</span></p>
                                                <p className='preview-data'>+{data?.profileDetails?.phone}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.maritalstatus ?
                                            <>
                                                <p className='preview-data'><span>Marital Status :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.maritalstatus}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.placeofbirth ?
                                            <>
                                                <p className='preview-data'><span>Place Of Birth :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.placeofbirth}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.country ?
                                            <>
                                                <p className='preview-data'><span>Country :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.country}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.city ?
                                            <>
                                                <p className='preview-data'><span>City :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.city}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        {data?.profileDetails?.gender ?
                                            <>
                                                <p className='preview-data'><span>Gender :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.gender}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.dateofbirth ?
                                            <>
                                                <p className='preview-data'><span>D.O.B :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.dateofbirth}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.timeofbirth ?
                                            <>
                                                <p className='preview-data'><span>Time Of Birth :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.timeofbirth}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.state ?
                                            <>
                                                <p className='preview-data'><span>State :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.state}</p>
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div>
                                        {data?.profileDetails?.pincode ?
                                            <>
                                                <p className='preview-data'><span>Pincode :</span></p>
                                                <p className='preview-data'>{data?.profileDetails?.pincode}</p>
                                            </>
                                            : null
                                        }
                                    </div>

                                </Col>
                            </Col>
                        </div>
                        <div className='d-flex justify-content-center gap-4 mt-3' >
                            {/* <Button type={"primary"} className='bg-darkBlue btn'>
                                Disable
                            </Button>
                            <Button type={"primary"} className='bg-yellow btn'>
                                Remove
                            </Button> */}
                        </div>
                    </Col>

                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}

export default UserPreview;