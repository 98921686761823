import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';

import { IoSend } from "react-icons/io5";
import { TiAttachment } from "react-icons/ti";
import { FaFileDownload } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import React, { useRef } from "react";

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetTicketMessages } from "../../../Redux/Slice";

import FileUpload from './FileUpload';

function ChatPage({ socket, person, ticketId }) {
    const dispatch = useDispatch();
    const { success, TicketMessages } = useSelector((state) => state.Astromind);

    const token = localStorage.getItem("admin-auth-token");
    const ChatingPersonRole = localStorage.getItem("chatingPersonRole");
    const ToId = localStorage.getItem("toId");

    const [message, setMessage] = useState("");
    const [fileStatus, setFileStatus] = useState(false);

    const textareaRef = useRef(null);
    const chatfeedRef = useRef(null);
    const [receivedMessage, setReceivedMessage] = useState([]);
    // const [isTyping, setIsTyping] = useState(false);
    // const [trackTypingMessage, setTrackMessage] = useState("");
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);


    useEffect(() => {
        scrollToBottom();
    });

    const scrollToBottom = () => {
        if (chatfeedRef.current) {
            chatfeedRef.current.scrollTop = chatfeedRef.current.scrollHeight;
        }
    };

    const fileClose = (value) => {
        setFileStatus(value);
    }

    useEffect(() => {
        if (person) {
            localStorage?.setItem("room", person?.uuid);
            localStorage?.setItem("ticketId", person?.id);
            if (person?.user) {
                localStorage?.setItem("chatingPersonRole", "user");
                localStorage?.setItem("toId", person?.user?.id);
            }
            else if (person?.astrologer) {
                localStorage?.setItem("chatingPersonRole", "astrologer");
                localStorage?.setItem("toId", person?.astrologer?.id);
            }
            // socket.emit("joinsupport",{ room : localStorage?.getItem("room") })
        }
    }, [person]);

    useEffect(() => {
        if (ticketId) {
            dispatch(GetTicketMessages({ token: token, ticketId: ticketId }));
            setReceivedMessage([]);
            setMessage("");
        }
    }, [dispatch, token, ticketId]);

    useEffect(() => {
        if (success) {
            const roomName = person?.room;
            socket.emit("sendSupportMessage", {
                room: roomName,
                message: success?.data?.file,
                role: 'user',
                token: token,
                ticketId: person?.id
            });
            let message = success?.data?.file;
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            setReceivedMessage(prevMessages => [...prevMessages, { message, role: 'admin', time: MessageTime }]);
            setFileStatus(false);
        }
    }, [success, person, token, socket]);


    useEffect(() => {
        if (TicketMessages) {
            for (let i = 0; i < TicketMessages?.data?.data?.length; i++) {
                let MessageTime = new Date(TicketMessages?.data?.data[i]?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                let obj = {
                    message: TicketMessages?.data?.data[i]?.message,
                    role: TicketMessages?.data?.data[i]?.role,
                    time: MessageTime,
                };
                setReceivedMessage(prevMessages => [...prevMessages, obj]);
            }
        }
    }, [TicketMessages]);

    const handleChangeMessage = (e) => {
        let input = e.target.value;
        setMessage(input);
        e.target.style.height = '30px';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    const sendMessage = () => {
        if (message !== '') {
            socket.emit("sendSupportMessage",
                {
                    room: person?.room,
                    message: message,
                    role: 'admin',
                    token: token,
                    ticketId: person?.id,
                    toId: ToId,
                    toRole: ChatingPersonRole
                });

            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

            setReceivedMessage(prevMessages => [...prevMessages, { message, role: 'admin', time: MessageTime }]);
            setMessage("");
        }
    };

    useEffect(() => {
        const handleReceiveMessage = (message, role) => {
            let MessageTime = new Date().toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            let obj = {
                message: message,
                role: role,
                time: MessageTime,
            };
            setReceivedMessage(prevMessages => [...prevMessages, obj]);
        };

        socket.on("receiveSupportMessage", handleReceiveMessage);

        return () => {
            socket.off("receiveSupportMessage", handleReceiveMessage);
        };
    });

    const handleKeyPress = (e) => {
        let newLine = '\n';
        if (e.ctrlKey && e.key === 'Enter') {
            setMessage((prevValue) => prevValue + '\n');
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }
        if (e.key === 'Enter' && !(message.includes(newLine)) && message != " ") {
            e.preventDefault();
            sendMessage();
            textareaRef.current.style.height = '30px';
        }
    };

    return (
        <>
            {
                fileStatus ?
                    <FileUpload
                        fileShow={fileStatus}
                        fileClose={fileClose}
                    />
                    : null
            }
            <Col
                lg={12}
                id="chat-feed"
                ref={chatfeedRef}
                className={`${isKeyboardOpen && "chat-view-screen-keyboard"} chat-view-screen bg-lavender p-3`}
            >
                {
                    ticketId ?
                        <>
                            <Col lg={12} className="d-flex justify-content-start">
                                <div className="chat-message">
                                    <p>{person?.issuecategory}</p>
                                    <p>{person?.issue}</p>
                                    <p>{person?.issue}</p>
                                </div>
                            </Col>

                            {
                                receivedMessage.map((item, index) => {
                                    const url = item.message;
                                    const imageExtension = url?.split('.').pop().toLowerCase();
                                    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(imageExtension);
                                    const isFile = ['pdf', 'csv', 'xlsx', 'txt', 'pptx', 'docx', 'doc', 'zip'].includes(imageExtension);

                                    const urlFile = isFile ? new URL(url) : null;
                                    const pathname = isFile ? urlFile.pathname : null;

                                    return (
                                        <Col
                                            lg={12}
                                            className={`d-flex ${item.role === 'admin' ? 'justify-content-end' : 'justify-content-start'}`}
                                            key={index}
                                        >
                                            {isImage ?
                                                <div className="chat-image" >
                                                    <img src={item.message} alt="" className="img-fluid" />
                                                    <div className="image-download">
                                                        <p><a href={item.message}>Download <MdDownload className="icon" /></a></p>
                                                        <span>{item.time}</span>
                                                    </div>
                                                </div>
                                                : isFile ?
                                                    <div className="chat-file">
                                                        <div className="file">
                                                            <a href={item.message}>
                                                                <FaFileDownload className="icon" />
                                                            </a>
                                                        </div>
                                                        <div className="file-name">
                                                            <p>{pathname?.slice(15)}</p>
                                                            <span>{item.time}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="chat-message">
                                                        <p>{item.message}</p>
                                                        <span>{item.time}</span>
                                                    </div>
                                            }
                                        </Col>
                                    );
                                })
                            }
                        </>
                        :
                        <div className='no-data'>
                            <p>No sufficient data available!</p>
                        </div>
                }
            </Col>
            {
                ticketId ?
                    <>
                        <Col lg={12} className="type-section">
                            <div className="text-input">
                                <textarea
                                    type="text"
                                    name=""
                                    id=""
                                    placeholder="Type Messages Here........."
                                    ref={textareaRef}
                                    value={message}
                                    onChange={(e) => {
                                        handleChangeMessage(e);
                                        // handleTyping()
                                    }}
                                    onKeyDown={handleKeyPress}
                                    // onFocus={handleTyping}
                                    // onBlur={handleNotTyping}
                                    required
                                ></textarea>
                                <button >
                                    <TiAttachment
                                        className="icon"
                                        onClick={() => setFileStatus(true)}
                                    />
                                </button>
                            </div>
                            <div>
                                <button>
                                    <IoSend
                                        className="icon"
                                        onClick={() => sendMessage()}
                                    />
                                </button>
                            </div>
                        </Col>
                    </>
                    : null
            }
        </>
    )
}

export default ChatPage;