import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";

import { IoImages } from "react-icons/io5";

import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetEmployeeRole, AddEmployees, UpdateAdminProfileImg, successMessage, errorMessage } from '../../../Redux/Slice';

function AddEmployee() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { EmployeeRole, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    useEffect(() => {
        dispatch(GetEmployeeRole(token));
    }, [])

    const handleUpdateProfileImg = (token) => {
        const ExistFile =  null ;
        const formData = new FormData();
        formData.append('filefor', "profile");
        formData.append('file', formik?.values?.profile);

        dispatch(UpdateAdminProfileImg({ formData, token, ExistFile }));
    }

    useEffect(() => {
        if(success?.data?.isCreated){
            handleUpdateProfileImg(success?.data?.token);
            dispatch(successMessage(''));
        }
        if(success){
            Swal.fire({
                title: success?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage(''));
                navigate('/employees')
            })
        }
    }, [success, errors])

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            phone: '',
            gender: '',
            age: '',
            position: null,
            dateofjoining: '',
            experience: '',
            qualification: '',
            address: '',
            profile: ''
        },
        onSubmit: async (values) => {
            console.log(values);
            let employeeData = {
                name : values?.name,
                email : values?.email,
                phone : values?.phone,
                password : values?.password,
                roleId : Number(values?.position),
                gender : values?.gender,
                age : values?.age,
                dateofjoining: values?.dateofjoining,
                experience : values?.experience,
                qualification : values?.qualification,
                address : values?.address
            }
            dispatch(AddEmployees({ token, employeeData}));
        },
        validate: (values) => {
            const errors = {};
            const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'];
            const fileType = values?.profile?.type;

            if (!values.name) {
                errors.name = "Requried*";
            }
            if (!values.email) {
                errors.email = "Required*";
            }
            else if (values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
            }
            if (!values.password) {
                errors.password = "Required*";
            }
            if (!values.phone) {
                errors.phone = "Required*";
            }
            if (!values.position) {
                errors.position = "Required*";
            }
            if (!values.experience) {
                errors.experience = "Required*";
            }
            if(values.profile && (!(supportedFormats.includes(fileType)))){
                errors.profile = "Unsupported image format. Please upload a JPG, JPEG, PNG, WEBP or GIF file.";
            }
            console.log(values);
            console.log(!(supportedFormats.includes(fileType)));
            console.log(errors);
            return errors;
        }

    })

    return (
        <>
            <MainPagetitle pageTitle="Add Employee" parentTitle="Employee" />

            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <form action="" onSubmit={formik.handleSubmit}>
                            <div className={"details__wrapper employee-form mb-5"}>
                                <Col lg={12} sm={12}>
                                    <div className="mb-4">
                                        <h3>Add Employee Details</h3>
                                    </div>
                                </Col>
                                <Col lg={6} sm={6} className="p-3">
                                    <div className={`form__item ${formik.errors.name && "input__error"} mb-4`}>
                                        <label> Name <span className="text-red">*</span></label>
                                        <input name="name" className="px-3" value={formik.values.name} onChange={formik.handleChange} />
                                        <p className={"error__feedback"}>{formik.errors.name}</p>
                                    </div>
                                    <div className={`form__item ${formik.errors.email && "input__error"} mb-4`}>
                                        <label> Email<span className="text-red">*</span> </label>
                                        <input name="email" className="px-3" value={formik.values.email} onChange={formik.handleChange} />
                                        <p className={"error__feedback"}>{formik.errors.email}</p>
                                    </div>
                                    <div className={`form__item ${formik.errors.password && "input__error"} mb-4`}>
                                        <label> Password <span className="text-red">*</span></label>
                                        <input name="password" className="px-3" value={formik.values.password} onChange={formik.handleChange} />
                                        <p className={"error__feedback"}>{formik.errors.password}</p>
                                    </div>
                                    <div className={`form__item ${formik.errors.phone && "input__error"} mb-4`}>
                                        <label>Phone Number <span className="text-red">*</span></label>
                                        <PhoneInput
                                            country={'in'}
                                            id='phone'
                                            name='phone'
                                            value={formik.values.phone}
                                            onChange={(value) => { formik.setFieldValue('phone', value) }}
                                        />
                                        <p className={"error__feedback"}>{formik.errors.phone}</p>
                                    </div>

                                    <div className="d-flex gap-3">
                                        <div
                                            className={`form__item ${formik.errors.gender && "input__error"} half-form `}
                                        >
                                            <label>Gender </label>
                                            <select name="gender" className="px-2" value={formik.values.gender} onChange={formik.handleChange}>
                                                <option value="">Choose</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            <p className={"error__feedback"}>{formik.errors.gender}</p>
                                        </div>
                                        <div className={`form__item ${formik.errors.age && "input__error"} half-form `}>
                                            <label>Age</label>
                                            <input type="number" name="age" className="px-3" value={formik.values.age} onChange={formik.handleChange} />
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6} sm={6} className="p-3">
                                    <div className={`form__item ${formik.errors.position && "input__error"} mb-4`}>
                                        <label> Position<span className="text-red">*</span> </label>
                                        <select name="position" className="px-3" value={formik?.values?.position} onChange={formik.handleChange} >
                                            <option value="" >Choose</option>
                                            {
                                                EmployeeRole?.data?.data.map((item, i) => (
                                                    <>
                                                        <option value={item?.id} >{item?.rolename}</option>
                                                    </>
                                                ))
                                            }
                                            {/* <option value="Admin" >Admin</option>
                                            <option value="Finance Team" >Finance Team</option>
                                            <option value="Marketing Team" >Marketing Team</option>
                                            <option value="Management Team" >Management Team</option>
                                            <option value="Support Team" >Support Team</option> */}
                                        </select>
                                        <p className={"error__feedback"}>{formik.errors.position}</p>
                                    </div>
                                    <div className="d-flex gap-3">
                                        <div className={`form__item ${formik.errors.dateofjoining && "input__error"} half-form `}>
                                            <label>Date Of Joining </label>
                                            <DatePicker
                                                className="px-3"
                                                showIcon
                                                placeholderText="Select Date"
                                                selected={formik.values.dateofjoining}
                                                onChange={(value) => { formik.setFieldValue('dateofjoining', value) }}
                                                monthsShown={1}
                                                maxDate={new Date()}
                                                showYearDropdown
                                            />
                                            <p className={"error__feedback"}>{formik.errors.dateofjoining}</p>
                                        </div>
                                        <div className={`form__item ${formik.errors.experience && "input__error"} half-form`}>
                                            <label>Experience <span className="text-red">*</span></label>
                                            <input type="number" name="experience" className="px-3" value={formik.values.experience} onChange={formik.handleChange} />
                                            <p className={"error__feedback"}>{formik.errors.experience}</p>
                                        </div>
                                    </div>
                                    <div className={`form__item ${formik.errors.qualification && "input__error"} mb-4`}>
                                        <label>Qualification </label>
                                        <input type="text" name="qualification" className="px-3" value={formik.values.qualification} onChange={formik.handleChange} />
                                    </div>
                                    <div className={`form__item ${formik.errors.address && "input__error"} mb-4`}>
                                        <label>Address </label>
                                        <input type="text" name="address" className="px-3" value={formik.values.address} onChange={formik.handleChange} />
                                    </div>
                                    <div className={`form__item`}>
                                        <label>Profile </label>
                                        <div className="dz-default dlab-message upload-img bg-lightOrange">
                                            <form action="#" className="dropzone astro-file-upload">
                                                <IoImages className="file-icon" />

                                                <div className="fallback">
                                                    <input type="file" name="profile" onChange={(e) => {
                                                        formik.setFieldValue('profile', e.target.files[0])
                                                    }} multiple />
                                                </div>
                                                {
                                                    formik?.values?.profile ?
                                                        <div className="upload-img-text">
                                                            <p className="file-name">{formik?.values?.profile?.name}</p>
                                                        </div>
                                                        :
                                                        <div className="upload-img-text">
                                                            <p>Upload Your Profile Image</p>
                                                            <p className="sub-note"><span className="text-red">Note:</span> Passport size photo with Plain background) (jpg, png, jpeg only)</p>
                                                        </div>
                                                }
                                            </form>
                                        </div>
                                        <p className={"error__feedback"}>{formik.errors.profile}</p>
                                    </div>
                                </Col>
                                <div
                                    className={"form__item2 button__items d-flex justify-content-center mx-4"}
                                >
                                    <Button type={"primary"} onClick={formik.handleSubmit}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default AddEmployee;