import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetSendedNotifications } from "../../../Redux/Slice";

import MainPagetitle from "../../layouts/MainPagetitle";

function SendedNotifications() {
    const { success, errors, Loading, SendNotifications } = useSelector((state) => state.Astromind);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const token = localStorage.getItem("admin-auth-token");

    useEffect(() => {
        dispatch(GetSendedNotifications({ token }));
    }, [dispatch, token])

    return (
        <>
            <MainPagetitle pageTitle="Sent Notifications" parentTitle="Home" />

            <Container fluid className='pt-100 mb-5'>
                <Container className='mt-0'>
                    <Row>
                        {Loading ?
                            <>
                                <div class="wrapper">
                                    <div class="page-loader">
                                        <div class="loading one"></div>
                                        <div class="loading two"></div>
                                        <div class="loading three"></div>
                                        <div class="loading four"></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {
                                    SendNotifications?.data?.data?.notifications?.length > 0 ?
                                        <>
                                            {
                                                SendNotifications?.data?.data?.notifications?.map((item, i) => (
                                                    <>
                                                        <Col lg={12} className='notification-list mb-3' onClick={() => { navigate(item?.redirectUrl) }}>
                                                            <h6>{item?.title}</h6>
                                                            <p>{item?.description}</p>
                                                            <div className='notify-date'>
                                                                {new Date(item?.createdAt).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        month: "short",
                                                                        day: "2-digit",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: true,
                                                                    }
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </>
                                                ))
                                            }
                                        </>
                                        :
                                        <div className='no-data pt-5'>
                                            <p>No sufficient data available!</p>
                                        </div>
                                }
                            </>
                        }
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default SendedNotifications;