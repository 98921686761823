import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import LogoutPage from './Logout';

import { SVGICON } from "../../constant/theme";
import SupportIcon from '../../../images/Support-icon.svg'
import Logoutbtn from "./Logoutbtn";

import EmptyImg from '../../../images/dashboard/empty-profile.png'

const Header = ({ onNote, profile }) => {
	const navigate = useNavigate();
	const [headerFix, setheaderFix] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-end">

						<ul className="navbar-nav header-right">
							<Dropdown onClick={() => {navigate('/notifications/')}} as="li" className="nav-item dropdown notification_dropdown p-3">
								<svg  width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9564 2.5866e-08C12.5437 -5.55741e-05 12.1359 0.0895253 11.7604 0.262672C11.385 0.435818 11.0508 0.688484 10.7805 1.00355C10.5102 1.31861 10.3101 1.6887 10.1937 2.08875C10.0773 2.48879 10.0474 2.90944 10.106 3.32218C8.01949 3.94462 6.18823 5.23232 4.88571 6.99297C3.5832 8.75363 2.87932 10.8928 2.87921 13.0909V24.7273H1.4396C1.0578 24.7273 0.691628 24.8805 0.42165 25.1533C0.151672 25.4261 0 25.7961 0 26.1818C0 26.5676 0.151672 26.9376 0.42165 27.2103C0.691628 27.4831 1.0578 27.6364 1.4396 27.6364H24.4733C24.8551 27.6364 25.2212 27.4831 25.4912 27.2103C25.7612 26.9376 25.9129 26.5676 25.9129 26.1818C25.9129 25.7961 25.7612 25.4261 25.4912 25.1533C25.2212 24.8805 24.8551 24.7273 24.4733 24.7273H23.0337V13.0909C23.0335 10.8928 22.3297 8.75363 21.0271 6.99297C19.7246 5.23232 17.8934 3.94462 15.8068 3.32218C15.8654 2.90944 15.8355 2.48879 15.7192 2.08875C15.6028 1.6887 15.4027 1.31861 15.1323 1.00355C14.862 0.688484 14.5278 0.435818 14.1524 0.262672C13.777 0.0895253 13.3691 -5.55741e-05 12.9564 2.5866e-08ZM15.8356 30.5455C15.8356 30.9312 15.684 31.3012 15.414 31.574C15.144 31.8468 14.7778 32 14.396 32H11.5168C11.135 32 10.7689 31.8468 10.4989 31.574C10.2289 31.3012 10.0772 30.9312 10.0772 30.5455C10.0772 30.1597 10.2289 29.7897 10.4989 29.5169C10.7689 29.2442 11.135 29.0909 11.5168 29.0909H14.396C14.7778 29.0909 15.144 29.2442 15.414 29.5169C15.684 29.7897 15.8356 30.1597 15.8356 30.5455Z" fill="white" />
									<ellipse cx="23.756" cy="8.92218" rx="5.24427" ry="5.23077" fill="#FF0000" />
								</svg>
							</Dropdown>
							<Dropdown onClick={() => {navigate('/customer-support/')}} as="li" className="nav-item dropdown notification_dropdown ">
								<img src={SupportIcon} className="p-3" alt="img" />
							</Dropdown>
							<li className="nav-item align-items-center header-border">
								<Logoutbtn />
							</li>
							<li className="nav-item ps-3">
								<Dropdown className="header-profile2 d-flex align-items-center">
									<Dropdown.Toggle className="nav-link i-false" as="div">
										<div className="header-info2 d-flex align-items-center">
											<div className="header-media">
												<img src={profile?.data?.data?.profile ? profile?.data?.data?.profile?.file : EmptyImg} alt="" />
											</div>
											<div className="header-info">
												<h6>{profile?.data?.data?.user?.name}</h6>
												<p>{profile?.data?.data?.user?.email}</p>
											</div>

										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu align="end">
										<div className="card border-0 mb-0">
											<div className="card-header py-2">
												<div className="products">
													<img src={profile?.data?.data?.profile ? profile?.data?.data?.profile?.file : EmptyImg} className="avatar avatar-md" alt="" />
													<div>
														<h6>{profile?.data?.data?.user?.name}</h6>
														<span>Admin</span>
													</div>
												</div>
											</div>
											<div className="card-body px-0 py-2">
												<Link to={"/profile/"} className="dropdown-item ai-icon ">
													{SVGICON.User}{" "}
													<span className="ms-2">Profile </span>
												</Link>


												<Link to={"/notifications/"} className="dropdown-item ai-icon ">
													{SVGICON.Notification} {" "}
													<span className="ms-2">Notification </span>
												</Link>
											</div>
											<div className="card-footer px-0 py-2">
												<LogoutPage />
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
