import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Row, Col, Collapse } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { successMessage, errorMessage } from '../../../Redux/Slice';
import { GetCourseRegisterUsers } from '../../../Redux/Action';

function CourseRegisterUsers() {
    const dispatch = useDispatch();
    const { Loading, success, errors, CourseRegisterUsers } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    const { id } = useParams();

    console.log(CourseRegisterUsers);

    const [currentPage, setCurrentPage] = useState(CourseRegisterUsers?.data?.data?.pagination?.currentpage);


    useEffect(() => {
        setCurrentPage(CourseRegisterUsers?.data?.data?.pagination?.currentpage);
    }, [CourseRegisterUsers]);

    useEffect(() => {
        dispatch(GetCourseRegisterUsers({ token, courseId: id, page: 1 }));
    }, []);

    const changePage = (value) => {
        dispatch(GetCourseRegisterUsers({ token, courseId: id, page: value }))
    }

    const nextPage = () => {
        const next = Number(currentPage) + 1;
        if (next <= CourseRegisterUsers?.data?.data?.pagination?.totalPage) {
            dispatch(GetCourseRegisterUsers({ token, courseId: id, page: next }));
        }
    }

    const previousPage = () => {
        const previousPage = Number(currentPage) - 1;
        if (previousPage >= 1) {
            dispatch(GetCourseRegisterUsers({ token, courseId: id, page: previousPage }));
        }
    }

    return (
        <>
            <MainPagetitle pageTitle="Course Register Users" parentTitle="Home" />
            <Container fluid >
                <Row >
                    <Col xl={12} >
                        <div className="filter cm-content-box box-primary add-offer-card">
                            <div className={`content-title`}>
                                <div className="cpa">
                                    <i className="fas fa-file-word me-2"></i>Course Register Users
                                </div>
                            </div>
                            <Collapse>
                                {Loading ?
                                    <>
                                        <div className="wrapper">
                                            <div className="page-loader">
                                                <div className="loading one"></div>
                                                <div className="loading two"></div>
                                                <div className="loading three"></div>
                                                <div className="loading four"></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            CourseRegisterUsers?.data?.data?.course?.length > 0 ?
                                                <div className="card-body py-3">
                                                    <div className="table-responsive">
                                                        <div id="content_wrapper" className="dataTables_wrapper no-footer">
                                                            <table className="table table-responsive-lg table-striped table-condensed flip-content">
                                                                <thead>
                                                                    <tr>
                                                                        <th className='text-black'>S.No</th>
                                                                        <th className='text-black'>User Name</th>
                                                                        <th className='text-black'>User AMC ID</th>
                                                                        <th className='text-black'>Register Date</th>
                                                                        <th className='text-black'>Phone Number</th>
                                                                        <th className='text-black'>Email</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {CourseRegisterUsers?.data?.data?.course?.map((user, i) => {
                                                                        let indianTime = new Date(user?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                                        return (
                                                                            <>
                                                                                <tr key={i}>
                                                                                    <td>{i + 1}</td>
                                                                                    <td >{user?.user?.name}</td>
                                                                                    <td >{user?.user?.amc_id}</td>
                                                                                    <td>{indianTime}</td>
                                                                                    <td >{user?.user?.phone ? `+${user?.user?.phone}` : "none"}</td>
                                                                                    <td >{user?.user?.email ? user?.user?.email : "none"}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='no-data pt-5'>
                                                    <p>No sufficient data available!</p>
                                                </div>
                                        }
                                    </>
                                }

                            </Collapse>

                            {CourseRegisterUsers?.data?.data?.pagination?.totalPage > 1 ?
                                <Col lg={12} className='d-flex justify-content-center mt-5 pagination'>
                                    <Pagination className="px-4">
                                        <Pagination.Prev onClick={() => previousPage()} />

                                        {[...new Array(CourseRegisterUsers?.data?.data?.pagination?.totalPage)].map((arr, index) => {
                                            return (
                                                <Pagination.Item
                                                    onClick={() => changePage(index + 1)}
                                                    key={index + 1}
                                                    active={index + 1 === Number(currentPage)}
                                                >
                                                    {index + 1}
                                                </Pagination.Item>
                                            )
                                        })}
                                        <Pagination.Next onClick={() => nextPage()} />
                                    </Pagination>
                                </Col>
                                : null
                            }
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default CourseRegisterUsers;