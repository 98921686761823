import React, { useContext, useEffect } from 'react';
//import loadable from "@loadable/component";
//import pMinDelay from "p-min-delay";
import { Col } from 'react-bootstrap';


//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from '../../layouts/MainPagetitle';

import ActiveCards from './dashboard-elements/ActiveCards'
import PieChart from './dashboard-elements/AstrologerStatistic';
import AstrologerTopRating from './dashboard-elements/AstrologerTopRating';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetDashboardData } from '../../../Redux/Slice';

const Home = ({ profile }) => {
	const dispatch = useDispatch();
	const { changeBackground } = useContext(ThemeContext);
	const { DashboardData } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
		dispatch(GetDashboardData({ token }));
	}, []);

	return (
		<>
			<MainPagetitle pageTitle="Dashboard" parentTitle="Home" />

			<div className='active-cardMain'>
				<ActiveCards data={DashboardData?.data?.data} />
			</div>

			<Col  className='active-cardMain ratingsMain d-flex flex-wrap justify-content-between'>
				<Col lg={6} >
					<PieChart orderData={DashboardData?.data?.data} />
				</Col>
				<Col lg={5}>
					<AstrologerTopRating />
				</Col>

			</Col>

		</>
	)
}
export default Home;